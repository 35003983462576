import * as THREE from "three";
import { BaseManager } from "../base/BaseManager";
import { Context } from "../core/context";
import { IndserObject } from "../core/IndserObject";

import { MLayer } from "../core/MLayer";
/**
 * 场景管理
 */
class SceneManager extends BaseManager{

    /**
     * 场景模型
     */
    private m_scene:THREE.Scene;

    private m_effectScene: THREE.Scene;
    /**
     * 挂载模型
     */
    private m_modelLayer: MLayer;
    /**
     * 挂载光照
     */
    private m_lightLayer: MLayer;
    /* xyz轴辅助线 */
    private m_AxisHelper:MLayer;
    /**
     * 挂载threejs自带加载器加载的模型
     */
    private m_threejsModel: MLayer;
    /**
     * 辅助线等
     */
    private m_auxModel: MLayer;


    private m_signModel: MLayer;
    private m_maskLayer: MLayer;
    /**
     * 当前场景， 
     * ！！！所有的模型，必须通过mlayer分层挂载，禁止直接挂载在场景下
     */
    public get Scene(){
        return this.m_scene;
    }

    public get EffectScene(){
        return this.m_effectScene;
    }
    /**
     * 辅助模型。用于辅助线等元素,特点userData.scale可以跟随屏幕大小自动变化，该场景内模型仅可查看不可交互
     */
    public get AuxModel(){
        return this.m_auxModel;
    }
    /**
     * 标记模型，特点可以跟随屏幕大小自动变化，并且可以交互
     */
    public get SignModel(){
        return this.m_signModel;
    }
    public get ModelLayer(){
        return this.m_modelLayer;
    }
    public get ThreeLayer(){
        return this.m_threejsModel;
    }

    public get MaskLayer(){
        return this.m_maskLayer;
    }
    constructor(context:Context){
        super(context);
        this.m_scene = new THREE.Scene();

        let modelLayer =new MLayer();
        modelLayer.name = "modelLayer";
        this.m_scene.add(modelLayer);
        this.m_modelLayer = modelLayer;
        
        let threejsModel = new MLayer();
        threejsModel.name = "threejsModel";
        this.m_scene.add(threejsModel);
        this.m_threejsModel = threejsModel;




        let auxModel = new MLayer();
        auxModel.name = "auxModel";
        this.m_scene.add(auxModel);
        this.m_auxModel = auxModel;
        

        let signModel = new MLayer();
        auxModel.name = "signModel";
        this.m_scene.add(signModel);
        this.m_signModel = signModel;

        let maskLayer = new MLayer();
        auxModel.name = "maskLayer";
        this.m_scene.add(maskLayer);
        this.m_maskLayer = maskLayer;

        this.m_AxisHelper=new THREE.AxesHelper(30000);
        // this.m_auxModel.add(this.m_AxisHelper);


        this.m_lightLayer = this.CreateLightLayer();
        this.m_scene.background = new THREE.Color(0x212121);
        this.m_scene.add(this.m_lightLayer);


        this.m_effectScene = new THREE.Scene();
        this.m_effectScene.add(this.CreateLightLayer())
    }

    public GetBox():THREE.Box3{
        let box=new THREE.Box3();
        box.setFromObject(this.ModelLayer);
        let box2 = new THREE.Box3();
        box2.setFromObject(this.ThreeLayer);
        if(!box2.isEmpty()){
            box.expandByPoint(box2.max);
            box.expandByPoint(box2.min);
        }
        return box;
    }

    CreateLightLayer(): MLayer {
        let lightLayer = new MLayer();
        lightLayer.name = "lightLayer";
        const dirLight1 = new THREE.DirectionalLight( 0xffffff );
        dirLight1.position.set( 1, 1, 1 );
        lightLayer.add( dirLight1 );

        const dirLight2 = new THREE.DirectionalLight( 0x002288 );
        dirLight2.position.set( - 1, - 1, - 1 );
        lightLayer.add( dirLight2 );

        const ambientLight = new THREE.AmbientLight( 0x222222 );
        lightLayer.add( ambientLight );
        return lightLayer;
    }

    /**
     * 清空ThreeLayer层数据
     */
    ClearThreeLayer()
    {
        this.ThreeLayer.clear();
    }
    /**
     * 清空标记
     */
    ClearSign()
    {
        this.SignModel.clear();
    }
}

export{SceneManager};