//@ts-check
import * as THREE from 'three'
import { Box3, LineBasicMaterial, Vector3 } from 'three';
import { ModelManager } from '../manager/ModelManager';
import { DBEntity } from './DBEntity';
import { MLayer } from './MLayer';
/**
 * 范围选择工具
 */
let testMesh = new THREE.LineSegments();

export class SelectionBox {
    private m_frustum =new THREE.Frustum();
    private m_center =new THREE.Vector3();

    private m_tmpPoint =new THREE.Vector3();

    private m_vecNear =new THREE.Vector3();
    private m_vecTopLeft =new THREE.Vector3();
    private m_vecTopRight =new THREE.Vector3();
    private m_vecDownRight =new THREE.Vector3();
    private m_vecDownLeft =new THREE.Vector3();

    private m_vecFarTopLeft =new THREE.Vector3();
    private m_vecFarTopRight =new THREE.Vector3();
    private m_vecFarDownRight =new THREE.Vector3();
    private m_vecFarDownLeft =new THREE.Vector3();

    private m_vectemp1 =new THREE.Vector3();
    private m_vectemp2 =new THREE.Vector3();
    private m_vectemp3 =new THREE.Vector3();

    private m_matrix =new THREE.Matrix4();
    private m_quaternion =new THREE.Quaternion();
    private m_scale =new THREE.Vector3();
    m_camera: THREE.Camera;
    m_model: ModelManager;
    m_startPoint: THREE.Vector3;
    m_collection: (DBEntity | THREE.Object3D)[]=[];
    m_endPoint: THREE.Vector3;
    m_threeModel: MLayer;
    instances: any;
    m_deep: number;
    m_auxModel: MLayer;
    /**
     * 
     * @param camra 相交查询
     * @param model 
     * @param threeModel 
     */
    constructor(camra:THREE.Camera, model:ModelManager, threeModel:MLayer, aux:MLayer)
    {
        this.m_camera = camra;
        this.m_model = model;
        this.m_startPoint = new Vector3();
        this.m_endPoint = new Vector3();
        this.m_threeModel = threeModel;
        this.m_auxModel = aux;
        this.m_deep = Number.MAX_VALUE;
    }

    public select(startPoint:THREE.Vector3|undefined = undefined, endPoint:THREE.Vector3|undefined = undefined)
    {
        this.m_startPoint = startPoint || this.m_startPoint;
        this.m_endPoint = endPoint || this.m_endPoint;
        this.updateFrustum(this.m_startPoint, this.m_endPoint);
        this.searchChildInFrustum(this.m_frustum, this.m_threeModel);
        this.searchChildInFrustum(this.m_frustum, this.m_auxModel);
        this.searchChildInFrustum(this.m_frustum, this.m_model);
    }

    updateFrustum(startPoint: THREE.Vector3, endPoint: THREE.Vector3) {

        // let geo =  new THREE.BufferGeometry();

        if (startPoint.x === endPoint.x ) {

            endPoint.x += Number.EPSILON;

        }

        if ( startPoint.y === endPoint.y ) {

            endPoint.y += Number.EPSILON;

        }

        if ( this.m_camera instanceof THREE.PerspectiveCamera ) {
            this.m_camera.updateProjectionMatrix();
            this.m_camera.updateMatrixWorld();
            this.m_tmpPoint.copy( startPoint );
            this.m_tmpPoint.x = Math.min( startPoint.x, endPoint.x );
            this.m_tmpPoint.y = Math.max( startPoint.y, endPoint.y );
            endPoint.x = Math.max( startPoint.x, endPoint.x );
            endPoint.y = Math.min( startPoint.y, endPoint.y );

            this.m_vecNear.setFromMatrixPosition( this.m_camera.matrixWorld );
            this.m_vecTopLeft.set(this.m_tmpPoint.x, this.m_tmpPoint.y,0.5)
            this.m_vecTopRight.set( endPoint.x, this.m_tmpPoint.y, 0);
            this.m_vecDownRight.set(endPoint.x, endPoint.y,0.5)
            this.m_vecDownLeft.set( this.m_tmpPoint.x, endPoint.y, 0);

            this.m_vecTopLeft.unproject( this.m_camera );
            this.m_vecTopRight.unproject( this.m_camera );
            this.m_vecDownRight.unproject( this.m_camera );
            this.m_vecDownLeft.unproject( this.m_camera );

            this.m_vectemp1.copy( this.m_vecTopLeft ).sub( this.m_vecNear );
            this.m_vectemp2.copy( this.m_vecTopRight ).sub( this.m_vecNear );
            this.m_vectemp3.copy( this.m_vecDownRight ).sub( this.m_vecNear );
            this.m_vectemp1.normalize();
            this.m_vectemp2.normalize();
            this.m_vectemp3.normalize();

            this.m_vectemp1.multiplyScalar( this.m_deep );
            this.m_vectemp2.multiplyScalar( this.m_deep );
            this.m_vectemp3.multiplyScalar( this.m_deep );
            this.m_vectemp1.add( this.m_vecNear );
            this.m_vectemp2.add( this.m_vecNear );
            this.m_vectemp3.add( this.m_vecNear );

            const planes = this.m_frustum.planes;
            planes[ 0 ].setFromCoplanarPoints( this.m_vecNear, this.m_vecTopLeft, this.m_vecTopRight );
            planes[ 1 ].setFromCoplanarPoints( this.m_vecNear, this.m_vecTopRight, this.m_vecDownRight );
            planes[ 2 ].setFromCoplanarPoints( this.m_vecDownRight, this.m_vecDownLeft, this.m_vecNear );
            planes[ 3 ].setFromCoplanarPoints( this.m_vecDownLeft, this.m_vecTopLeft, this.m_vecNear );
            planes[ 4 ].setFromCoplanarPoints( this.m_vecTopRight, this.m_vecDownRight, this.m_vecDownLeft );
            planes[ 5 ].setFromCoplanarPoints( this.m_vectemp3, this.m_vectemp2,this.m_vectemp1 );
            planes[ 5].normal.multiplyScalar( - 1 );
            // geo.setFromPoints([this.m_vecTopLeft, this.m_vecTopRight, this.m_vecDownRight, this.m_vecDownLeft, this.m_vecTopLeft]);
            // let mesh = new THREE.Line(geo, new LineBasicMaterial({color:0xFFFFFF}))
            // this.m_threeModel.add(mesh);
            //  _frustum.intersectsObject(())
        }
        else if ( this.m_camera instanceof THREE.OrthographicCamera ) {

            this.m_camera.updateProjectionMatrix();
            this.m_camera.updateMatrixWorld();
            const left = Math.min( startPoint.x, endPoint.x );
            const top = Math.max( startPoint.y, endPoint.y );
            const right = Math.max( startPoint.x, endPoint.x );
            const down = Math.min( startPoint.y, endPoint.y );

            this.m_vecTopLeft.set( left, top, - 1 );
            this.m_vecTopRight.set( right, top, - 1 );
            this.m_vecDownRight.set( right, down, - 1 );
            this.m_vecDownLeft.set( left, down, - 1 );

            this.m_vecFarTopLeft.set( left, top, 1 );
            this.m_vecFarTopRight.set( right, top, 1 );
            this.m_vecFarDownRight.set( right, down, 1 );
            this.m_vecFarDownLeft.set( left, down, 1 );

            this.m_vecTopLeft.unproject( this.m_camera );
            this.m_vecTopRight.unproject( this.m_camera );
            this.m_vecDownRight.unproject( this.m_camera );
            this.m_vecDownLeft.unproject( this.m_camera );

            this.m_vecFarTopLeft.unproject( this.m_camera );
            this.m_vecFarTopRight.unproject( this.m_camera );
            this.m_vecFarDownRight.unproject( this.m_camera );
            this.m_vecFarDownLeft.unproject( this.m_camera );

            const planes = this.m_frustum.planes;

            planes[ 0 ].setFromCoplanarPoints( this.m_vecTopLeft, this.m_vecFarTopLeft, this.m_vecFarTopRight );
            planes[ 1 ].setFromCoplanarPoints( this.m_vecTopRight, this.m_vecFarTopRight, this.m_vecFarDownRight );
            planes[ 2 ].setFromCoplanarPoints( this.m_vecFarDownRight, this.m_vecFarDownLeft,this.m_vecDownLeft );
            planes[ 3 ].setFromCoplanarPoints( this.m_vecFarDownLeft,this.m_vecFarTopLeft, this.m_vecTopLeft );
            planes[ 4 ].setFromCoplanarPoints( this.m_vecTopRight, this.m_vecDownRight, this.m_vecDownLeft );
            planes[ 5 ].setFromCoplanarPoints( this.m_vecFarDownRight,this.m_vecFarTopRight, this.m_vecFarTopLeft );
            planes[ 5 ].normal.multiplyScalar( - 1 );

        }
        else {

            console.error( 'THREE.SelectionBox: Unsupported camera type.' );

        }
    }
    searchChildInFrustum( frustum:THREE.Frustum, object:THREE.Object3D|ModelManager ) {
        if(object instanceof THREE.Object3D){
            if ( object instanceof THREE.Mesh || object instanceof THREE.Line || object instanceof THREE.Points || object instanceof THREE.Sprite) {

                if ( object instanceof THREE.InstancedMesh ) {
    
                    this.instances[ object.uuid ] = [];
    
                    for ( let instanceId = 0; instanceId < object.count; instanceId ++ ) {
    
                        object.getMatrixAt( instanceId, this.m_matrix );
                        this.m_matrix.decompose( this.m_center, this.m_quaternion, this.m_scale );
                        this.m_center.applyMatrix4( object.matrixWorld );
                        if ( frustum.containsPoint( this.m_center ) ) {
                            this.instances[object.uuid].push( instanceId );
                        }
                    }
    
                } else {

                    if ( object.geometry.boundingSphere === null ) object.geometry.computeBoundingSphere();
    
                    this.m_center.copy( object.geometry.boundingSphere.center );
    
                    this.m_center.applyMatrix4( object.matrixWorld );
    
                    if ( frustum.containsPoint( this.m_center ) ) {
                        // let entity = new DBEntity({id:object.uuid, layerId:'three', blockId:'none'})
                        // entity.tag = object;
                        // this.m_collection.push();
                        //this.m_collection.push(object)
                        //console.log('jjjjjjjjjjjjjjjjjjjjjjjj');
                        this.m_collection.push(object)
                    }
                }
            }
    
            if ( object.children.length > 0 ) {
    
                for ( let x = 0; x < object.children.length; x ++ ) {
                    this.searchChildInFrustum( frustum, object.children[ x ] );
                }
    
            }
        }
        else{
            let model = object;
            for(let key in model.Lines)
            {
                let items = model.Lines[key];
                let layer_id = key.split('_')[0];
                if( model.LayerTable[layer_id].Enabled &&  model.LayerTable[layer_id].Visible)
                {
                    items.lines.forEach(line =>
                        {
                            if(line.box && frustum.intersectsBox(line.box))
                            {
                                this.m_collection.push(line);
                            }
                        }
                    );
                }
            }

            for(let key in model.Meshs)
            {
                let items = model.Meshs[key];
                let layer_id = key.split('_')[0];
                if( model.LayerTable[layer_id].Enabled &&  model.LayerTable[layer_id].Visible)
                {
                    items.meshs.forEach(line =>
                        {
                            if(line.box && frustum.intersectsBox(line.box))
                            {
                                this.m_collection.push(line);
                            }
                        }
                    );
                }
            }
            for(let layer_id in model.Instances)
            {
                if( model.LayerTable[layer_id].Enabled &&  model.LayerTable[layer_id].Visible)
                {
                    model.Instances[layer_id].forEach(instance=>{
                        this.m_matrix.decompose( this.m_center, this.m_quaternion, this.m_scale );
                        this.m_center.applyMatrix4( instance.matrix );
                        if ( frustum.containsPoint( this.m_center ) ) {
                            this.m_collection.push(instance);
                        }
                    })
                }
            }
        }

    }
    public get Collection()
    {
        return this.m_collection;
    }
}
