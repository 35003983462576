import { Context } from "../core/context";
import * as THREE from "three";
import { MLayer } from "../core/MLayer";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";

type DistanceInfo = {
  distance: number;
  xDistance: number;
  yDistance: number;
};
export class Measurement {
  private isStarted: boolean = false;
  private clickCount: number = 0;
  private m_context: Context;
  private m_auxModel: MLayer;
  private startLinePoint!: THREE.Vector3;
  private endLinePoint!: THREE.Vector3;
  private auxGroup: THREE.Group;
  private auxGroupItem!: THREE.Group;
  private unit: string = "m";
  private domElement: HTMLDivElement;
  private raycaster = new THREE.Raycaster();
  private m_camera: THREE.Camera;
  private isInstanceObject: boolean = false;
  private updateDashSizeAndGapSizeBind: () => void;
  private isAuxLabelVisible: boolean = false;
  public isLifeMove: boolean = false;
  private callback: (distance: DistanceInfo) => void = () => {};
  private precision: number = 0;
  private intersectsGroup!: THREE.Group;
  constructor(context: Context) {
    this.m_context = context;
    this.m_auxModel = context.SceneManager.AuxModel;
    this.auxGroup = new THREE.Group();
    this.auxGroup.name = "auxGroup";
    this.m_auxModel.add(this.auxGroup);
    this.domElement = context.Dom;
    this.m_camera = context.ViewManager.Camera;
    this.updateDashSizeAndGapSizeBind =
      this.updateDashSizeAndGapSize.bind(this);
  }

  setUnit(unit: string) {
    this.unit = unit;
  }

  setPrecision(precision: number) {
    this.precision = precision;
  }

  setAuxLabelVisible(visible: boolean) {
    this.isAuxLabelVisible = visible;
  }

  start() {
    this.clickCount = 1;
    this.isStarted = true;
    window.addEventListener("wheel", this.updateDashSizeAndGapSizeBind);
    this.hideOrShowAllGroup(true);
  }

  stop() {
    this.isStarted = false;
    this.changeCursor("default");
    window.removeEventListener("wheel", this.updateDashSizeAndGapSizeBind);
    this.hideOrShowAllGroup(false);
  }

  // 测量
  private line: Line2 | undefined;
  private isDrawLine: boolean = false;
  measure(point: THREE.Vector3) {    
    if (!this.isStarted || this.isLifeMove) return;
    if (this.clickCount % 2 == 1 && !this.isInstanceObject) {
      this.auxGroupItem = new THREE.Group();
      this.auxGroup.add(this.auxGroupItem);
      this.auxGroupItem.name = "measureGroup";
      let startPoint = this.createPoint(point);
      this.auxGroupItem.add(...startPoint);
      this.clickCount++;
      this.startLinePoint = point;
      this.line = this.createLine();
      this.auxGroupItem.add(this.line);
      this.isDrawLine = true;
      this.hideAuxLine();
    } else if (this.isDrawLine) {
      let endPoint = this.createPoint(this.endLinePoint);
      this.auxGroupItem.add(...endPoint);
      this.isDrawLine = false;
      let auxLines = this.createAuxLine(this.startLinePoint, this.endLinePoint);
      this.auxGroupItem.add(...auxLines);
      let distanceLabel2dObjectArr = this.createDistanceLabel(
        this.endLinePoint
      );
      this.auxGroupItem.add(...distanceLabel2dObjectArr);
      this.clickCount = 1;
      let radiusLine = this.createRadiusLine();
      this.auxGroupItem.add(radiusLine);
    }
  }

  createPoint(position: THREE.Vector3) {
    let measureTarget = document.createElement("div");
    measureTarget.style.width = "30px";
    measureTarget.style.height = "3px";
    measureTarget.style.left = "15px";
    measureTarget.style.backgroundColor = "rgba(247, 213, 74, 1)";

    let measureTarget2dObject = new CSS2DObject(measureTarget);
    measureTarget2dObject.visible = false;
    measureTarget2dObject.position.copy(position);
    measureTarget2dObject.name = "measurePoint";

    let measureTargetImg = document.createElement("div");
    measureTargetImg.style.width = "15px";
    measureTargetImg.style.height = "15px";
    measureTargetImg.style.background = "url(./icon/circle.png) no-repeat";
    measureTargetImg.style.backgroundSize = "100% 100%";
    // measureTargetImg.style.top = "-15px";
    // measureTargetImg.style.left = "5px";
    let measureTarget2dObjectImg = new CSS2DObject(measureTargetImg);
    measureTarget2dObjectImg.visible = true;
    measureTarget2dObjectImg.position.copy(position);
    measureTarget2dObjectImg.name = "measurePointImg";
    let pointGeometry = new THREE.CircleGeometry(0.5, 32);
    let pointMaterial = new THREE.MeshBasicMaterial();
    let pointMesh = new THREE.Mesh(pointGeometry, pointMaterial);
    pointMesh.name = "measurePoint";
    pointMesh.position.copy(position);
    pointMesh.visible = false;
    return [measureTarget2dObject, measureTarget2dObjectImg, pointMesh];
  }

  createLine() {
    const geometry = new LineGeometry();
    let positions = [
      this.startLinePoint.x,
      this.startLinePoint.y,
      this.startLinePoint.z,
    ];
    geometry.setPositions(positions);
    const matLine = new LineMaterial({
      color: 0xf7d54a,
      linewidth: 2,
    });

    matLine.resolution.set(window.innerWidth, window.innerHeight); //设置分辨率
    let line = new Line2(geometry, matLine);
    line.computeLineDistances(); //计算线段距离
    line.name = "measureLine";
    return line;
  }

  // 范围线
  createRadiusLine() {
    const geometry = new LineGeometry();
    let startPoint = this.startLinePoint
      .clone()
      .lerp(this.endLinePoint.clone(), 0.1);
    let endPoint = this.startLinePoint
      .clone()
      .lerp(this.endLinePoint.clone(), 0.9);
    let positions = [
      startPoint.x,
      startPoint.y,
      startPoint.z,
      endPoint.x,
      endPoint.y,
      endPoint.z,
    ];
    geometry.setPositions(positions);
    const matLine = new LineMaterial({
      color: 0xf1003b,
      linewidth: 30,
    });
    matLine.resolution.set(window.innerWidth, window.innerHeight); //设置分辨率
    let line = new Line2(geometry, matLine);
    line.computeLineDistances(); //计算线段距离
    line.name = "measureRadiusLine";
    line.visible = false;
    return line;
  }

  drawLine(endLinePoint: THREE.Vector3) {
    this.endLinePoint = endLinePoint;
    if (!this.isStarted) return;
    if (!this.line || !this.isDrawLine || !endLinePoint) return;
    let positions = [
      this.startLinePoint.x,
      this.startLinePoint.y,
      this.startLinePoint.z,
      endLinePoint.x,
      endLinePoint.y,
      endLinePoint.z,
    ];

    this.line.geometry.setPositions(positions);
    // this.line.geometry.attributes.position.needsUpdate = true;

  }

  // 辅助线
  createAuxLine(startPoint: THREE.Vector3, endPoint: THREE.Vector3) {
    const geometry1 = new LineGeometry();
    let positions1 = [
      startPoint.x,
      startPoint.y,
      startPoint.z,
      endPoint.x,
      startPoint.y,
      startPoint.z,
    ];
    geometry1.setPositions(positions1);
    const matLine1 = new LineMaterial({
      color: 0xee2c2c,
      linewidth: 3,
      dashed: true,
      gapSize: this.m_camera.position.z * 0.01,
      dashSize: this.m_camera.position.z * 0.01,
    });

    matLine1.resolution.set(window.innerWidth, window.innerHeight); //设置分辨率
    let auxLine1 = new Line2(geometry1, matLine1);
    auxLine1.name = "auxLine";
    auxLine1.computeLineDistances(); //计算线段距离

    const geometry2 = new LineGeometry();
    let positions2 = [
      endPoint.x,
      startPoint.y,
      endPoint.z,
      endPoint.x,
      endPoint.y,
      endPoint.z,
    ];
    geometry2.setPositions(positions2);
    const matLine2 = new LineMaterial({
      color: 0x00ff7f,
      linewidth: 3,
      dashed: true,
      gapSize: this.m_camera.position.z * 0.01,
      dashSize: this.m_camera.position.z * 0.01,
    });

    matLine2.resolution.set(window.innerWidth, window.innerHeight); //设置分辨率
    let auxLine2 = new Line2(geometry2, matLine2);
    auxLine2.computeLineDistances(); //计算线段距离
    auxLine2.name = "auxLine";

    return [auxLine1, auxLine2];
  }

  // 创建线的距离信息标识
  createDistanceLabel(endPosition: THREE.Vector3) {
    let distanceLabel2dObject = this.createdDistanceLabelObject(
      this.startLinePoint,
      endPosition,
      "rgba(247, 213, 74, 1)",
      "distanceLabel"
    );
    let distanceLabel2dObjectX = this.createdDistanceLabelObject(
      new THREE.Vector3(
        this.startLinePoint.x,
        this.startLinePoint.y,
        this.startLinePoint.z
      ),
      new THREE.Vector3(
        endPosition.x,
        this.startLinePoint.y,
        this.startLinePoint.z
      ),
      "rgba(238, 44, 44, 1)",
      "distanceLabelAux"
    );

    let distanceLabel2dObjectY = this.createdDistanceLabelObject(
      new THREE.Vector3(endPosition.x, endPosition.y, endPosition.z),
      new THREE.Vector3(endPosition.x, this.startLinePoint.y, endPosition.z),
      "rgba(0, 255, 127, 1)",
      "distanceLabelAux",
      0.7
    );

    this.callback(this.getDistanceInfo(endPosition));
    return [
      distanceLabel2dObjectX,
      distanceLabel2dObjectY,
      distanceLabel2dObject,
    ];
  }

  createdDistanceLabelObject(
    startPosition: THREE.Vector3,
    endPosition: THREE.Vector3,
    color: string,
    name: string,
    labelOffset: number = 0.5
  ) {
    let distanceTo = this.changeDistanceLabelUnit(
      startPosition.clone(),
      endPosition.clone()
    );

    let distanceLabel = document.createElement("div");
    distanceLabel.style.width = "130px";
    distanceLabel.style.height = "30px";
    distanceLabel.style.color = color;
    distanceLabel.style.textAlign = "center";
    distanceLabel.style.lineHeight = "30px";
    distanceLabel.style.fontWeight = "700";
    distanceLabel.style.position = "absolute";
    distanceLabel.innerHTML =
      distanceTo.toFixed(this.precision) + " " + this.unit;
    let distanceLabelPosition = startPosition
      .clone()
      .lerp(endPosition, labelOffset);

    let distanceLabel2dObject = new CSS2DObject(distanceLabel);
    distanceLabel2dObject.visible = true;
    distanceLabel2dObject.name = name;
    distanceLabel2dObject.position.copy(distanceLabelPosition);
    return distanceLabel2dObject;
  }

  // 根据距离单位改变距离信息
  changeDistanceLabelUnit(
    startPosition: THREE.Vector3,
    endPosition: THREE.Vector3
  ) {
    let distanceTo = startPosition.distanceTo(endPosition);
    switch (this.unit) {
      case "m":
        distanceTo = distanceTo;
        break;
      case "cm":
        distanceTo = distanceTo * 100;
        break;
      case "mm":
        distanceTo = distanceTo * 1000;
        break;
      default:
        break;
    }
    return distanceTo;
  }

  //distance, x，y信息
  getDistanceInfo(endPosition: THREE.Vector3) {
    let distance: number = this.startLinePoint.distanceTo(endPosition);
    let xDistance: number = Math.abs(this.startLinePoint.x - endPosition.x);
    let yDistance: number = Math.abs(this.startLinePoint.y - endPosition.y);
    switch (this.unit) {
      case "m":
        distance = distance;
        xDistance = xDistance;
        yDistance = yDistance;
        break;
      case "cm":
        distance = distance * 100;
        xDistance = xDistance * 100;
        yDistance = yDistance * 100;
        break;
      case "mm":
        distance = distance * 1000;
        xDistance = xDistance * 1000;
        yDistance = yDistance * 1000;
        break;
      default:
        break;
    }
    distance = Number(distance.toFixed(this.precision));
    xDistance = Number(xDistance.toFixed(this.precision));
    yDistance = Number(yDistance.toFixed(this.precision));

    return {
      distance,
      xDistance,
      yDistance,
    };
  }

  setCallback(callback: (distanceInfo: DistanceInfo) => void) {
    this.callback = callback;
  }

  // 所有的辅助线隐藏
  hideAuxLine() {
    this.auxGroup.traverse((item) => {
      if (item instanceof Line2 && item.name === "auxLine") {
        item.visible = false;
      }
      if (item instanceof Line2 && item.name === "measureLine") {
        item.material.color.setHSL(0.16, 0.8, 0.5);
      }

      if (item instanceof CSS2DObject && item.name === "measurePointImg") {
        item.visible = true;
      }
      if (item instanceof CSS2DObject && item.name.includes("distanceLabel")) {
        item.element.style.webkitTextStroke = "0px #ffffff";
        item.element.style.zIndex = "1";
        item.element.style.opacity = "0.6";
      }
      if (item instanceof CSS2DObject && item.name === "distanceLabelAux") {
        item.visible = false;
      }
    });
  }

  // 改变鼠标样式
  changeCursor(cursor: string) {
    this.domElement.style.cursor = cursor;
  }

  // 射线检测
  DetectionLine(event: MouseEvent) {
    let point = new THREE.Vector2();
    // point.x = (event.offsetX / this.m_context.Dom.clientWidth) * 2 - 1;
    // point.y = -(event.offsetY / this.m_context.Dom.clientHeight) * 2 + 1;
    point.x = this.endLinePoint.clone().project(this.m_camera).x;
    point.y = this.endLinePoint.clone().project(this.m_camera).y;
    this.raycaster.setFromCamera(point, this.m_camera);
    const intersects = this.raycaster.intersectObject(this.auxGroup, true);
    if (intersects.length > 0) {
      this.isInstanceObject = true;
      if (
        intersects.some(
          (item) =>
            item.object.name === "measurePoint" ||
            item.object.name === "auxLine"
        )
      ) {
        this.isInstanceObject = false;
      }
      if (event.button === -1) {
        this.onMouseMoveChangeCursor();
      } else if (event.button === 0 && !this.isDrawLine) {
        if (
          intersects.every(
            (item) => item.object.name === "auxLine" && !item.object.visible
          )
        ) {
          return;
        }

        let measureLine = intersects.filter(
          (item) => item.object.name === "measureLine" && item.object.visible
        );
        if (measureLine.length > 0) {
          measureLine.forEach((item) => {
            item.object.parent?.traverse((itm) => {
              if (itm instanceof THREE.Mesh && itm.name === "measurePoint") {
                if (itm.position.equals(this.startLinePoint)) {
                  this.onMouseClickShowAuxLine(
                    item.object.parent as THREE.Group
                  );
                }
              }
            });
          });
        } else {
          this.onMouseClickShowAuxLine(
            intersects[0].object.parent as THREE.Group
          );
        }
      }
    } else {
      if (this.isStarted) {
        this.changeCursor("url(./icon/ruler.png),auto");
      }
      this.isInstanceObject = false;
    }
  }

  // 鼠标移动改变鼠标样式
  onMouseMoveChangeCursor() {
    if(!this.isStarted) return
    if (!this.isDrawLine && this.isInstanceObject) {
      this.changeCursor("pointer");
    } else {
      this.changeCursor("url(./icon/ruler.png),auto");
    }
  }

  //  鼠标点击线段显示激活状态，辅助线
  onMouseClickShowAuxLine(intersectsGroup: THREE.Group) {
    if(!this.isStarted) return
    if (intersectsGroup.children.length > 0) {
      this.hideAuxLine();
      this.intersectsGroup = intersectsGroup;
      intersectsGroup.traverse((item) => {
        if (item instanceof Line2 && item.name === "auxLine") {
          item.visible = true;
        }
        if (item instanceof Line2 && item.name === "measureLine") {
          item.material.color.setHSL(0.16, 0.9, 0.8);
        }
        if (item instanceof CSS2DObject && item.name === "measurePointImg") {
          item.visible = true;
        }
        if (
          item instanceof CSS2DObject &&
          item.name.includes("distanceLabel")
        ) {
          item.element.style.webkitTextStroke = "0.3px #ffffff";
          item.element.style.zIndex = "100";
          item.element.style.opacity = "1";
        }
        if (item instanceof CSS2DObject && item.name === "distanceLabelAux") {
          item.visible = this.isAuxLabelVisible;
        }
      });
    }
  }

  // 更新相机视角时调整虚线大小和间隔
  updateDashSizeAndGapSize() {
    this.auxGroup.traverse((item) => {
      if (item instanceof Line2 && item.name === "auxLine") {
        item.material.dashSize = this.m_camera.position.z * 0.01;
        item.material.gapSize = this.m_camera.position.z * 0.01;
      }
    });
  }

  // 删除点击的线段组
  deleteIntersectsGroup() {
    if (this.intersectsGroup && this.isStarted) {
      // this.intersectsGroup.visible=false
      this.intersectsGroup.traverse((item) => {
        if (item instanceof Line2) {
          item.visible = false;
          item.geometry.dispose();
          item.material.dispose();
        }
        if (item instanceof THREE.Mesh) {
          item.visible = false;
          item.geometry.dispose();
          item.material.dispose();
        }
        if (item instanceof CSS2DObject) {
          item.element.style.display = "none";
          item.element.remove();
        }
      });
      this.auxGroup.remove(this.intersectsGroup);
      this.m_context.ViewManager.Render();
    }
  }

  // 隐藏/显示所有的组
  hideOrShowAllGroup(visible: boolean) {
    let cssRender = document.querySelector(".cssRender") as HTMLElement;
    this.auxGroup.traverse((item) => {
      if (item instanceof Line2 && item.name === "auxLine") {
        item.visible = false;
      }
      if (item instanceof Line2 && item.name === "measureLine") {
        item.visible = visible;
      }
      cssRender.style.display = visible ? "block" : "none";
    });

    this.intersectsGroup &&
      this.intersectsGroup.traverse((itm) => {
        if (itm instanceof Line2 && itm.name === "auxLine") {
          itm.visible = visible;
        }
      });
    this.m_context.ViewManager.Render();
  }

  // 删除全部的测量线段
  clearAllMeasure() {
    this.auxGroup.traverse((item) => {
      if (item instanceof Line2 && item.name === "auxLine") {
        item.visible = false;
        item.geometry.dispose();
        item.material.dispose();
      }
      if (item instanceof THREE.Mesh) {
        item.visible = false;
        item.geometry.dispose();
        item.material.dispose();
      }
      if (item instanceof CSS2DObject) {
        item.element.style.display = "none";
        item.element.remove();
      }
    });
    this.auxGroup.children.length = 0;
    this.m_context.ViewManager.Render();
  }

  // 删除当前的测量线段
  clearMeasure() {
    if (this.isStarted) {
      this.auxGroupItem.traverse((item) => {
        if (item instanceof Line2 && item.name === "auxLine") {
          item.visible = false;
          item.geometry.dispose();
          item.material.dispose();
        }
        if (item instanceof THREE.Mesh) {
          item.visible = false;
          item.geometry.dispose();
          item.material.dispose();
        }
        if (item instanceof CSS2DObject) {
          item.element.style.display = "none";
          item.element.remove();
        }
      });
      this.clickCount = 1
      this.auxGroup.remove(this.auxGroupItem);
      this.m_context.ViewManager.Render();
    }
  }


}
