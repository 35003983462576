import * as THREE from "three";
import {
  BufferGeometry,
  LineSegments,
  Points,
  SphereBufferGeometry,
} from "three";
import { BaseManager } from "../base/BaseManager";
import { Context } from "../core/context";
import { DBEntity } from "../core/DBEntity";
import { DbLine } from "../core/DbLine";
import { DbMesh } from "../core/DbMesh";
// @ts-ignore
import { SelectionBox } from "../core/SelectionBox";
// @ts-ignore
import { IndserObject } from "../core/IndserObject";
import { SelectionData } from "../core/SelectionData";
import { EventManager } from "./EventManager";
import { SelectionHelper } from "../utils/SelectionHelper";
import { ScreenCoordinateHelper } from "../utils/ScreenCoordinateHelper";
import { FreeThreeObject } from "../utils/GeometryUtils";
import { BPMathUtils } from "../utils/BPMathUtils";
import { snap, SnapModel } from "../core/SnapModel";
import { Measurement } from "../draws/Measurement";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import { DEG2RAD, generateUUID } from "three/src/math/MathUtils";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { compress } from "@bokuweb/zstd-wasm";
interface Selection {
  location: { x: number; y: number; z: number };
  entity: DBEntity | THREE.Object3D | undefined;
  code?: string;
  offset?: { x: number; y: number; z: number };
}
interface InsertObject {
  lines: {
    [key: string]: {
      lines: DbLine[];
      model: THREE.LineSegments;
      canEdit: boolean;
    };
  };
  meshs: {
    [key: string]: { meshs: DbMesh[]; canEdit: boolean; model: THREE.Mesh };
  };
}

enum SelectionState {
  None = "none",
  Pick = "pick",
  SelectionBox = "selectionBox",
  Place = "place",
  drawline = "drawline",
}

enum Selection_Event {
  Hover = "hover",
  Pick = "pick",
  SelectionBox = "selectBox",
  Place = "place",
  addhole = "addhole",
  drawline = "drawline",
  snapFilter = "snapFilter",
  inserts = "inserts",
}

class SelectionManager extends BaseManager {
  private m_eventManager: EventManager = new EventManager();
  public m_raycaster: THREE.Raycaster = new THREE.Raycaster();
  private m_plane: THREE.Plane = new THREE.Plane();
  m_isHover: boolean = true;
  private m_paceObject: THREE.Sprite | undefined;

  /**
   * 是否启用捕捉
   */
  private m_enableSnap: boolean = false;
  public get EnableHover() {
    return this.m_isHover;
  }

  public set setEnableHover(visible: boolean) {
    this.m_isHover = visible;
  }

  public multiSelect: boolean;
  public selectionBox: SelectionBox | undefined;
  helper1: SelectionHelper | undefined;
  public m_selection: SelectionData;
  private m_hoverData: SelectionData;
  private m_selectionState: SelectionState = SelectionState.None;

  private m_hovercolor: number = 0xff4addec;
  private m_selectcolor: number = 0xff4addec;
  private m_enableShift: boolean;

  //@ts-ignore;
  private PlaceBeforeCallBack: ((selection: Selection) => boolean) | undefined;

  public holeStartPoint: THREE.Vector3 | undefined;
  public holeEndPoint: THREE.Vector3 | undefined;

  public holeArr: THREE.Box3[] = [];

  private m_snapModel: SnapModel;

  public drawPoints: THREE.Vector3[] = [];
  public drawlineEve: any;
  public sphereTmp: THREE.Object3D | undefined;
  public lineObj: THREE.Line | undefined;

  public HighLighting = true;

  private m_measurement!: Measurement;
  private updateDashSizeAndGapSizeBind: () => void;

  constructor(context: Context) {
    super(context);
    this.m_selection = new SelectionData(context);
    this.m_hoverData = new SelectionData(context, new THREE.Color(0x4addec));
    this.m_plane.set(new THREE.Vector3(0, 0, 1), 0);
    this.m_context.ViewManager.EventManager.addEventListener(
      "pointermove",
      this.onMouseMove.bind(this)
    );
    this.m_context.ViewManager.EventManager.addEventListener(
      "pointerup",
      this.onMouseUp.bind(this)
    );
    this.m_context.ViewManager.EventManager.addEventListener(
      "pointerdown",
      this.onMouseDown.bind(this)
    );
    window.addEventListener("keydown", this.keydown.bind(this));
    window.addEventListener("keyup", this.keyup.bind(this));
    this.multiSelect = false;
    this.m_enableShift = false;
    this.drawlineEve = undefined;
    this.sphereTmp = undefined;
    this.lineObj = undefined;
    this.m_snapModel = new SnapModel(
      this.m_context.ViewManager.m_context.SceneManager.AuxModel
    );
    this.m_measurement = new Measurement(this.m_context);
    this.updateDashSizeAndGapSizeBind =
      this.updateDashSizeAndGapSize.bind(this);
  }

  public get EventManager() {
    return this.m_eventManager;
  }

  /**
   * 恢复默认的hover颜色
   */
  public ResetHoverColor() {
    this.m_hoverData.setColor(this.m_hovercolor, 1);
  }
  /**
   * 恢复默认的搞了颜色
   */
  public ResetSelectionColor() {
    this.m_selection.setColor(this.m_selectcolor, 1);
  }

  /**
   * 设置hover颜色
   * @param color
   * @param opacity
   */
  public SetHoverColor(color: number, opacity: number = 1) {
    this.m_hoverData.setColor(color, opacity);
  }
  /**
   * 设置高亮颜色
   * @param color
   * @param opacity
   */
  public SetSelectionColor(color: number, opacity: number = 1) {
    this.m_selection.setColor(color, opacity);
  }
  public OpenSelectBox(): void {
    this.selectionBox = new SelectionBox(
      this.m_context.ViewManager.Camera,
      this.m_context.ModelManager,
      this.m_context.SceneManager.ThreeLayer,
      this.m_context.SceneManager.SignModel
    );
    this.helper1 = new SelectionHelper(
      this.m_context.ViewManager.EventManager,
      this.m_context.Dom,
      "selectBox"
    );
  }
  public cancelSelectBox(): void {
    this.m_selectionState = SelectionState.None;
    if (this.helper1) {
      this.helper1.dispose();
    }
    this.m_context.ViewManager.Render();
  }

  public onMouseUp(event: MouseEvent) {
    if (event.button != 0) {
      return;
    }
    this.m_hoverData.clear();
    if (!this.HighLighting) return;
    switch (this.m_selectionState) {
      case SelectionState.SelectionBox:
        {
          if (this.helper1 && this.helper1.isDown) {
            this.selectionBox?.m_endPoint.set(
              (event.offsetX / this.m_context.Dom.clientWidth) * 2 - 1,
              -(event.offsetY / this.m_context.Dom.clientHeight) * 2 + 1,
              0.5
            );
            this.selectionBox?.select();
            if (this.selectionBox?.Collection) {
              this.m_selection.addObjects(...this.selectionBox?.Collection);
            }
            //(box: THREE.Box3, entity: DBEntity[])
            let box = new THREE.Box3();
            if (this.m_selection) box.setFromObject(this.m_selection);
            // console.log('hhhhhhhhhhhhhhhhhhhhhhh')
            // console.log(box)
            this.m_eventManager.dispatchEvent(Selection_Event.SelectionBox, {
              box: box,
              entity: this.m_selection.getObjects(),
            });
          }
          this.m_context.ViewManager.Controls.EnablePan = true;
        }
        break;
      case SelectionState.Place:
        {
          let p = this.m_context.ViewManager.Controls.DownPointXY;
          let u = new THREE.Vector2(event.offsetX, event.offsetY);
          if (p.distanceTo(u) > 4)
            //鼠标滑动超过5个像素时，视为移动不进行放置
            return;

          if (this.m_paceObject) {
            let intersectObject = undefined;
            this.GetInsertObject(event.offsetX, event.offsetY, (obj) => {
              intersectObject = obj.obejct[0];
            });
            let target = this.GetPointByScreenXY(event.offsetX, event.offsetY);
            if (
              this.PlaceBeforeCallBack == undefined ||
              this.PlaceBeforeCallBack({
                location: target,
                entity: intersectObject,
              })
            ) {
              let placeObejct = this.m_paceObject.clone();
              placeObejct.position.copy(target);
              this.m_context.SceneManager.SignModel.children.push(placeObejct);
              let select: Selection = {
                location: {
                  x: Number(target.x),
                  y: Number(target.y),
                  z: Number(target.z),
                },
                entity: placeObejct,
              };
              this.m_eventManager.dispatchEvent(Selection_Event.Place, {
                place: select,
                next: this.m_paceObject,
              });
              placeObejct.userData.id = placeObejct.id;
              this.m_context.ViewManager.Render();
            }
          }
        }
        break;
      case SelectionState.drawline: {
        let p = this.m_context.ViewManager.Controls.DownPointXY;
        let u = new THREE.Vector2(event.offsetX, event.offsetY);
        if (p.distanceTo(u) > 4)
          //鼠标滑动超过5个像素时，视为移动不进行放置
          return;
        let target = this.GetPointByScreenXY(event.offsetX, event.offsetY);
        let geo = new THREE.SphereGeometry(0.49, 30, 30);
        let mat = new THREE.MeshBasicMaterial({
          color: "green",
          side: THREE.BackSide,
        });
        let sphere = new THREE.Mesh(geo, mat);
        sphere.userData.name = "drawP";
        sphere.position.copy(target);
        this.m_context.SceneManager.ThreeLayer.children.push(sphere);

        if (!this.sphereTmp) {
          this.sphereTmp = sphere.clone();
          this.m_context.SceneManager.ThreeLayer.children.push(this.sphereTmp);
          this.sphereTmp.userData.name = "drawP2";
        }

        if (this.drawPoints.length > 0) {
          this.drawPoints.splice(this.drawPoints.length - 1, 1);
        }

        this.drawPoints.push(target);

        this.drawPoints.push(target.clone());
        if (this.drawPoints.length > 1) {
          this.clearPointLine("drawL");
          let geoL = new THREE.BufferGeometry().setFromPoints(this.drawPoints);
          let matL = new THREE.MeshBasicMaterial({ color: "blue" });
          this.lineObj = new THREE.Line(geoL, matL);
          this.m_context.SceneManager.ThreeLayer.children.push(this.lineObj);
          this.lineObj.userData.name = "drawL";
        }
        this.m_eventManager.dispatchEvent(Selection_Event.drawline, {
          points: this.drawPoints,
        });
        this.m_context.ViewManager.Render();
      }
      case SelectionState.None:
        {
          if (
            this.m_isHover ||
            this.m_selectionState.toString() == SelectionState.Place
          ) {
            let isInsert = false;
            let startLinePoint = this.GetPointByScreenXY(
              event.offsetX,
              event.offsetY
            );
            this.GetInsertObject(event.offsetX, event.offsetY, (object) => {
              if (object.adjust)
                startLinePoint = new THREE.Vector3(
                  object.adjust!.x,
                  object.adjust!.y,
                  object.adjust!.z
                );
              this.m_eventManager.dispatchEvent(
                Selection_Event.inserts,
                object
              );
              isInsert = true;
              if (this.HighLighting) {
                this.m_hoverData.addObjects(...object.obejct);
              }
              // if(this.m_enableShift&&this.multiSelect){
              //   this.m_selection.addObjects(...object.obejct);
              // }else{
              //   this.m_selection.clear();
              //   this.m_selection.addObjects(...object.obejct);
              // }
            });
            if (!isInsert) {
              let pos = this.GetPointByScreenXY(event.offsetX, event.offsetY);
              this.m_eventManager.dispatchEvent(
                Selection_Event.inserts,
                new IndserObject(pos, 0, "", [])
              );
            }
            this.m_measurement.measure(startLinePoint);
            this.m_measurement.DetectionLine(event);
          }
          if (this.isInsertFile && this.insertMangerCount == 1) {
            this.InsetFileEnd();
          }
        }
        break;

      default: {
        let p = this.m_context.ViewManager.Controls.DownPointXY;
        let u = new THREE.Vector2(event.offsetX, event.offsetY);
        if (p.distanceTo(u) > 4)
          //鼠标滑动超过5个像素时，视为移动不进行放置
          return;
        this.GetInsertObject(event.offsetX, event.offsetY, (obj) => {
          if (this.m_enableShift || this.multiSelect) {
            let rtValk = this.m_selection.clearObj(obj.obejct[0]);
            let select: Selection = {
              location: {
                x: Number(obj.location.x),
                y: Number(obj.location.y),
                z: Number(obj.location.z),
              },
              entity: obj.obejct[0],
            };
            if (!rtValk) {
              this.m_selection.addObjects(...obj.obejct);
              this.m_eventManager.dispatchEvent(Selection_Event.Pick, select);
            } else {
              this.m_eventManager.dispatchEvent(Selection_Event.Pick, select);
            }
          } else {
            let select: Selection = {
              location: {
                x: Number(obj.location.x),
                y: Number(obj.location.y),
                z: Number(obj.location.z),
              },
              entity: obj.obejct[0],
              code: obj.code,
            };
            this.m_eventManager.dispatchEvent(Selection_Event.Pick, select);
            this.m_selection.clear();
            this.m_selection.addObjects(...obj.obejct);
          }
        });

        if (this.m_selectionState == SelectionState.Pick) {
          if (this.isReplaceFile) {
            let isRootIdEqual: boolean = false;
            let instances = this.m_context.ModelManager.Instances;
            for (const key in instances) {
              instances[key].forEach((item: any, index: number) => {
                if (
                  this.selectionData.entity &&
                  item.id == this.selectionData.entity.root_reference_id
                ) {
                  isRootIdEqual = true;
                }
              });
            }
            if (!isRootIdEqual) {
              this.insertGroup =
                this.m_context.SceneManager.ModelLayer.getObjectByName(
                  "insertGroup"
                ) as THREE.Group;

              this.insertGeos = this.m_context.ModelManager.insertGeos;
              this.insertGroup!.visible = false;
              this.isReplaceFile = false;
              this.emptyInsertGroup();
              this.insertGeos.lines = {};
              this.insertGeos.meshs = {};
              this.insertRootId = "";
              this.selectionData.entity = undefined;
              this.CancelPick();
              console.error("没有找到对应的rootId");
              return;
            }

            this.m_context.LoadManager.LoadBlockP2d(
              this.replaceFile.url,
              true,
              this.ReplaceFileEnd.bind(this)
            );
          }
        }
      }
    }
  }

  public clearPointLine(name?: string) {
    if (name) {
      let objarr = this.m_context.SceneManager.ThreeLayer.children.filter(
        (element) => {
          if (element.userData.name != name) {
            return element;
          } else {
            FreeThreeObject(element);
          }
        }
      );
      this.m_context.SceneManager.ThreeLayer.children = objarr;
      if (name == "drawP") {
        this.drawPoints = [];
      }
    } else {
      let objarr = this.m_context.SceneManager.ThreeLayer.children.filter(
        (element) => {
          if (
            element.userData.name != "drawP" &&
            element.userData.name != "drawL" &&
            element.userData.name != "drawP2"
          ) {
            return element;
          } else {
            FreeThreeObject(element);
          }
        }
      );
      this.m_context.SceneManager.ThreeLayer.children = objarr;
      this.drawPoints = [];
    }

    this.m_context.ViewManager.Render();
  }

  public stopDrawLine() {
    this.m_eventManager.removeEventListener(
      Selection_Event.drawline,
      this.drawlineEve
    );
    this.m_selectionState = SelectionState.None;
    this.drawPoints.splice(this.drawPoints.length - 1, 1);

    if (this.lineObj) {
      let length = this.lineObj.geometry.attributes.position.array.length;
      //@ts-ignore
      let arr1 = this.lineObj.geometry.attributes.position.array;
      //@ts-ignore
      this.lineObj.geometry.attributes.position.array = arr1.slice(
        0,
        length - 3
      );
      //@ts-ignore
      this.lineObj.geometry.attributes.position.count -= 1;
      this.lineObj.geometry.attributes.position.needsUpdate = true;
    }

    let objarr;
    if (this.drawPoints.length > 1) {
      objarr = this.m_context.SceneManager.ThreeLayer.children.filter(
        (element) => {
          if (element.userData.name != "drawP2") {
            return element;
          } else {
            FreeThreeObject(element);
          }
        }
      );
    } else {
      objarr = this.m_context.SceneManager.ThreeLayer.children.filter(
        (element) => {
          if (
            element.userData.name != "drawP2" &&
            element.userData.name != "drawP"
          ) {
            return element;
          } else {
            FreeThreeObject(element);
          }
        }
      );
    }

    this.m_context.SceneManager.ThreeLayer.children = objarr;
    this.m_context.ViewManager.Render();
  }

  public checkIntersect(box: THREE.Box3): boolean {
    for (let i = 0; i < this.holeArr.length; i++) {
      if (this.holeArr[i].intersectsBox(box)) {
        return true;
      }
    }
    return false;
  }

  public get HoverData() {
    return this.m_hoverData.getObjects();
  }

  public onMouseDown(event: MouseEvent) {
    //
    // if(this.m_selectionState == SelectionState.drawline && event.button==2){
    //     this.stopDrawLine()
    // }
    if (event.button != 0) {
      return;
    }

    if (!this.multiSelect || !this.m_enableShift) {
      // alert('rrrrrrrrrrrrrrrrrrrrr')
      this.m_selection.clear();
      this.selectionDataArr.length = 0;
      this.m_context.ViewManager.Render();
      // this.startEditSelect = false;
      // this.selectionData.entity = undefined;
      // this.m_context.Dom.style.cursor = "";
      // this.CancelPick();
    }
    //框选
    if (this.m_selectionState == SelectionState.SelectionBox) {
      this.m_context.ViewManager.Controls.EnablePan = false;
      this.selectionBox?.m_startPoint.set(
        (event.offsetX / this.m_context.Dom.clientWidth) * 2 - 1,
        -(event.offsetY / this.m_context.Dom.clientHeight) * 2 + 1,
        0.5
      );
    }
    //点选
    else if (this.m_selectionState == SelectionState.Pick) {
    } else if (this.m_selectionState == SelectionState.Place) {
    }
    //不选
    else if (this.m_selectionState == SelectionState.None) {
      if (
        (this.moveStart || this.rotateStart) &&
        (this.moveLineSelect.length > 0 || this.moveMeshSelect.length > 0)
      ) {
        this.moveLineAuxPointDownCount++;
        if (this.moveLineAuxPointDownCount == 2) {
          if (this.moveStart) {
            this.EndMove();
          } else if (this.rotateStart) {
            this.EndRotate();
          }
        } else {
          let target = this.GetPointByScreenXY(event.offsetX, event.offsetY);
          this.moveLineAuxStartPoint = target;
          this.createdMoveLineAux();
          window.addEventListener("wheel", this.updateDashSizeAndGapSizeBind);
          if (this.rotateStart) {
            this.m_context.Dom.style.cursor = "all-scroll";
          }
        }
      }
    } else if (this.m_selectionState == SelectionState.drawline) {
    }
  }

  private preRotateAngle: number = 0;
  private leftMoveNum: number = 0;
  private mouseMovePosition: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
  public onMouseMove(event: MouseEvent) {
    this.m_hoverData.clear();
    this.mouseMovePosition = this.GetPointByScreenXY(
      event.offsetX,
      event.offsetY
    );
    // this.divtag.style.display='none'
    // clearInterval(this.setInterEve)
    if (this.m_context.ViewManager.Controls.IsPan) {
      this.leftMoveNum++;
      if (this.leftMoveNum > 10) {
        this.m_measurement.isLifeMove = true;
      }

      //|| this.m_selectionState==this.m_selectionState
      return;
    } else {
      this.leftMoveNum = 0;
      this.m_measurement.isLifeMove = false;
    }

    switch (this.m_selectionState) {
      case SelectionState.drawline:
        {
          let target = this.GetPointByScreenXY(event.offsetX, event.offsetY);
          if (this.sphereTmp) {
            this.sphereTmp.position.copy(target);
          }
          // this.drawPoints[this.drawPoints.length-1]=target
          if (this.lineObj) {
            let length = this.lineObj.geometry.attributes.position.array.length;
            //@ts-ignore
            this.lineObj.geometry.attributes.position.array[length - 3] =
              target.x;
            //@ts-ignore
            this.lineObj.geometry.attributes.position.array[length - 2] =
              target.y;
            //@ts-ignore
            this.lineObj.geometry.attributes.position.array[length - 1] =
              target.z;
            this.lineObj.geometry.attributes.position.needsUpdate = true;
          }
        }
        break;
      case SelectionState.None:
        {
          if (
            this.m_isHover ||
            this.m_selectionState.toString() == SelectionState.Place
          ) {
            let isInsert = false;
            let endLinePoint = this.GetPointByScreenXY(
              event.offsetX,
              event.offsetY
            );

            this.GetInsertObject(event.offsetX, event.offsetY, (object) => {
              if (object.adjust)
                endLinePoint = new THREE.Vector3(
                  object.adjust!.x,
                  object.adjust!.y,
                  object.adjust!.z
                );
              this.m_eventManager.dispatchEvent(
                Selection_Event.inserts,
                object
              );
              isInsert = true;
              if (this.HighLighting) {
                this.m_hoverData.addObjects(...object.obejct);
              }
            });
            if (!isInsert) {
              let pos = this.GetPointByScreenXY(event.offsetX, event.offsetY);
              this.m_eventManager.dispatchEvent(
                Selection_Event.inserts,
                new IndserObject(pos, 0, "", [])
              );
            }
            this.m_measurement.drawLine(endLinePoint);
            // 检测鼠标是否碰到所画的线段
            this.m_measurement.DetectionLine(event);
            if (this.moveLineAux) {
              this.moveLineAux?.geometry.setAttribute(
                "position",
                new THREE.BufferAttribute(
                  new Float32Array([
                    this.moveLineAuxStartPoint.x,
                    this.moveLineAuxStartPoint.y,
                    this.moveLineAuxStartPoint.z,
                    endLinePoint.x,
                    endLinePoint.y,
                    endLinePoint.z,
                  ]),
                  3
                )
              );
              this.moveLineAux.computeLineDistances();
              let offset = new THREE.Vector3();
              offset.x = endLinePoint.x - this.moveLineAuxStartPoint.x;
              offset.y = endLinePoint.y - this.moveLineAuxStartPoint.y;
              if (this.moveStart) {
                this.moveLineSelect.forEach((line: any) => {
                  line.position.x = line.startPosition.x + offset.x;
                  line.position.y = line.startPosition.y + offset.y;
                });
                this.moveMeshSelect.forEach((mesh: any) => {
                  mesh.position.x = mesh.startPosition.x + offset.x;
                  mesh.position.y = mesh.startPosition.y + offset.y;
                });
              }
              if (this.rotateStart) {
                let xAxis = new THREE.Vector3(1, 0, 0);
                let rotateAngle = 0;
                if (offset.y < 0) {
                  rotateAngle = -offset.angleTo(xAxis);
                } else {
                  rotateAngle = offset.angleTo(xAxis);
                }
                let subAngle = rotateAngle - this.preRotateAngle;
                this.moveLineSelect.forEach((line: any, index: number) => {
                  if (line instanceof THREE.LineSegments) {
                    let rotatedPosition =
                      line.geometry.attributes.position.clone();
                    for (let i = 0; i < rotatedPosition.count; i++) {
                      let vertex = new THREE.Vector3(
                        rotatedPosition.getX(i),
                        rotatedPosition.getY(i),
                        rotatedPosition.getZ(i)
                      );
                      vertex.sub(this.moveLineAuxStartPoint); // 将顶点相对于中心点进行平移
                      vertex.applyAxisAngle(
                        new THREE.Vector3(0, 0, 1),
                        subAngle
                      ); // 绕Z轴旋转
                      vertex.add(this.moveLineAuxStartPoint); // 将顶点平移到旋转后的位置
                      rotatedPosition.setXYZ(i, vertex.x, vertex.y, vertex.z); // 更新旋转后的顶点位置
                    }
                    line.geometry.attributes.position.copy(rotatedPosition); // 将新的顶点位置应用到几何体上
                    line.geometry.attributes.position.needsUpdate = true;
                    line.geometry.computeBoundingBox();
                    line.geometry.computeBoundingSphere();

                    if (this.moveLineSelect[index - 1] instanceof Line2) {
                      let line2Geo = this.moveLineSelect[index - 1]
                        .geometry as LineGeometry;
                      line2Geo.setPositions(rotatedPosition.array);
                      line2Geo.computeBoundingBox();
                      line2Geo.computeBoundingSphere();
                    }
                  }
                });
                this.moveMeshSelect.forEach((mesh: any) => {
                  let rotatedPosition =
                    mesh.geometry.attributes.position.clone();
                  for (let i = 0; i < rotatedPosition.count; i++) {
                    let vertex = new THREE.Vector3(
                      rotatedPosition.getX(i),
                      rotatedPosition.getY(i),
                      rotatedPosition.getZ(i)
                    );
                    vertex.sub(this.moveLineAuxStartPoint); // 将顶点相对于中心点进行平移
                    vertex.applyAxisAngle(new THREE.Vector3(0, 0, 1), subAngle); // 绕Z轴旋转
                    vertex.add(this.moveLineAuxStartPoint); // 将顶点平移到旋转后的位置
                    rotatedPosition.setXYZ(i, vertex.x, vertex.y, vertex.z); // 更新旋转后的顶点位置
                  }
                  mesh.geometry.attributes.position.copy(rotatedPosition); // 将新的顶点位置应用到几何体上
                  mesh.geometry.attributes.position.needsUpdate = true;
                  mesh.geometry.computeBoundingBox();
                  mesh.geometry.computeBoundingSphere();
                });
                this.preRotateAngle = rotateAngle;
              }
            }
          }

          if (this.isInsertFile) {
            let insertFileTarget = this.GetPointByScreenXY(
              event.offsetX,
              event.offsetY
            );
            this.insertGroup?.position.copy(insertFileTarget);
          }
        }
        break;
      case SelectionState.Place: {
        if (this.m_paceObject) {
          let target = this.GetPointByScreenXY(event.offsetX, event.offsetY);
          this.m_paceObject.position.copy(target);
        }
      }
      case SelectionState.Pick:
        {
          if (!this.m_isHover) return;
          this.GetInsertObject(event.offsetX, event.offsetY, (object) => {
            this.m_hoverData.addObjects(...object.obejct);
          });
        }
        break;
      case SelectionState.SelectionBox: {
        if (this.helper1 && this.helper1.isDown) {
          this.selectionBox?.m_endPoint.set(
            (event.offsetX / this.m_context.Dom.clientWidth) * 2 - 1,
            -(event.offsetY / this.m_context.Dom.clientHeight) * 2 + 1,
            0.5
          );
        }
      }
    }
  }

  private keydown(event: any) {
    //alert(event.shiftKey == 1)
    if (event.keyCode == 17 && !this.isSingleSelect) {
      this.m_enableShift = true;
    }
    if (event.keyCode == 46) {
      this.m_measurement.deleteIntersectsGroup();
    }
  }
  public insertRotate: number = Math.PI / 2;
  private keyup(event: any) {
    this.m_enableShift = false;
    if (event.keyCode == 27) {
      this.m_measurement.clearMeasure();
    }
    if (event.keyCode == 32 && this.insertFile) {
      this.insertGeoAndDataSetRotate(this.insertRotate);
    }
  }
  private insertFileRotateOffsetArr: THREE.Vector3[] = [];
  private originCenter = new THREE.Vector3(0, 0, 0);
  private insertFileRotate=0;
  // 插入的数据进行旋转
  insertGeoAndDataSetRotate(rotate: number) {
    if(this.insertFileRotate>=Math.PI*2){
      this.insertFileRotate=0;
    }
    this.insertFileRotate+=rotate;
    let originVector = new THREE.Vector3(0, 0, 0);
    if (this.insertGroup) {
      this.insertGroup.traverse((item) => {
        if (item instanceof THREE.Mesh || item instanceof THREE.LineSegments) {
          let rotatedPosition = item.geometry.attributes.position.clone();
          if (!this.insertFileRotateOffsetArr.length) {
            this.originCenter = item.geometry.boundingSphere!.center.clone();
          }

          for (let i = 0; i < rotatedPosition.count; i++) {
            let vertex = new THREE.Vector3(
              rotatedPosition.getX(i),
              rotatedPosition.getY(i),
              rotatedPosition.getZ(i)
            );
            vertex.sub(originVector); // 将顶点相对于中心点进行平移
            vertex.applyAxisAngle(new THREE.Vector3(0, 0, 1), rotate); // 绕Z轴旋转
            vertex.add(originVector); // 将顶点平移到旋转后的位置
            rotatedPosition.setXYZ(i, vertex.x, vertex.y, vertex.z); // 更新旋转后的顶点位置
            item.geometry.attributes.position.copy(rotatedPosition); // 将新的顶点位置应用到几何体上
            item.geometry.attributes.position.needsUpdate = true;
            item.geometry.computeBoundingBox();
            item.geometry.computeBoundingSphere();
            this.m_context.ViewManager.Render();
          }
          let center2 = item.geometry.boundingSphere!.center.clone();
          if (!this.insertFileRotateOffsetArr.length) {
            this.insertFileRotateOffsetArr.push(
              new THREE.Vector3(
                center2.x - this.originCenter.x,
                center2.y - this.originCenter.y,
                center2.z - this.originCenter.z
              )
            );
          } else {
            this.insertFileRotateOffsetArr[0] = new THREE.Vector3(
              center2.x - this.originCenter.x,
              center2.y - this.originCenter.y,
              center2.z - this.originCenter.z
            );
          }
        }
      });
    }
    for (const key in this.insertGeos.lines) {
      this.insertGeos.lines[key].lines.forEach((item: any) => {
        let points = [...item.points];
        let rotatedPosition = new THREE.BufferAttribute(
          new Float32Array(points),
          3
        );
        for (let i = 0; i < rotatedPosition.count; i++) {
          let vertex = new THREE.Vector3(
            rotatedPosition.getX(i),
            rotatedPosition.getY(i),
            rotatedPosition.getZ(i)
          );
          vertex.sub(originVector); // 将顶点相对于中心点进行平移
          vertex.applyAxisAngle(new THREE.Vector3(0, 0, 1), rotate); // 绕Z轴旋转
          vertex.add(originVector); // 将顶点平移到旋转后的位置
          rotatedPosition.setXYZ(i, vertex.x, vertex.y, vertex.z); // 更新旋转后的顶点位置
          item.points = rotatedPosition.array as Float32Array;
          let modelCenter =
            this.insertGeos.lines[key].model.geometry.boundingSphere!.center;
          item.sphere.center = {
            x: modelCenter.x,
            y: modelCenter.y,
            z: modelCenter.z,
          };
          item.sphere.radius =
            this.insertGeos.lines[key].model.geometry.boundingSphere?.radius;
          this.m_context.ViewManager.Render();
        }
      });
    }
    for (const key in this.insertGeos.meshs) {
      this.insertGeos.meshs[key].meshs.forEach((item: any) => {
        let rotatedPosition = new THREE.BufferAttribute(item.vertexs, 3);
        for (let i = 0; i < rotatedPosition.count; i++) {
          let vertex = new THREE.Vector3(
            rotatedPosition.getX(i),
            rotatedPosition.getY(i),
            rotatedPosition.getZ(i)
          );
          vertex.sub(originVector); // 将顶点相对于中心点进行平移
          vertex.applyAxisAngle(new THREE.Vector3(0, 0, 1), rotate); // 绕Z轴旋转
          vertex.add(originVector); // 将顶点平移到旋转后的位置
          rotatedPosition.setXYZ(i, vertex.x, vertex.y, vertex.z); // 更新旋转后的顶点位置
          item.vertexs = rotatedPosition.array as Float32Array;
          let modelCenter =
            this.insertGeos.meshs[key].model.geometry.boundingSphere!.center;
          item.sphere.center = {
            x: modelCenter.x,
            y: modelCenter.y,
            z: modelCenter.z,
          };
          item.sphere.radius =
            this.insertGeos.meshs[key].model.geometry.boundingSphere?.radius;
          this.m_context.ViewManager.Render();
        }
      });
    }
  }

  public GetInsertObject(
    sx: number,
    sy: number,
    callback: (param: IndserObject) => void
  ) {
    let camera = this.m_context.ViewManager.Camera;
    let x = (sx / this.m_context.Dom.offsetWidth) * 2 - 1;
    let y = -(sy / this.m_context.Dom.offsetHeight) * 2 + 1;
    this.m_raycaster.setFromCamera(new THREE.Vector2(x, y), camera);
    let ray = this.m_raycaster.ray;
    let position = new THREE.Vector3();
    camera.getWorldPosition(position).z;
    //@ts-ignore
    let halfAngle = camera.fov * 0.5 * (Math.PI / 180);
    let L = Math.tan(halfAngle) * camera.position.z * 2;
    let P = this.m_context.Dom.offsetHeight;
    let P2 = L / P;
    let mindis = P2 * 5;
    if (
      this.m_raycaster.intersectObject(this.m_context.SceneManager.MaskLayer)
        .length > 0
    ) {
      return;
    }
    let array: IndserObject[] = [];
    this.m_context.ModelManager.GetInsertObject(ray, mindis, (params) => {
      array.push(params);
      return this.m_enableSnap;
    });
    //抛出snapFilter事件，在此处可加入需要捕捉的点位
    this.m_eventManager.dispatchEvent(Selection_Event.snapFilter, {
      ray: ray,
      array: array,
      tol: mindis,
    });
    if (array.length > 0) {
      if (this.m_enableSnap) {
        //返回最佳捕捉点
       let posType:string[]= array.map((item) => {
          return item.position
        })
        let posArr = array.filter((item) => {
          if((posType.includes('start')||posType.includes('end')||posType.includes('cross'))){
            if (!item.position.includes("aux")) {
              return true;
            }
          }else{
            return true;
          }
        });

        let min = BPMathUtils.min(posArr, (item) => item.distance);
        if (min) {
          callback(min);
          let posistion = min.adjust
            ? new THREE.Vector3(min.adjust.x, min.adjust.y, min.adjust.z)
            : new THREE.Vector3(min.location.x, min.location.y, min.location.z);
          let fun: { [key: string]: () => void } = {
            start: () => {
              this.m_snapModel.set(snap.end, posistion, P2);
            },
            end: () => {
              this.m_snapModel.set(snap.end, posistion, P2);
            },
            center: () => {
              this.m_snapModel.set(snap.center, posistion, P2);
            },
            insert: () => {
              this.m_snapModel.set(snap.insert, posistion, P2);
            },
            cross: () => {
              this.m_snapModel.set(snap.cross, posistion, P2);
            },
            vertical: () => {
              this.m_snapModel.set(snap.vertical, posistion, P2);
            },
            auxOrthogonal: () => {
              this.m_snapModel.set(snap.other, posistion, P2);
            },
            auxVertical: () => {
              this.m_snapModel.set(snap.other, posistion, P2);
            },
          };
          if (fun[min.position]) fun[min.position]();
          else this.m_snapModel.set(snap.none, new THREE.Vector3(0, 0, 0), P2);
        }
      } else {
        callback(array[0]);
        this.m_snapModel.set(snap.none, new THREE.Vector3(0, 0, 0), P2);
        // this.m_snapModel.set(snap.cross, new THREE.Vector3(array[0].adjust!.x, array[0].adjust!.y, array[0].adjust!.z), P2);
      }
      return;
    } else {
      this.m_snapModel.set(snap.none, new THREE.Vector3(0, 0, 0), P2);
    }
    let insts = this.m_raycaster.intersectObject(
      this.m_context.SceneManager.SignModel
    );
    // console.log('vvvvvvvvvvvvvvvvvvvvvvv')
    // console.log(insts)
    // console.log('vvvvvvvvvvvvvvvvvvvvvvv')
    if (insts.length > 0) {
      let obj = insts[0].object;
      let location = {
        x: Number(insts[0].point.x),
        y: Number(insts[0].point.y),
        z: Number(insts[0].point.z),
      };
      callback(new IndserObject(location, insts[0].distance, "", [obj]));
      return;
    }
    let temp: THREE.Intersection[] = [];
    if (!this.m_context.SceneManager.ThreeLayer.userData.disEnable) {
      insts = this.m_raycaster.intersectObject(
        this.m_context.SceneManager.ThreeLayer
      );
    }
    insts.forEach((item) => {
      if (item.object.visible) temp.push(item);
    });
    insts = temp;
    if (insts.length > 0) {
      let objs = insts.map((inst) => {
        return inst.object;
      });
      let location = {
        x: Number(insts[0].point.x),
        y: Number(insts[0].point.y),
        z: Number(insts[0].point.z),
      };
      callback(new IndserObject(location, insts[0].distance, "", objs));
      return;
    }
  }

  public get CameraRay() {
    return this.m_raycaster;
  }

  public distanceSqLineToPoint(
    start: THREE.Vector3,
    end: THREE.Vector3,
    point: THREE.Vector3
  ): number {
    let a = end.clone().sub(start);
    let b = point.clone().sub(start);
    let c = a.dot(b) / a.lengthSq();
    if (c < 0) {
      return b.lengthSq();
    }
    if (c > 1) {
      return point.clone().sub(end).lengthSq();
    }
    return point
      .clone()
      .sub(start.clone().add(a.clone().multiplyScalar(c)))
      .lengthSq();
  }

  /**
   *
   * @param hoverCallback hover回调
   * @param pickCallback 拾取回调，返回ture 继续拾取， false 停止拾取
   * @param layerId
   */
  private moveDbLines: any = [];
  private moverDbMesh: any = [];
  private selectionData: any = {};
  private selectionDataArr: any = [];
  private selectData!: Selection | Selection[];
  private pick!: (selection: Selection) => void;
  private isSingleSelect: boolean = false;
  public Pick(
    pickCallback: (slection: Selection | Selection[]) => boolean,
    layerId: string | undefined = undefined,
    isSingleSelect: boolean = false
  ) {
    this.m_context.Dom.style.cursor = "crosshair";
    this.multiSelect = true;
    this.isSingleSelect = isSingleSelect;
    let ids: string[] = [];
    if (layerId) {
      for (let key in this.m_context.ModelManager.LayerTable) {
        let item = this.m_context.ModelManager.LayerTable[key];
        if ((item.Id! = layerId)) {
          ids.push(layerId);
        }
      }
      // this.m_context.ViewManager.EnableLayerByIds(false, ...ids);
    }

    this.pick = (selection: Selection) => {
      // if (this.multiSelect) {
      let includesArr: any = [];
      this.selectionDataArr.forEach((item: any, index: number) => {
        if (selection.entity instanceof DBEntity) {
          if (selection.entity.root_reference_id) {
            if (
              item.entity.root_reference_id ==
              selection.entity!.root_reference_id
            ) {
              includesArr.push(index);
            }
          } else {
            if (
              item.entity.id + item.entity.block_path ==
              selection.entity!.id + selection.entity!.block_path
            ) {
              includesArr.push(index);
            }
          }
        }
      });

      if (includesArr.length > 0) {
        this.selectionDataArr.splice(includesArr[0], 1);
      } else {
        this.selectionDataArr.push(selection);
      }
      this.selectData = [...this.selectionDataArr];
      if (Array.isArray(this.selectData) && this.selectData.length == 1) {
        this.selectData = this.selectData[0];
      }
      // }
      // else {
      //   this.clearSelection();
      //   this.selectionDataArr.length = 0;
      //   this.selectData = selection;
      // }
      if (!pickCallback(this.selectData)) {
        this.selectionData = { ...selection, entity: { ...selection.entity } };
      }
    };
    this.m_eventManager.addEventListener(Selection_Event.Pick, this.pick);
    this.m_selectionState = SelectionState.Pick;
  }

  clearSelection() {
    this.moveLineSelect.forEach((item) => {
      let moveLineMaterial = item.material as THREE.Material;
      moveLineMaterial.dispose();
      item.geometry.dispose();
      this.m_context.SceneManager.ModelLayer.remove(item);
    });
    this.moveMeshSelect.forEach((mesh) => {
      if (mesh instanceof THREE.Mesh) {
        let moveMeshMaterial = mesh.material as THREE.Material;
        moveMeshMaterial.dispose();
        mesh.geometry.dispose();
        this.m_context.SceneManager.ModelLayer.remove(mesh);
      }
    });
    this.moveDbLines.length = 0;
    this.moveLineSelect.length = 0;
    this.moverDbMesh.length = 0;
    this.moveMeshSelect.length = 0;
  }

  clearSelectFn() {
    this.moveLineSelect.forEach((item) => {
      if (item.name == "activeLine") {
        let activeMaterial = item.material as THREE.Material;
        activeMaterial.dispose();
        item.geometry.dispose();
        this.m_context.SceneManager.ModelLayer.remove(item);
      }
      if (item instanceof LineSegments || item.name == "moveLine") {
        let moveLineMaterial = item.material as THREE.Material;
        moveLineMaterial.dispose();
        item.geometry.dispose();
        this.m_context.SceneManager.ModelLayer.remove(item);
      }
    });
    this.moveMeshSelect.forEach((mesh) => {
      if (mesh instanceof THREE.Mesh) {
        let moveMeshMaterial = mesh.material as THREE.Material;
        moveMeshMaterial.dispose();
        mesh.geometry.dispose();
        this.m_context.SceneManager.ModelLayer.remove(mesh);
      }
    });
    for (const key in this.linesModelsAll) {
      this.moveDbLines.forEach(
        (itm: { line: DbLine; key: string }, idx: number) => {
          if (itm.key == key) {
            // 把这条线添加到对应的类中
            this.linesModelsAll[key].lines.push(itm.line);
          }
        }
      );
      this.linesToModel(
        this.linesModelsAll[key].lines,
        this.linesModelsAll[key].model
      );
    }

    for (const key in this.meshsModelsAll) {
      if (this.meshsModelsAll[key].meshs.length > 0) {
        this.meshsModelsAll[key].meshs.forEach((item, index) => {
          // 如果移动的网格中包含这个网格
          this.moverDbMesh.forEach(
            (itm: { mesh: DbMesh; key: string }, idx: number) => {
              if (itm.key == key) {
                // 把这个网格添加到对应的类中
                if (!this.meshsModelsAll[key].meshs.includes(itm.mesh)) {
                  this.meshsModelsAll[key].meshs.push(itm.mesh);
                }
              }
            }
          );
        });
      } else {
        this.moverDbMesh.forEach(
          (itm: { mesh: DbMesh; key: string }, idx: number) => {
            if (itm.key == key) {
              // 把这个网格添加到对应的类中
              if (!this.meshsModelsAll[key].meshs.includes(itm.mesh)) {
                this.meshsModelsAll[key].meshs.push(itm.mesh);
              }
            }
          }
        );
      }
      this.MeshToModel(
        this.meshsModelsAll[key].meshs,
        this.meshsModelsAll[key].model
      );
    }

    this.moveDbLines.length = 0;
    this.moverDbMesh.length = 0;
  }

  // 获得所有的Lines
  private linesModelsAll = this.m_context.ModelManager.Lines;
  // 获得所有的Meshs
  private meshsModelsAll = this.m_context.ModelManager.Meshs;
  // 筛选出rootId相同类的线和网格
  filtrateRootIdLineAndMesh(entity: any) {
    // 不是多选需要删除上一个
    if (!this.multiSelect) {
      this.clearSelectFn();
    }
    for (const key in this.linesModelsAll) {
      if (this.linesModelsAll[key] && this.linesModelsAll[key].canEdit) {
        this.linesModelsAll[key].lines.forEach((item, index) => {
          // 判断是否和点击的属于同一类
          if (item.root_reference_id == entity.root_reference_id) {
            // 如果是同一类需要根据数据创建新的线
            this.createdMoveLine(
              item,
              this.linesModelsAll[key].model.material as THREE.LineBasicMaterial
            );
            this.moveDbLines.push({ line: item, key });
          }
        });

        if (
          this.moveDbLines.some(
            (item: { mesh: DbLine; key: string }) => item.key == key
          )
        ) {
          let rootReferenceIdArr = this.moveDbLines.map(
            (item: any) =>
              item.line.root_reference_id + item.line.block_path + item.line.id
          );

          this.linesModelsAll[key].lines = this.linesModelsAll[
            key
          ].lines.filter((item, index) => {
            let rootReferenceId =
              item.root_reference_id! + item.block_path + item.id;
            if (!rootReferenceIdArr.includes(rootReferenceId)) {
              return true;
            }
          });
          this.linesToModel(
            this.linesModelsAll[key].lines,
            this.linesModelsAll[key].model
          );
        }
      }
    }

    for (const key in this.meshsModelsAll) {
      if (this.meshsModelsAll[key] && this.meshsModelsAll[key].canEdit) {
        this.meshsModelsAll[key].meshs.forEach((item, index) => {
          if (item.root_reference_id == entity.root_reference_id!) {
            this.createdMoveMesh(
              item,
              this.meshsModelsAll[key].model.material as THREE.MeshBasicMaterial
            );
            this.moverDbMesh.push({ mesh: item, key });
          }
        });
        if (
          this.moverDbMesh.some(
            (item: { mesh: DbMesh; key: string }) => item.key == key
          )
        ) {
          let rootReferenceIdArr = this.moverDbMesh.map(
            (item: any) =>
              item.mesh.root_reference_id + item.mesh.block_path + item.mesh.id
          );
          this.meshsModelsAll[key].meshs = this.meshsModelsAll[
            key
          ].meshs.filter((item, index) => {
            let rootReferenceId =
              item.root_reference_id! + item.block_path + item.id;
            if (rootReferenceId) {
              if (!rootReferenceIdArr.includes(rootReferenceId)) {
                return true;
              }
            } else {
              return true;
            }
          });
          this.MeshToModel(
            this.meshsModelsAll[key].meshs,
            this.meshsModelsAll[key].model
          );
        }
      }
      this.moveMeshSelect.forEach((meshSelect) => {
        let oldMaterial = meshSelect.material as THREE.MeshBasicMaterial;
        let newMaterial = oldMaterial.clone();
        newMaterial.color = new THREE.Color(0x4addec);
        meshSelect.material = newMaterial;
      });
    }
  }
  // 筛选出id， block_path相同类的线和网络
  filtrateIdAndBlockPathLineAndMesh(entity: any) {
    if (!this.multiSelect) {
      this.clearSelectFn();
    }
    for (const key in this.linesModelsAll) {
      if (this.linesModelsAll[key] && this.linesModelsAll[key].canEdit) {
        this.linesModelsAll[key].lines.forEach((item, index) => {
          // 判断是否和点击的属于同一类
          if (item.id == entity.id && item.block_path == entity.block_path) {
            // 如果是同一类需要根据数据创建新的线
            this.createdMoveLine(
              item,
              this.linesModelsAll[key].model.material as THREE.LineBasicMaterial
            );
            this.moveDbLines.push({ line: item, key });
          }
        });

        if (
          this.moveDbLines.some(
            (item: { mesh: DbLine; key: string }) => item.key == key
          )
        ) {
          let idBlockPathLinesArr = this.moveDbLines.map(
            (item: any) =>
              item.root_reference_id + item.line.block_path + item.line.id
          );

          this.linesModelsAll[key].lines = this.linesModelsAll[
            key
          ].lines.filter((item, index) => {
            let rootReferenceId =
              item.root_reference_id + item.block_path! + item.id;
            if (!idBlockPathLinesArr.includes(rootReferenceId)) {
              return true;
            }
          });
          this.linesToModel(
            this.linesModelsAll[key].lines,
            this.linesModelsAll[key].model
          );
        }
      }
    }

    for (const key in this.meshsModelsAll) {
      if (this.meshsModelsAll[key] && this.meshsModelsAll[key].canEdit) {
        this.meshsModelsAll[key].meshs.forEach((item, index) => {
          if (item.block_path + item.id == entity.block_path + entity.id) {
            this.createdMoveMesh(
              item,
              this.meshsModelsAll[key].model.material as THREE.MeshBasicMaterial
            );
            this.moverDbMesh.push({ mesh: item, key });
          }
        });
        if (
          this.moverDbMesh.some(
            (item: { mesh: DbMesh; key: string }) => item.key == key
          )
        ) {
          let idBlockPathMeshsArr = this.moverDbMesh.map(
            (item: any) =>
              item.root_reference_id + item.mesh.block_path + item.mesh.id
          );

          this.meshsModelsAll[key].meshs = this.meshsModelsAll[
            key
          ].meshs.filter((item, index) => {
            let rootReferenceId =
              item.root_reference_id + item.block_path! + item.id;
            if (!idBlockPathMeshsArr.includes(rootReferenceId)) {
              return true;
            }
          });
          this.MeshToModel(
            this.meshsModelsAll[key].meshs,
            this.meshsModelsAll[key].model
          );
        }
      }

      this.moveMeshSelect.forEach((meshSelect) => {
        let oldMaterial = meshSelect.material as THREE.MeshBasicMaterial;
        let newMaterial = oldMaterial.clone();
        newMaterial.color = new THREE.Color(0x4addec);
        meshSelect.material = newMaterial;
      });
    }
  }

  // 把移动后的数据转换成线和网格
  moveLineAndMeshToModel() {
    // 遍历所有的Lines
    for (const key in this.linesModelsAll) {
      if (this.linesModelsAll[key].lines.length > 0) {
        this.linesModelsAll[key].lines.forEach((item, index) => {
          this.moveDbLines.forEach(
            (itm: { line: DbLine; key: string }, idx: number) => {
              if (itm.key == key) {
                // 把这条线添加到对应的类中
                if (!this.linesModelsAll[key].lines.includes(itm.line)) {
                  this.linesModelsAll[key].lines.push(itm.line);
                }
              }
            }
          );
        });
      } else {
        this.moveDbLines.forEach(
          (itm: { line: DbLine; key: string }, idx: number) => {
            if (itm.key == key) {
              // 把这条线添加到对应的类中
              if (!this.linesModelsAll[key].lines.includes(itm.line)) {
                this.linesModelsAll[key].lines.push(itm.line);
              }
            }
          }
        );
      }
      this.linesModelsAll[key].model.position.set(0, 0, 0);
      this.linesToModel(
        this.linesModelsAll[key].lines,
        this.linesModelsAll[key].model
      );
    }
    // 遍历所有的Meshs

    for (const key in this.meshsModelsAll) {
      if (this.meshsModelsAll[key].meshs.length > 0) {
        this.meshsModelsAll[key].meshs.forEach((item, index) => {
          // 如果移动的网格中包含这个网格
          this.moverDbMesh.forEach(
            (itm: { mesh: DbMesh; key: string }, idx: number) => {
              if (itm.key == key) {
                // 把这个网格添加到对应的类中
                if (!this.meshsModelsAll[key].meshs.includes(itm.mesh)) {
                  this.meshsModelsAll[key].meshs.push(itm.mesh);
                }
              }
            }
          );
        });
      } else {
        this.moverDbMesh.forEach(
          (itm: { mesh: DbMesh; key: string }, idx: number) => {
            if (itm.key == key) {
              // 把这个网格添加到对应的类中
              if (!this.meshsModelsAll[key].meshs.includes(itm.mesh)) {
                this.meshsModelsAll[key].meshs.push(itm.mesh);
              }
            }
          }
        );
      }
      this.meshsModelsAll[key].model.position.set(0, 0, 0);
      this.MeshToModel(
        this.meshsModelsAll[key].meshs,
        this.meshsModelsAll[key].model
      );
    }
  }

  private startEditSelect: boolean = false;
  private selcetCallBack!: (selection: Selection | Selection[]) => boolean;
  StartMoveSelect(
    callback: (selection: Selection | Selection[]) => boolean,
    layerId: string | undefined = undefined,
    isSingleSelect: boolean = true
  ) {
    this.startEditSelect = true;
    this.linesModelsAll = this.m_context.ModelManager.Lines;
    this.meshsModelsAll = this.m_context.ModelManager.Meshs;
    this.selcetCallBack = callback;
    this.Pick(callback, layerId, isSingleSelect);
  }

  createdMoveLine(entity: DbLine, material: THREE.LineBasicMaterial) {
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute(
      "position",
      new THREE.BufferAttribute(entity.points!, 3)
    );
    geometry.setIndex([...entity.indexes!]);
    const lineMesh = new THREE.LineSegments(geometry, material) as any;
    lineMesh.startPosition = lineMesh.position.clone();
    lineMesh.name = "moveLine";
    lineMesh.userData = {
      id: entity.id,
      block_path: entity.block_path,
      root_reference_id: entity.root_reference_id,
    };
    this.createdActiveLine(entity.points!);

    this.moveLineSelect.push(lineMesh);
    this.m_context.SceneManager.ModelLayer.add(lineMesh);
  }

  // 创建激活状态线
  createdActiveLine(points: Float32Array) {
    const geometry = new LineGeometry();
    geometry.setPositions(points);
    const material = new LineMaterial({
      color: 0x4addec,
      linewidth: 1,
      dashed: false,
    });
    material.resolution.set(window.innerWidth, window.innerHeight);
    const line = new Line2(geometry, material) as any;
    line.startPosition = line.position.clone();
    line.name = "activeLine";

    this.moveLineSelect.push(line);
    this.m_context.SceneManager.ModelLayer.add(line);
  }

  linesToModel(lines: DbLine[], lineMesh: THREE.LineSegments) {
    let position: number[] = [];
    let index = 0;
    let indexs: number[] = [];
    lines.forEach((item) => {
      item.points.forEach((p) => {
        position.push(p);
      });
      item.indexes?.forEach((v) => {
        indexs.push(v + index);
      });
      index += item.points.length / 3;
    });
    let posistionArray = new Float32Array(position);
    let lineGeo = lineMesh.geometry as THREE.BufferGeometry;
    lineGeo.setAttribute(
      "position",
      new THREE.BufferAttribute(posistionArray, 3)
    );
    lineGeo.setIndex(indexs);
    lineGeo.attributes.position.needsUpdate = true;
    lineGeo.computeBoundingBox();
    lineGeo.computeBoundingSphere();
  }

  private moveMeshSelect: THREE.Mesh[] = [];
  createdMoveMesh(entity: DbMesh, material: THREE.Material) {
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute(
      "position",
      new THREE.BufferAttribute(entity.vertexs!, 3)
    );
    const mesh = new THREE.Mesh(geometry, material) as any;
    mesh.name = "moveMesh";
    mesh.userData = {
      id: entity.id,
      block_path: entity.block_path,
      root_reference_id: entity.root_reference_id,
    };
    mesh.startPosition = mesh.position.clone();
    this.moveMeshSelect.push(mesh);
    this.m_context.SceneManager.ModelLayer.add(mesh);
  }

  MeshToModel(meshs: DbMesh[], modelMesh: THREE.Mesh) {
    let posistion: number[] = [];
    meshs.forEach((geo) => {
      geo.vertexs?.forEach((v) => {
        posistion.push(v);
      });
    });
    let posistionArray = new Float32Array(posistion);
    let meshGeo = modelMesh.geometry as THREE.BufferGeometry;
    meshGeo.setAttribute(
      "position",
      new THREE.BufferAttribute(posistionArray, 3)
    );
    meshGeo.attributes.position.needsUpdate = true;
    meshGeo.computeBoundingBox();
    meshGeo.computeBoundingSphere();
  }

  private moveLineAux: THREE.Line | undefined;
  private moveLineAuxStartPoint: THREE.Vector3 = new THREE.Vector3();
  private moveLineAuxPointDownCount: number = 0;

  createdMoveLineAux() {
    const geometry = new THREE.BufferGeometry();
    const matLine = new THREE.LineDashedMaterial({
      color: 0xf7d54a,
      linewidth: 1,
      scale: 1,
      dashSize: this.m_context.ViewManager.Camera.position.z * 0.01, //虚线的大小
      gapSize: this.m_context.ViewManager.Camera.position.z * 0.01, //虚线之间的间隔大小
    });
    let positions = new THREE.BufferAttribute(
      new Float32Array([
        this.moveLineAuxStartPoint.x,
        this.moveLineAuxStartPoint.y,
        this.moveLineAuxStartPoint.z,
        this.moveLineAuxStartPoint.x,
        this.moveLineAuxStartPoint.y,
        this.moveLineAuxStartPoint.z,
      ]),
      3
    );

    geometry.setAttribute("position", positions);
    let lineAux = new THREE.Line(geometry, matLine);
    lineAux.name = "moveLineAux";
    lineAux.computeLineDistances();
    this.m_context.SceneManager.ModelLayer.add(lineAux);
    this.moveLineAux = lineAux;
  }

  filterSelectionData() {
    this.m_selection.clear();
    if (!this.selectionDataArr.length) {
      this.CancelPick();
      return;
    }
    this.selectionDataArr.forEach((selection: Selection) => {
      if (selection.entity) {
        let entity = selection.entity as DBEntity;
        if (entity.root_reference_id) {
          this.filtrateRootIdLineAndMesh(entity);
        } else {
          this.filtrateIdAndBlockPathLineAndMesh(entity);
        }
      }
    });
  }
  private moveStart: boolean = false;
  private moveLineSelect: THREE.LineSegments[] | Line2[] = [];
  StartMoveLines() {
    this.m_selectionState = SelectionState.None;
    if (!this.copyStart) {
      this.filterSelectionData();
    }
    if (!this.moverDbMesh.length && !this.moveDbLines.length) return;
    this.moveStart = true;
    this.m_context.Dom.style.cursor = "crosshair";
    this.selectionData.entity = undefined;
  }

  // 结束移动线段/网格
  EndMove() {
    this.moveLineAuxPointDownCount = 0;
    this.moveStart = false;
    let instanceObj: { rootId: string; pos: THREE.Vector3 }[] = [];
    if (this.moveLineAux) {
      this.moveLineAux?.geometry.dispose();
      let auxMaterial = this.moveLineAux!.material as THREE.Material;
      auxMaterial.dispose();
      this.m_context.SceneManager.ModelLayer.remove(this.moveLineAux!);
      this.moveLineAux = undefined;
    }

    let posistion = new THREE.Vector3();
    this.moveLineSelect.forEach((item) => {
      if (item.name == "activeLine") {
        let activeMaterial = item.material as THREE.Material;
        activeMaterial.dispose();
        item.geometry.dispose();
        this.m_context.SceneManager.ModelLayer.remove(item);
      }
      if (item instanceof LineSegments || item.name == "moveLine") {
        posistion = item.position.clone();
        let moveLineMaterial = item.material as THREE.Material;
        moveLineMaterial.dispose();
        item.geometry.dispose();
        this.m_context.SceneManager.ModelLayer.remove(item);
      }
    });

    this.moveDbLines.forEach(
      (item: { line: DbLine; key: string }, index: number) => {
        item.line.points.forEach((p, idx) => {
          if ((idx + 1) % 3 == 1) {
            item.line.points[idx] += posistion.x;
          }
          if ((idx + 1) % 3 == 2) {
            item.line.points[idx] += posistion.y;
          }
        });

        this.moveDbLines[index].line.sphere!.center.x =
          item.line.sphere?.center.x! + posistion.x;
        this.moveDbLines[index].line.sphere!.center.y =
          item.line.sphere?.center.y! + posistion.y;

        if (!this.copyStart) {
          if (item.line.type === "insert") {
            this.insertGroupPositions.forEach((insertPosition: any) => {
              if (insertPosition.rootId == item.line.root_reference_id) {
                let insertPos = posistion
                  .clone()
                  .add(insertPosition.position.clone());
                this.m_context.ModelManager.setInstances(
                  item.line.root_reference_id!,
                  insertPos
                );
              }
            });
          } else {
            let insPos = this.instancePosition(item.line.root_reference_id!);
            if (insPos) {
              let pos = insPos.add(posistion);
              instanceObj.push({ rootId: item.line.root_reference_id!, pos });
            }
          }
        }
      }
    );

    this.moveMeshSelect.forEach((mesh) => {
      if (mesh instanceof THREE.Mesh) {
        posistion = mesh.position.clone();
        let moveMeshMaterial = mesh.material as THREE.Material;
        moveMeshMaterial.dispose();
        mesh.geometry.dispose();
        this.m_context.SceneManager.ModelLayer.remove(mesh);
      }
    });

    this.moverDbMesh.forEach(
      (itm: { mesh: DbMesh; key: string }, index: number) => {
        itm.mesh.vertexs?.forEach((v, idx) => {
          if ((idx + 1) % 3 == 1) {
            this.moverDbMesh[index].mesh.vertexs![idx] += posistion.x;
          }
          if ((idx + 1) % 3 == 2) {
            this.moverDbMesh[index].mesh.vertexs![idx] += posistion.y;
          }
        });

        this.moverDbMesh[index].mesh.sphere!.center.x =
          itm.mesh.sphere?.center.x! + posistion.x;
        this.moverDbMesh[index].mesh.sphere!.center.y =
          itm.mesh.sphere?.center.y! + posistion.y;
        if (!this.copyStart) {
          if (itm.mesh.type === "insert") {
            this.insertGroupPositions.forEach((insertPosition: any) => {
              if (insertPosition.rootId == itm.mesh.root_reference_id) {
                let insertPos = posistion
                  .clone()
                  .add(insertPosition.position.clone());
                this.m_context.ModelManager.setInstances(
                  itm.mesh.root_reference_id!,
                  insertPos
                );
              }
            });
          } else {
            let insPos = this.instancePosition(itm.mesh.root_reference_id!);
            if (insPos) {
              let pos = insPos.add(posistion);
              instanceObj.push({ rootId: itm.mesh.root_reference_id!, pos });
            }
          }
        }
      }
    );

    let deInstances = this.arrObjDeWeight(instanceObj);
    if (deInstances.length) {
      deInstances.forEach((inst) => {
        this.m_context.ModelManager.setInstances(inst.rootId!, inst.pos);
      });
    }
    let selectDataCp: any = [];
    if (Array.isArray(this.selectData)) {
      this.selectData.forEach((item: any, index: number) => {
        item.offset = {
          x: posistion.x,
          y: posistion.y,
          z: posistion.z,
        };
        selectDataCp.push({ ...item });
        selectDataCp[index].entity = { ...item.entity };
      });
    } else {
      this.selectData.offset = {
        x: posistion.x,
        y: posistion.y,
        z: posistion.z,
      };
      selectDataCp = { ...this.selectData };
      selectDataCp.entity = { ...this.selectData.entity };
    }

    if (!this.copyStart) {
      this.moveLineAndMeshToModel();
      this.selcetCallBack(this.selectData);
    } else {
      this.copyDbLines = this.moveDbLines.map((item: any, index: number) => {
        let cpItem = {
          key: item.key,
          line: new DbLine({
            id: item.line.id,
            blockId: item.line.blockId,
            indexes: item.line.indexes,
            layerId: item.line.layerId,
            points: item.line.points,
            root_block_id: item.line.root_block_id,
          }),
          location: { x: 0, y: 0, z: 0 },
        };
        cpItem.line.sphere = { ...item.line.sphere };
        cpItem.line.box = { ...item.line.box };
        cpItem.line.block_path = generateUUID();
        cpItem.line.id = generateUUID();
        // cpItem.line.root_reference_id = item.line.root_reference_id;
        if (item.line.root_reference_id) {
          cpItem.line.root_reference_id =
            item.line.root_reference_id + this.copyCount.toString() + "x";
          if (Array.isArray(selectDataCp)) {
            selectDataCp.forEach((selectData: any) => {
              if (
                selectData.entity.root_reference_id ==
                item.line.root_reference_id
              ) {
                selectData.entity.root_reference_id =
                  cpItem.line.root_reference_id;
              }
            });
          } else {
            let entity = selectDataCp.entity as DBEntity;
            if (entity!.root_reference_id == item.line.root_reference_id) {
              entity.root_reference_id = cpItem.line.root_reference_id;
            }
          }

          if (
            !this.m_context.ModelManager.Group[cpItem.line.root_reference_id]
          ) {
            this.m_context.ModelManager.Group[cpItem.line.root_reference_id] = {
              key: item.key,
              lines: [],
              mesh: [],
            };
          }
          this.m_context.ModelManager.Group[
            cpItem.line.root_reference_id
          ].lines.push(cpItem.line);
        }
        this.insertItemPosition(cpItem, item.line.root_reference_id!);
        return cpItem;
      });

      this.copyDbLines.forEach((copyLine: any) => {
        let pos = new THREE.Vector3(
          copyLine.location.x,
          copyLine.location.y,
          copyLine.location.z
        ).add(posistion.clone());
        this.m_context.ModelManager.setInstances(
          copyLine.line.root_reference_id!,
          pos
        );
      });

      this.copyDbMeshs = this.moverDbMesh.map((item: any, index: number) => {
        let cpItem = {
          key: item.key,
          mesh: new DbMesh({
            id: item.mesh.id,
            blockId: item.mesh.blockId,
            indexes: item.mesh.indexes,
            layerId: item.mesh.layerId,
            vertexs: item.mesh.vertexs,
            root_block_id: item.mesh.root_block_id,
          }),
          location: { x: 0, y: 0, z: 0 },
        };
        cpItem.mesh.sphere = { ...item.mesh.sphere };
        cpItem.mesh.box = { ...item.mesh.box };
        cpItem.mesh.block_path = generateUUID();
        cpItem.mesh.id = generateUUID();
        cpItem.mesh.root_reference_id = item.mesh.root_reference_id;
        if (item.mesh.root_reference_id) {
          cpItem.mesh.root_reference_id =
            item.mesh.root_reference_id + this.copyCount.toString() + "x";
          if (Array.isArray(selectDataCp)) {
            selectDataCp.forEach((selectData: any) => {
              if (
                selectData.entity.root_reference_id ==
                item.mesh.root_reference_id
              ) {
                selectData.entity.root_reference_id =
                  cpItem.mesh.root_reference_id;
              }
            });
          } else {
            let entity = selectDataCp.entity as DBEntity;
            if (entity!.root_reference_id == item.mesh.root_reference_id) {
              entity.root_reference_id = cpItem.mesh.root_reference_id;
            }
          }
          if (
            !this.m_context.ModelManager.Group[cpItem.mesh.root_reference_id]
          ) {
            this.m_context.ModelManager.Group[cpItem.mesh.root_reference_id] = {
              key: item.key,
              lines: [],
              mesh: [],
            };
          }
          this.m_context.ModelManager.Group[
            cpItem.mesh.root_reference_id
          ].mesh.push(cpItem.mesh);
        }
        this.insertItemPosition(cpItem, item.mesh.root_reference_id!);
        return cpItem;
      });
      this.copyDbMeshs.forEach((copyMesh: any) => {
        let pos = new THREE.Vector3(
          copyMesh.location.x,
          copyMesh.location.y,
          copyMesh.location.z
        ).add(posistion.clone());
        this.m_context.ModelManager.setInstances(
          copyMesh.mesh.root_reference_id!,
          pos
        );
      });
      this.copyLineAndMeshToModel();
      this.selcetCallBack(selectDataCp);
    }

    this.CancelPick();
    this.moveDbLines.length = 0;
    this.moveLineSelect.length = 0;
    this.moverDbMesh.length = 0;
    this.moveMeshSelect.length = 0;
    this.copyStart = false;
    window.removeEventListener("wheel", this.updateDashSizeAndGapSizeBind);
  }

  // 数组对象去重
  arrObjDeWeight(arr: any[]) {
    let map = new Map();
    for (const item of arr) {
      if (!map.has(item.rootId)) {
        map.set(item.rootId, item);
      }
    }
    return [...map.values()];
  }

  instancePosition(rootId: string | number): any {
    let instances = this.m_context.ModelManager.Instances;
    let keys = Object.keys(instances);
    if (keys.length) {
      for (const key in instances) {
        for (let i = 0; i < instances[key].length; i++) {
          if (instances[key][i].id == rootId) {
            let insertPos = new THREE.Vector3();
            insertPos.setFromMatrixPosition(instances[key][i].matrix);
            return insertPos;
          }
        }
      }
    }
  }

  insertItemPosition(cpItem: any, rootId: number) {
    let instances = this.m_context.ModelManager.Instances;
    let keys = Object.keys(instances);
    if (keys.length) {
      for (const key in instances) {
        instances[key].forEach((instance: any) => {
          if (instance.id == rootId) {
            let insertPos = { x: 0, y: 0, z: 0 };
            insertPos.x = instance.matrix.elements[12];
            insertPos.y = instance.matrix.elements[13];
            insertPos.z = instance.matrix.elements[14];
            cpItem.location = insertPos;
          }
        });
      }
    }
  }

  // 更新相机视角时调整虚线大小和间隔
  updateDashSizeAndGapSize() {
    if (!this.moveLineAux) return;
    let material = this.moveLineAux!.material as THREE.LineDashedMaterial;
    material.dashSize = this.m_context.ViewManager.Camera.position.z * 0.01;
    material.gapSize = this.m_context.ViewManager.Camera.position.z * 0.01;
  }

  // 删除选中的线或网格
  DeletedSelect() {
    if (!this.startEditSelect) return;
    let entity: DBEntity[] = [];
    this.m_selection.getObjects().forEach((item) => {
      if (item instanceof DBEntity) {
        entity.push(item);
      }
    });
    this.m_context.ModelManager.DeleteEntity(...entity);
    this.ClearSelected();
    this.CancelPick();
  }

  // 复制选中的线或网格

  private copyStart: boolean = false;
  private copyDbLines: any = [];
  private copyDbLinesOrgin: any = [];
  private copyDbMeshs: any = [];
  private copyDbMeshsOrgin: any = [];
  private copyCount: number = 0;
  CopySelect() {
    if (!this.startEditSelect) return;
    this.copyCount++;
    this.copyDbLinesOrgin.length = 0;
    this.copyDbLines.length = 0;
    this.copyDbMeshs.length = 0;
    this.copyDbMeshsOrgin.length = 0;
    this.copyStart = true;
    this.filterSelectionData();
    this.moveLineAndMeshToModel();
    this.copyDbLinesOrgin = this.copyDbLinesFn();
    this.copyDbMeshsOrgin = this.copyDbMeshFn();
    this.StartMoveLines();
  }

  private copyDbLinesFn() {
    return this.moveDbLines.map((item: any) => {
      let cpItem = {
        key: item.key,
        line: new DbLine({
          id: item.line.id,
          blockId: item.line.blockId,
          indexes: item.line.indexes,
          layerId: item.line.layerId,
          points: new Float32Array([...item.line.points]),
          root_reference_id: item.line.root_reference_id,
          root_block_id: item.line.root_block_id,
        }),
      };
      cpItem.line.sphere = {
        ...item.line.sphere,
        center: { ...item.line.sphere.center },
      };
      cpItem.line.block_path = item.line.block_path;
      cpItem.line.box = {
        ...item.line.box,
        max: { ...item.line.box.max },
        min: { ...item.line.box.min },
      };
      cpItem.line.type = item.line.type;
      return cpItem;
    });
  }

  private copyDbMeshFn() {
    return this.moverDbMesh.map((item: any) => {
      let cpItem = {
        key: item.key,
        mesh: new DbMesh({
          id: item.mesh.id,
          blockId: item.mesh.blockId,
          layerId: item.mesh.layerId,
          indexes: item.mesh.indexes,
          vertexs: new Float32Array([...item.mesh.vertexs]),
          root_reference_id: item.mesh.root_reference_id,
          root_block_id: item.mesh.root_block_id,
        }),
      };
      cpItem.mesh.sphere = {
        ...item.mesh.sphere,
        center: { ...item.mesh.sphere.center },
      };
      cpItem.mesh.block_path = item.mesh.block_path;
      cpItem.mesh.box = {
        ...item.mesh.box,
        max: { ...item.mesh.box.max },
        min: { ...item.mesh.box.min },
      };
      cpItem.mesh.type = item.mesh.type;
      return cpItem;
    });
  }

  // 把复制的数据转换成线和网格
  private copyLineAndMeshToModel() {
    for (const key in this.linesModelsAll) {
      if (this.linesModelsAll[key].lines.length > 0) {
        this.linesModelsAll[key].lines.forEach((item, index) => {
          this.copyDbLines.forEach(
            (itm: { line: DbLine; key: string }, idx: number) => {
              if (itm.key == key) {
                // 把这条线添加到对应的类中
                if (!this.linesModelsAll[key].lines.includes(itm.line)) {
                  this.linesModelsAll[key].lines.push(itm.line);
                }
              }
            }
          );
          this.copyDbLinesOrgin.forEach(
            (itm: { line: DbLine; key: string }, idx: number) => {
              if (itm.key == key) {
                // 把这条线添加到对应的类中
                if (!this.linesModelsAll[key].lines.includes(itm.line)) {
                  this.linesModelsAll[key].lines.push(itm.line);
                }
                // 如果id,block_path或root_reference_id相同
                if (item.root_reference_id && itm.line.root_reference_id) {
                  this.linesModelsAll[key].lines = this.linesModelsAll[
                    key
                  ].lines.filter((item: any) => {
                    if (item.root_reference_id !== itm.line.root_reference_id) {
                      return true;
                    }
                  });
                } else if (!itm.line.root_reference_id) {
                  this.linesModelsAll[key].lines = this.linesModelsAll[
                    key
                  ].lines.filter((item: any) => {
                    if (
                      item.id !== itm.line.id &&
                      item.block_path !== itm.line.block_path
                    ) {
                      return true;
                    }
                  });
                }
              }
            }
          );
        });
      } else {
        this.copyDbLines.forEach(
          (itm: { line: DbLine; key: string }, idx: number) => {
            if (itm.key == key) {
              // 把这条线添加到对应的类中
              if (!this.linesModelsAll[key].lines.includes(itm.line)) {
                this.linesModelsAll[key].lines.push(itm.line);
              }
            }
          }
        );
      }

      this.copyDbLinesOrgin.forEach(
        (itm: { line: DbLine; key: string }, idx: number) => {
          if (itm.key == key) {
            // 把这条线添加到对应的类中
            if (!this.linesModelsAll[key].lines.includes(itm.line)) {
              this.linesModelsAll[key].lines.push(itm.line);
            }
          }
        }
      );

      this.linesToModel(
        this.linesModelsAll[key].lines,
        this.linesModelsAll[key].model
      );
    }

    for (const key in this.meshsModelsAll) {
      if (this.meshsModelsAll[key].meshs.length > 0) {
        this.meshsModelsAll[key].meshs.forEach((item, index) => {
          // 如果移动的网格中包含这个网格
          this.copyDbMeshs.forEach(
            (itm: { mesh: DbMesh; key: string }, idx: number) => {
              if (itm.key == key) {
                // 把这个网格添加到对应的类中
                if (!this.meshsModelsAll[key].meshs.includes(itm.mesh)) {
                  this.meshsModelsAll[key].meshs.push(itm.mesh);
                }
              }
            }
          );
          this.copyDbMeshsOrgin.forEach(
            (itm: { mesh: DbMesh; key: string }, idx: number) => {
              if (itm.key == key) {
                // 把这个网格添加到对应的类中
                if (!this.meshsModelsAll[key].meshs.includes(itm.mesh)) {
                  this.meshsModelsAll[key].meshs.push(itm.mesh);
                }
                // 如果id,block_path或root_reference_id相同
                if (item.root_reference_id && itm.mesh.root_reference_id) {
                  this.meshsModelsAll[key].meshs = this.meshsModelsAll[
                    key
                  ].meshs.filter((item: any) => {
                    if (item.root_reference_id !== itm.mesh.root_reference_id) {
                      return true;
                    }
                  });
                } else {
                  this.meshsModelsAll[key].meshs = this.meshsModelsAll[
                    key
                  ].meshs.filter((item: any) => {
                    if (
                      item.id !== itm.mesh.id &&
                      item.block_path !== itm.mesh.block_path
                    ) {
                      return true;
                    }
                  });
                }
              }
            }
          );
        });
      } else {
        this.copyDbMeshs.forEach(
          (itm: { mesh: DbMesh; key: string }, idx: number) => {
            if (itm.key == key) {
              // 把这个网格添加到对应的类中
              if (!this.meshsModelsAll[key].meshs.includes(itm.mesh)) {
                this.meshsModelsAll[key].meshs.push(itm.mesh);
              }
            }
          }
        );
      }

      this.copyDbMeshsOrgin.forEach(
        (itm: { mesh: DbMesh; key: string }, idx: number) => {
          if (itm.key == key) {
            // 把这个网格添加到对应的类中
            if (!this.meshsModelsAll[key].meshs.includes(itm.mesh)) {
              this.meshsModelsAll[key].meshs.push(itm.mesh);
            }
          }
        }
      );

      this.MeshToModel(
        this.meshsModelsAll[key].meshs,
        this.meshsModelsAll[key].model
      );
    }

    this.copyDbLinesOrgin.forEach((item: any) => {
      if (this.m_context.ModelManager.Group[item.line.root_reference_id]) {
        this.m_context.ModelManager.Group[item.line.root_reference_id].lines =
          this.copyDbLinesOrgin
            .filter((itm: any) => {
              if (itm.line.root_reference_id == item.line.root_reference_id) {
                return true;
              }
            })
            .map((itm: any) => itm.line);
      }
    });

    this.copyDbMeshsOrgin.forEach((item: any) => {
      if (this.m_context.ModelManager.Group[item.mesh.root_reference_id]) {
        this.m_context.ModelManager.Group[item.mesh.root_reference_id].mesh =
          this.copyDbMeshsOrgin
            .filter((itm: any) => {
              if (itm.mesh.root_reference_id == item.mesh.root_reference_id) {
                return true;
              }
            })
            .map((itm: any) => itm.mesh);
      }
    });
  }

  // 插入文件
  private insertFileComplete: boolean = false;
  private isInsertFile: boolean = false;
  private insertGroup!: THREE.Group;
  private insertMangerCount: number = 0;
  private insertGeos: InsertObject = { lines: {}, meshs: {} };
  private insertCallback!: (
    posistion: THREE.Vector3,
    m_selection: (DBEntity | THREE.Object3D)[],
    m_raycaster: THREE.Raycaster,
    matrix?: THREE.Matrix4
  ) => Promise<any>;
  private insertRootId: string = "";
  private isInsertContinuous: boolean = false;
  private insertFile: any;
  private isInsertManger!: (callback: any) => boolean;
  public insertFileScale: number = 1;
  InsertFile(
    file: any,
    callback: (
      posistion: THREE.Vector3,
      m_selection: (DBEntity | THREE.Object3D)[],
      m_raycaster: THREE.Raycaster,
      matrix?: THREE.Matrix4
    ) => Promise<string>,
    isInsertContinuous: boolean = false,
    scale: number = 1,
    isInsertManger?: (callback: any) => boolean
  ) {
    this.insertFile = file;
    this.insertFileScale = scale;
    this.isInsertContinuous = isInsertContinuous;
    this.m_context.ModelManager.isInsert = true;
    this.isInsertFile = true;
    this.insertFileRotate=0;
    this.insertGroup = this.m_context.SceneManager.ModelLayer.getObjectByName(
      "insertGroup"
    ) as THREE.Group;
    if (!this.insertGroup) {
      this.m_context.ModelManager.addInsertGroup();
      this.insertGroup = this.m_context.SceneManager.ModelLayer.getObjectByName(
        "insertGroup"
      ) as THREE.Group;
    } else {
      this.insertGroup.children.forEach((item) => {
        this.m_context.SceneManager.ModelLayer.remove(item);
      });
      this.insertGroup.children.length = 0;
      this.insertGeos.lines = {};
      this.insertGeos.meshs = {};
      this.insertRootId = "";
      this.m_context.ViewManager.Render();
    }
    this.m_context.LoadManager.LoadBlockP2d(file.url, true, () => {
      this.m_context.Dom.style.cursor = "crosshair";
      this.insertGroup!.visible = true;
      this.insertFileComplete = true;
      this.insertMangerCount = 1;
      this.insertCallback = callback;
      this.isInsertManger = isInsertManger!;
      this.insertGeoSetScale();
      this.insertDataSetScale();
      this.insertFileRotateOffsetArr.length = 0;
    });
  }

  insertGeoSetScale() {
    if (this.insertGroup) {
      this.insertGroup.traverse((item) => {
        if (item instanceof THREE.Mesh || item instanceof LineSegments) {
          item.geometry.scale(
            this.insertFileScale,
            this.insertFileScale,
            this.insertFileScale
          );
        }
      });
    }
  }
  insertDataSetScale() {
    this.insertGeos = this.m_context.ModelManager.insertGeos;
    for (const key in this.insertGeos.lines) {
      this.insertGeos.lines[key].lines.forEach((item) => {
        let points = [...item.points];
        points.forEach((p, idx) => {
          if ((idx + 1) % 3 !== 0) {
            points[idx] *= this.insertFileScale;
          }
        });
        item.points = new Float32Array(points);
      });
    }
    for (const key in this.insertGeos.meshs) {
      this.insertGeos.meshs[key].meshs.forEach((item) => {
        let vertexs = [...item.vertexs];
        vertexs.forEach((v, idx) => {
          if ((idx + 1) % 3 !== 0) {
            vertexs[idx] *= this.insertFileScale;
          }
        });
        item.vertexs = new Float32Array(vertexs);
      });
    }
  }

  private insertGroupPositions: any = [];

  private InsetFileEnd(closeContinuous: boolean = false) {
    this.insertFileComplete = false;
    this.insertMangerCount = 0;
    if (closeContinuous) {
      this.insertGroup.children.forEach((item) => {
        this.m_context.SceneManager.ModelLayer.remove(item);
      });
      this.insertGroup.children.length = 0;
      this.insertGeos.lines = {};
      this.insertGeos.meshs = {};
      this.insertRootId = "";
      this.m_context.ViewManager.Render();
      this.isInsertFile = this.m_context.ModelManager.isInsert = false;
      this.insertGroup.visible = false;
      return;
    }
    if (this.InsertFilePositions && this.InsertFilePositions.length) {
      this.insertGroup.position.set(
        this.InsertFilePositions[this.InsertFileToPositionNum].x as number,
        this.InsertFilePositions[this.InsertFileToPositionNum].y as number,
        this.InsertFilePositions[this.InsertFileToPositionNum].z as number
      );
    }
    let posistion = this.insertGroup.position.clone();
    let insertFileMatrix=new THREE.Matrix4();
    insertFileMatrix.compose(posistion,new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,0,1),this.insertFileRotate),new THREE.Vector3(this.insertFileScale,this.insertFileScale,this.insertFileScale));
    const insertCall = () => {
      this.insertCallback(
        posistion,
        this.m_selection.getObjects(),
        this.m_raycaster,
        insertFileMatrix
      ).then(({ isInsert, rootId }) => {
        if (!isInsert) {
          // this.insertGeos = this.m_context.ModelManager.insertGeos;
          this.insertGroup.children.forEach((item: any) => {
            if (
              item instanceof THREE.Mesh ||
              item instanceof THREE.LineSegments
            ) {
              item.geometry.dispose();
              item.material.dispose();
              item.removeFromParent();
            }
          });
          this.insertGroup.children.length = 0;
          this.insertGeos.lines = {};
          this.insertGeos.meshs = {};
          console.error("插入失败");
          return;
        }
        this.insertRootId = rootId.toString();
        this.insertLineAndMeshToModel(posistion);
        if (this.insertFileRotateOffsetArr.length) {
          posistion.x += this.insertFileRotateOffsetArr[0].x;
          posistion.y += this.insertFileRotateOffsetArr[0].y;
          posistion.z += this.insertFileRotateOffsetArr[0].z;
        }
        this.m_context.ModelManager.setInstances(this.insertRootId, posistion);
        for (const key in this.insertGeos.lines) {
          let insertGeosLinesLength = this.insertGeos.lines[key].lines.length;
          let linesModelsAllLength = this.linesModelsAll[key].lines.length;
          for (
            let i = linesModelsAllLength - insertGeosLinesLength;
            i < this.linesModelsAll[key].lines.length;
            i++
          ) {
            this.linesModelsAll[key].lines[i].root_reference_id = rootId;
          }
          this.addRootReferenceId(
            this.insertRootId,
            key,
            this.insertGeos.lines[key],
            "lines"
          );
        }

        for (const key in this.insertGeos.meshs) {
          let insertGeosMeshsLength = this.insertGeos.meshs[key].meshs.length;
          let meshsModelsAllLength = this.meshsModelsAll[key].meshs.length;
          for (
            let i = meshsModelsAllLength - insertGeosMeshsLength;
            i < this.meshsModelsAll[key].meshs.length;
            i++
          ) {
            this.meshsModelsAll[key].meshs[i].root_reference_id = rootId;
          }
          this.addRootReferenceId(
            this.insertRootId,
            key,
            this.insertGeos.meshs[key],
            "meshs"
          );
        }
        this.insertGroupPositions.push({
          rootId: this.insertRootId,
          position: posistion,
        });

        if (!this.isInsertContinuous) {
          this.isInsertFile = this.m_context.ModelManager.isInsert = false;
          this.insertGroup!.visible = false;
        }

        this.insertGeos.lines = {};
        this.insertGeos.meshs = {};

        if (this.InsertFilePositions && this.InsertFilePositions.length) {
          this.InsertFileToPositionNum++;
          if (this.InsertFileToPositionNum < this.InsertFilePositions.length) {
            this.isInsertFile = this.m_context.ModelManager.isInsert = true;
            for (const key in this.insertGeosCopy.lines) {
              let obejct: any = {};
              obejct[key] = this.insertGeosCopy.lines[key].lines.map(
                (item: any) => {
                  let cpItem = new DbLine({
                    id: generateUUID(),
                    blockId: item.blockId,
                    indexes: item.indexes,
                    layerId: item.layerId,
                    points: new Float32Array([...item.points]),
                    root_reference_id: item.root_reference_id,
                    root_block_id: item.root_block_id,
                  });
                  cpItem.sphere = {
                    ...item.sphere,
                    center: { ...item.sphere.center },
                  };
                  cpItem.block_path = generateUUID();
                  cpItem.box = {
                    ...item.box,
                    max: { ...item.box.max },
                    min: { ...item.box.min },
                  };
                  // cpItem.root_reference_id = item.root_reference_id;
                  cpItem.type = item.type;
                  return cpItem;
                }
              );
              this.m_context.ModelManager.addlines(
                obejct,
                this.insertGeosCopy.lines[key].canEdit
              );
            }
            for (const key in this.insertGeosCopy.meshs) {
              let obejct: any = {};
              obejct[key] = this.insertGeosCopy.meshs[key].meshs.map(
                (item: any) => {
                  let cpItem = new DbMesh({
                    id: item.id,
                    blockId: item.blockId,
                    layerId: item.layerId,
                    indexes: item.indexes,
                    vertexs: new Float32Array([...item.vertexs]),
                    root_reference_id: item.root_reference_id,
                    root_block_id: item.root_block_id,
                  });

                  cpItem.sphere = {
                    ...item.sphere,
                    center: { ...item.sphere.center },
                  };
                  cpItem.block_path = item.block_path;
                  cpItem.box = {
                    ...item.box,
                    max: { ...item.box.max },
                    min: { ...item.box.min },
                  };

                  // cpItem.root_reference_id = item.root_reference_id;
                  cpItem.type = item.type;
                  return cpItem;
                }
              );
              this.m_context.ModelManager.addMeshs(
                obejct,
                this.insertGeosCopy.meshs[key].canEdit
              );
            }
            this.InsetFileEnd();
          } else {
            this.InsertFilePositions.length = 0;
            this.CloseContinuousInsert();
            this.insertGeos.lines = {};
            this.insertGeos.meshs = {};
            this.insertGeosCopy.lines = {};
            this.insertGeosCopy.meshs = {};
            this.isInsertFile = this.m_context.ModelManager.isInsert = false;
            this.insertFileToPositionEndCallBack();
          }
        }
        this.insertRootId = "";
        if (this.isInsertContinuous) {
          this.InsertFile(
            this.insertFile,
            this.insertCallback,
            true,
            this.insertFileScale,
            this.isInsertManger
          );
        }
      });
    };
    if (this.isInsertManger) {
      this.isInsertManger(insertCall);
    } else {
      insertCall();
    }
  }

  private InsertFileToPositionNum = 0;
  private InsertFilePositions!: {
    x: number | string;
    y: number | string;
    z: number | string;
  }[];
  private multipleFile: any;
  private insertGeosCopy: InsertObject = { lines: {}, meshs: {} };
  public isMultipleInsert: boolean = false;
  private insertFileToPositionEndCallBack!: () => void;

  InsertFileToPosition(
    file: any,
    callback: (
      posistion: THREE.Vector3,
      m_selection: (DBEntity | THREE.Object3D)[],
      m_raycaster: THREE.Raycaster
    ) => Promise<string>,
    positions: { x: number | string; y: number | string; z: number | string }[],
    scale: number = 1,
    CompleteCallback: () => void
  ) {
    if (!this.isMultipleInsert) return;
    this.insertFileToPositionEndCallBack = CompleteCallback;
    this.InsertFileToPositionNum = 0;
    this.insertCallback = callback;
    this.isInsertContinuous = false;
    this.InsertFilePositions = positions;
    this.multipleFile = file;
    this.insertFileScale = scale;
    this.multipleInsertFile();
  }

  private multipleInsertFile() {
    this.isInsertFile = this.m_context.ModelManager.isInsert = true;
    this.insertGroup = this.m_context.SceneManager.ModelLayer.getObjectByName(
      "insertGroup"
    ) as THREE.Group;
    this.insertGeos = this.m_context.ModelManager.insertGeos;
    if (!this.insertGroup) {
      this.m_context.ModelManager.addInsertGroup();
      this.insertGroup = this.m_context.SceneManager.ModelLayer.getObjectByName(
        "insertGroup"
      ) as THREE.Group;
      this.insertGeos = this.m_context.ModelManager.insertGeos;
    }
    this.m_context.LoadManager.LoadBlockP2d(this.multipleFile.url, true, () => {
      this.m_context.Dom.style.cursor = "crosshair";
      this.insertGroup!.visible = true;
      this.insertFileComplete = true;
      this.insertGeoSetScale();
      this.insertDataSetScale();
      this.copyInsertGeosFn(this.insertGeos, this.insertGeosCopy);
      this.InsetFileEnd();
    });
  }

  private copyInsertGeosFn(
    originInsertGeos: InsertObject,
    copyInsertGeos: InsertObject,
    rootId?: string
  ) {
    for (const key in originInsertGeos.lines) {
      copyInsertGeos.lines[key] = { ...originInsertGeos.lines[key] };
      copyInsertGeos.lines[key].lines = originInsertGeos.lines[key].lines.map(
        (item: any) => {
          let cpItem = new DbLine({
            id: item.id,
            blockId: item.blockId,
            indexes: item.indexes,
            layerId: item.layerId,
            points: new Float32Array([...item.points]),
            root_block_id: item.root_block_id,
          });
          cpItem.sphere = {
            ...item.sphere,
            center: { ...item.sphere.center },
          };
          cpItem.block_path = item.block_path;
          cpItem.box = {
            ...item.box,
            max: { ...item.box.max },
            min: { ...item.box.min },
          };
          rootId
            ? (cpItem.root_reference_id = rootId)
            : (cpItem.root_reference_id = item.root_reference_id);
          cpItem.type = item.type;
          return cpItem;
        }
      );
    }
    for (const key in originInsertGeos.meshs) {
      copyInsertGeos.meshs[key] = { ...originInsertGeos.meshs[key] };
      copyInsertGeos.meshs[key].meshs = originInsertGeos.meshs[key].meshs.map(
        (item: any) => {
          let cpItem = new DbMesh({
            id: item.id,
            blockId: item.blockId,
            layerId: item.layerId,
            indexes: item.indexes,
            vertexs: new Float32Array([...item.vertexs]),
            root_block_id: item.root_block_id,
          });

          cpItem.sphere = {
            ...item.sphere,
            center: { ...item.sphere.center },
          };
          cpItem.block_path = item.block_path;
          cpItem.box = {
            ...item.box,
            max: { ...item.box.max },
            min: { ...item.box.min },
          };
          rootId
            ? (cpItem.root_reference_id = rootId)
            : (cpItem.root_reference_id = item.root_reference_id);
          cpItem.type = item.type;
          return cpItem;
        }
      );
    }
  }

  CloseContinuousInsert() {
    this.isInsertContinuous = false;
    this.InsetFileEnd(true);
    this.m_context.Dom.style.cursor = "default";
    this.isMultipleInsert = false;
  }

  // 将插入的线和网格移动过后添加到总的数据中
  private insertLineAndMeshToModel(posistion: THREE.Vector3) {
    this.insertGroup.traverse((item) => {
      if (
        item instanceof THREE.Mesh ||
        item instanceof THREE.LineSegments ||
        item instanceof THREE.InstancedMesh
      ) {
        item.name = "insert";
      }
    });

    this.insertGroup.children.forEach((item) => {
      item.parent = this.m_context.SceneManager.ModelLayer;
      this.m_context.SceneManager.ModelLayer.add(item);
    });

    this.emptyInsertGroup();

    this.m_context.SceneManager.ModelLayer.traverse((item) => {
      if (
        item.name == "insert" &&
        (item instanceof THREE.Mesh ||
          item instanceof THREE.LineSegments ||
          item instanceof THREE.InstancedMesh)
      ) {
        let array = item.geometry.attributes.position.array;

        if (array.length > 0) {
          for (let i = 0; i < array.length; i++) {
            if ((i + 1) % 3 == 1) {
              array[i] += posistion.x;
            }
            if ((i + 1) % 3 == 2) {
              array[i] += posistion.y;
            }
          }
          item.geometry.attributes.position.needsUpdate = true;
          item.geometry.computeBoundingBox();
          item.geometry.computeBoundingSphere();
          item.name = "";
        }
      }
    });

    // 将更改过的位置给到数据中
    for (const key in this.insertGeos.lines) {
      this.insertGeos.lines[key].lines.forEach((item) => {
        if (this.insertGeos.lines[key].canEdit) {
          item.points.forEach((p, idx) => {
            if ((idx + 1) % 3 == 1) {
              item.points[idx] += posistion.x;
            }
            if ((idx + 1) % 3 == 2) {
              item.points[idx] += posistion.y;
            }
          });
          item.type = "insert";
          item.sphere!.center.x += posistion.x;
          item.sphere!.center.y += posistion.y;
        }
      });
    }
    for (const key in this.insertGeos.meshs) {
      this.insertGeos.meshs[key].meshs.forEach((item) => {
        if (this.insertGeos.meshs[key].canEdit) {
          item.vertexs?.forEach((v, idx) => {
            if ((idx + 1) % 3 == 1) {
              item.vertexs![idx] += posistion.x;
            }
            if ((idx + 1) % 3 == 2) {
              item.vertexs![idx] += posistion.y;
            }
          });
          item.type = "insert";
          item.sphere!.center.x += posistion.x;
          item.sphere!.center.y += posistion.y;
        }
      });
    }
    this.insertFileToSumData();
  }

  // 清空插入Group
  private emptyInsertGroup() {
    this.insertGroup.traverse((item) => {
      if (
        item instanceof THREE.Mesh ||
        item instanceof THREE.LineSegments ||
        item instanceof THREE.InstancedMesh
      ) {
        item.geometry.dispose();
        item.material.dispose();
      }
    });
    this.insertGroup.children.length = 0;
  }
  // 给插入的线和网格添加root_reference_id使其可以高亮
  private addRootReferenceId(
    rootId: string,
    key: string,
    object: any,
    linesOrMeshsType: string = "lines"
  ) {
    if (!this.m_context.ModelManager.Group[rootId]) {
      this.m_context.ModelManager.Group[rootId] = {
        key,
        lines: [],
        mesh: [],
      };
    }
    switch (linesOrMeshsType) {
      case "lines":
        this.m_context.ModelManager.Group[rootId].lines.push(...object.lines);
        break;
      case "meshs":
        this.m_context.ModelManager.Group[rootId].mesh.push(...object.meshs);
        break;
    }
  }

  // 将插入的文件数据导入到总的数据中
  private insertFileToSumData() {
    this.linesModelsAll = this.m_context.ModelManager.Lines;
    this.meshsModelsAll = this.m_context.ModelManager.Meshs;
    let linesModelsKeys = Object.keys(this.linesModelsAll);
    let insertGeosLinesKeys = Object.keys(this.insertGeos.lines);
    let meshsModelsKeys = Object.keys(this.meshsModelsAll);
    let insertGeosMeshsKeys = Object.keys(this.insertGeos.meshs);
    if (insertGeosLinesKeys.length) {
      insertGeosLinesKeys.forEach((key) => {
        // 如果线的总数据中没有这个插入的线的key
        if (!linesModelsKeys.includes(key)) {
          this.linesModelsAll[key] = this.insertGeos.lines[key];
        } else {
          // 如果有这个key，就把这个key的数据添加到总数据中
          this.linesModelsAll[key].lines.push(
            ...this.insertGeos.lines[key].lines
          );
          if (this.isInsertFile || this.isReplaceFile) {
            let marginGeometry = BufferGeometryUtils.mergeBufferGeometries([
              this.linesModelsAll[key].model.geometry,
              this.insertGeos.lines[key].model.geometry,
            ]);
            this.linesModelsAll[key].model.geometry = marginGeometry;
            this.m_context.SceneManager.ModelLayer.remove(
              this.insertGeos.lines[key].model
            );
          }
        }
      });
    }

    if (insertGeosMeshsKeys.length) {
      insertGeosMeshsKeys.forEach((key) => {
        // 如果网格的总数据中没有这个插入的网格的key
        if (!meshsModelsKeys.includes(key)) {
          this.meshsModelsAll[key] = this.insertGeos.meshs[key];
        } else {
          // 如果有这个key，就把这个key的数据添加到总数据中
          this.meshsModelsAll[key].meshs.push(
            ...this.insertGeos.meshs[key].meshs
          );
          if (this.isInsertFile || this.isReplaceFile) {
            let marginGeometry = BufferGeometryUtils.mergeBufferGeometries([
              this.meshsModelsAll[key].model.geometry,
              this.insertGeos.meshs[key].model.geometry,
            ]);
            this.meshsModelsAll[key].model.geometry = marginGeometry;
            this.m_context.SceneManager.ModelLayer.remove(
              this.insertGeos.meshs[key].model
            );
          }
        }
      });
    }
    let rootId = generateUUID();
    let rootIdLines: any = [];
    for (const key in this.insertGeos.lines) {
      this.insertGeos.lines[key].lines.forEach((item) => {
        if (this.insertGeos.lines[key].canEdit) {
          if (this.m_context.ModelManager.Group[item.root_reference_id!]) {
            rootIdLines.push(item.root_reference_id);
          }
          item.root_reference_id = rootId;
        }
      });
      this.addRootReferenceId(rootId, key, this.insertGeos.lines[key], "lines");
    }

    rootIdLines = Array.from(new Set(rootIdLines));
    rootIdLines.forEach((itm: any) => {
      this.m_context.ModelManager.Group[itm].lines.splice(
        this.m_context.ModelManager.Group[itm].lines.length / 2
      );
    });

    let rootIdMeshs: any = [];

    for (const key in this.insertGeos.meshs) {
      this.insertGeos.meshs[key].meshs.forEach((item) => {
        if (this.insertGeos.meshs[key].canEdit) {
          if (this.m_context.ModelManager.Group[item.root_reference_id!]) {
            rootIdMeshs.push(item.root_reference_id);
          }
          item.root_reference_id = rootId;
        }
      });
      this.addRootReferenceId(rootId, key, this.insertGeos.meshs[key], "meshs");
    }
    rootIdMeshs = Array.from(new Set(rootIdMeshs));
    rootIdMeshs.forEach((itm: any) => {
      this.m_context.ModelManager.Group[itm].mesh.splice(
        this.m_context.ModelManager.Group[itm].mesh.length / 2
      );
    });
  }

  private isReplaceFile: boolean = false;
  private replaceRootId: string = "0";
  private replaceFile: any;
  // 添加文件替换所选中的
  ReplaceFile(file: any, replaceRootId: string, scale: number = 1) {
    if (this.selectionDataArr.length > 1) {
      throw new Error("只能选择一个文件进行替换");
    }
    if (!this.selectionData.entity) {
      this.StartMoveSelect((selection) => {
        return false;
      });
    }
    this.replaceRootId = replaceRootId;
    this.replaceFile = file;
    this.insertFileScale = scale;
    this.m_context.Dom.style.cursor = "crosshair";
    this.isReplaceFile = true;
    this.m_context.ModelManager.isInsert = true;
  }
  // 替换的文件加载完成后

  replaceObjects: any = {
    lines: [],
    meshs: [],
  };

  private ReplaceFileEnd() {
    if (!this.selectionData.entity || !this.isReplaceFile) return;
    this.filterSelectionData();
    this.insertGroup = this.m_context.SceneManager.ModelLayer.getObjectByName(
      "insertGroup"
    ) as THREE.Group;
    this.insertGroup!.visible = true;
    this.insertGeoSetScale();
    this.insertDataSetScale();
    let instances = this.m_context.ModelManager.Instances;
    let posistion = new THREE.Vector3();
    for (const key in instances) {
      instances[key].forEach((item: any, index: number) => {
        if (item.id == this.selectionData.entity.root_reference_id) {
          this.insertGroup.traverse((itm) => {
            if (
              itm instanceof THREE.Mesh ||
              itm instanceof THREE.LineSegments ||
              itm instanceof THREE.InstancedMesh
            ) {
              itm.name = "replace";
            }
          });

          this.insertGroup.children.forEach((itm) => {
            itm.parent = this.m_context.SceneManager.ModelLayer;
            this.m_context.SceneManager.ModelLayer.add(itm);
          });
          this.emptyInsertGroup();
          this.m_context.SceneManager.ModelLayer.traverse((itm) => {
            if (
              itm.name == "replace" &&
              (itm instanceof THREE.Mesh ||
                itm instanceof THREE.LineSegments ||
                itm instanceof THREE.InstancedMesh)
            ) {
              let pos = new THREE.Vector3(
                item.matrix.elements[12],
                item.matrix.elements[13],
                item.matrix.elements[14]
              );
              posistion.copy(pos);
              let array = itm.geometry.attributes.position.array;
              if (array.length > 0) {
                for (let i = 0; i < array.length; i++) {
                  if ((i + 1) % 3 == 1) {
                    array[i] += posistion.x;
                  }
                  if ((i + 1) % 3 == 2) {
                    array[i] += posistion.y;
                  }
                }
              }
              itm.geometry.setAttribute(
                "position",
                new THREE.BufferAttribute(
                  new Float32Array(itm.geometry.attributes.position.array),
                  3
                )
              );
              itm.geometry.attributes.position.needsUpdate = true;

              itm.name = "";
              itm.geometry.computeBoundingBox();
              itm.geometry.computeBoundingSphere();
            }
          });

          this.replaceModel(posistion);
        }
      });
    }
    this.isReplaceFile = this.m_context.ModelManager.isInsert = false;
    this.m_context.Dom.style.cursor = "default";
    this.selectionData.entity = undefined;
  }

  private replaceModel(posistion: THREE.Vector3) {
    this.clearSelection();
    if (!this.isReplaceFile) {
      this.selectionData.entity = undefined;
    }
    this.CancelPick();
    this.insertGeos = this.m_context.ModelManager.insertGeos;

    // 将更改过的位置给到数据中
    for (const key in this.insertGeos.lines) {
      this.insertGeos.lines[key].lines.forEach((item) => {
        if (this.insertGeos.lines[key].canEdit) {
          item.points.forEach((p, idx) => {
            if ((idx + 1) % 3 == 1) {
              item.points[idx] += posistion.x;
            }
            if ((idx + 1) % 3 == 2) {
              item.points[idx] += posistion.y;
            }
          });
          item.type = "replace";
          // item.root_reference_id=this.replaceRootId;
          item.sphere!.center.x += posistion.x;
          item.sphere!.center.y += posistion.y;
        }
      });
    }
    for (const key in this.insertGeos.meshs) {
      this.insertGeos.meshs[key].meshs.forEach((item) => {
        if (this.insertGeos.meshs[key].canEdit) {
          item.vertexs?.forEach((v, idx) => {
            if ((idx + 1) % 3 == 1) {
              item.vertexs![idx] += posistion.x;
            }
            if ((idx + 1) % 3 == 2) {
              item.vertexs![idx] += posistion.y;
            }
          });
          item.type = "replace";
          item.sphere!.center.x += posistion.x;
          item.sphere!.center.y += posistion.y;
        }
      });
    }

    this.insertFileToSumData();
    this.insertGeos.lines = {};
    this.insertGeos.meshs = {};
    this.insertGroup!.visible = false;
  }

  // 旋转
  private rotateStart: boolean = false;

  RotateSelect() {
    this.filterSelectionData();
    this.CancelPick();
    if (!this.moverDbMesh.length && !this.moveDbLines.length) return;
    this.startEditSelect = false;
    this.rotateStart = true;
    this.m_context.Dom.style.cursor = "crosshair";
  }

  private EndRotate() {
    this.moveLineAuxPointDownCount = 0;
    this.rotateStart = false;
    if (this.moveLineAux) {
      this.moveLineAux?.geometry.dispose();
      let auxMaterial = this.moveLineAux!.material as THREE.Material;
      auxMaterial.dispose();
      this.m_context.SceneManager.ModelLayer.remove(this.moveLineAux!);
      this.moveLineAux = undefined;
    }
    this.m_context.Dom.style.cursor = "default";
    this.moveLineSelect.forEach((itm, index) => {
      if (itm.name == "activeLine") {
        let activeMaterial = itm.material as THREE.Material;
        activeMaterial.dispose();
        itm.geometry.dispose();
        this.m_context.SceneManager.ModelLayer.remove(itm);
      }
      if (itm instanceof LineSegments || itm.name == "moveLine") {
        if (itm.userData.root_reference_id) {
          if (Array.isArray(this.selectData)) {
            for (let i = 0; i < this.selectData.length; i++) {
              let entity = this.selectData[i].entity as DbLine | DbMesh;
              let selectDataStr =
                entity.root_reference_id + entity.id + entity.block_path;
              let itmStr =
                itm.userData.root_reference_id +
                itm.userData.id +
                itm.userData.block_path;

              if (selectDataStr == itmStr) {
                let insertPos = this.instancePosition(
                  itm.userData.root_reference_id
                );
                let x =
                  itm.geometry.boundingSphere!.center.x -
                  entity.sphere!.center.x;
                let y =
                  itm.geometry.boundingSphere!.center.y -
                  entity.sphere!.center.y;
                let offset = new THREE.Vector3(x, y, 0);
                let pos = new THREE.Vector3();
                this.selectData[i].offset = {
                  x: offset.x,
                  y: offset.y,
                  z: 0,
                };
                pos.addVectors(insertPos, offset);
                this.m_context.ModelManager.setInstances(
                  itm.userData.root_reference_id.toString()!,
                  pos
                );
              }
            }
          } else {
            let entity = this.selectData.entity as DbLine | DbMesh;
            let selectDataStr =
              entity.root_reference_id + entity.id + entity.block_path;
            let itmStr =
              itm.userData.root_reference_id +
              itm.userData.id +
              itm.userData.block_path;
            if (selectDataStr == itmStr) {
              let insertPos = this.instancePosition(
                itm.userData.root_reference_id
              );
              let x =
                itm.geometry.boundingSphere!.center.x - entity.sphere!.center.x;
              let y =
                itm.geometry.boundingSphere!.center.y - entity.sphere!.center.y;
              let offset = new THREE.Vector3(x, y, 0);
              let pos = new THREE.Vector3();
              this.selectData.offset = {
                x: offset.x,
                y: offset.y,
                z: 0,
              };
              pos.addVectors(insertPos, offset);
              this.m_context.ModelManager.setInstances(
                itm.userData.root_reference_id.toString()!,
                pos
              );
            }
          }
        }

        this.moveDbLines[(index + 1) / 2 - 1].line.points = itm.geometry
          .attributes.position.array as Float32Array;
        this.moveDbLines[(index + 1) / 2 - 1].line.sphere.center = {
          x: itm.geometry.boundingSphere!.center.x,
          y: itm.geometry.boundingSphere!.center.y,
          z: itm.geometry.boundingSphere!.center.z,
        };
        this.moveDbLines[(index + 1) / 2 - 1].line.sphere.radius =
          itm.geometry.boundingSphere!.radius;
        let moveLineMaterial = itm.material as THREE.Material;
        moveLineMaterial.dispose();
        itm.geometry.dispose();
        this.m_context.SceneManager.ModelLayer.remove(itm);
      }
    });

    this.moveMeshSelect.forEach((mesh, index) => {
      if (mesh instanceof THREE.Mesh) {
        if (mesh.userData.root_reference_id) {
          if (Array.isArray(this.selectData)) {
            for (let i = 0; i < this.selectData.length; i++) {
              let entity = this.selectData[i].entity as DbLine | DbMesh;
              let selectDataStr =
                entity.root_reference_id + entity.id + entity.block_path;
              let itmStr =
                mesh.userData.root_reference_id +
                mesh.userData.id +
                mesh.userData.block_path;

              if (selectDataStr == itmStr) {
                let insertPos = this.instancePosition(
                  mesh.userData.root_reference_id
                );
                let x =
                  mesh.geometry.boundingSphere!.center.x -
                  entity.sphere!.center.x;
                let y =
                  mesh.geometry.boundingSphere!.center.y -
                  entity.sphere!.center.y;
                let offset = new THREE.Vector3(x, y, 0);
                let pos = new THREE.Vector3();
                this.selectData[i].offset = {
                  x: offset.x,
                  y: offset.y,
                  z: 0,
                };
                pos.addVectors(insertPos, offset);
                this.m_context.ModelManager.setInstances(
                  mesh.userData.root_reference_id.toString()!,
                  pos
                );
              }
            }
          } else {
            let entity = this.selectData.entity as DbLine | DbMesh;
            let selectDataStr =
              entity.root_reference_id + entity.id + entity.block_path;
            let itmStr =
              mesh.userData.root_reference_id +
              mesh.userData.id +
              mesh.userData.block_path;
            if (selectDataStr == itmStr) {
              let insertPos = this.instancePosition(
                mesh.userData.root_reference_id
              );

              let x =
                mesh.geometry.boundingSphere!.center.x -
                entity.sphere!.center.x;
              let y =
                mesh.geometry.boundingSphere!.center.y -
                entity.sphere!.center.y;
              let offset = new THREE.Vector3(x, y, 0);
              let pos = new THREE.Vector3();
              this.selectData.offset = {
                x: offset.x,
                y: offset.y,
                z: 0,
              };
              pos.addVectors(insertPos, offset);
              this.m_context.ModelManager.setInstances(
                mesh.userData.root_reference_id.toString()!,
                pos
              );
            }
          }
        }

        this.moverDbMesh[index].mesh.vertexs = mesh.geometry.attributes.position
          .array as Float32Array;
        this.moverDbMesh[index].mesh.sphere.center = {
          x: mesh.geometry.boundingSphere!.center.x,
          y: mesh.geometry.boundingSphere!.center.y,
          z: 0,
        };
        this.moverDbMesh[index].mesh.sphere.radius =
          mesh.geometry.boundingSphere!.radius;

        let moveMeshMaterial = mesh.material as THREE.Material;
        moveMeshMaterial.dispose();
        mesh.geometry.dispose();
        this.m_context.SceneManager.ModelLayer.remove(mesh);
      }
    });
    this.selcetCallBack(this.selectData);
    this.moveLineAndMeshToModel();
    this.moveDbLines.length = 0;
    this.moveLineSelect.length = 0;
    this.moverDbMesh.length = 0;
    this.moveMeshSelect.length = 0;
    this.selectionDataArr.length = 0;
    this.selectionData.entity = undefined;
  }

  // 清除上次的状态
  public ResetState(state: string) {
    this.ClearSelected();
    this.CancelPick();
    this.CloseState(state);
    switch (state) {
      case "move":
        this.copyStart = false;
        this.isInsertFile = false;
        this.isReplaceFile = false;
        this.rotateStart = false;
        break;
      case "delate":
        this.copyStart = false;
        this.moveStart = false;
        this.isInsertFile = false;
        this.isReplaceFile = false;
        this.rotateStart = false;
        break;
      case "copy":
        this.moveStart = false;
        this.isInsertFile = false;
        this.isReplaceFile = false;
        this.rotateStart = false;
        break;
      case "insert":
        this.copyStart = false;
        this.moveStart = false;
        this.isReplaceFile = false;
        this.rotateStart = false;
        break;
      case "replace":
        this.moveStart = false;
        this.isInsertFile = false;
        this.copyStart = false;
        this.rotateStart = false;
        break;
      case "rotate":
        this.moveStart = false;
        this.isInsertFile = false;
        this.copyStart = false;
        this.isReplaceFile = false;
        break;
    }
    this.startEditSelect = false;
    this.m_context.ViewManager.Render();
  }

  private CloseState(state: string) {
    switch (state) {
      case "move":
        if (this.isInsertFile) {
          this.CloseContinuousInsert();
        }
        if (this.copyStart) {
          this.clearSelection();
        }
        if (this.isReplaceFile) {
          this.emptyInsertGroup();
        }
        if (this.rotateStart) {
          this.EndRotate();
        }
        break;
      case "delate":
        if (this.isInsertFile) {
          this.CloseContinuousInsert();
        }
        if (this.isReplaceFile) {
          this.emptyInsertGroup();
        }
        if (this.copyStart) {
          this.clearSelection();
        }
        if (this.moveStart && !this.copyStart) {
          this.EndMove();
        }
        if (this.rotateStart) {
          this.EndRotate();
        }
        break;
      case "copy":
        if (this.isInsertFile) {
          this.CloseContinuousInsert();
        }
        if (this.moveStart) {
          this.EndMove();
        }
        if (this.isReplaceFile) {
          this.emptyInsertGroup();
        }
        if (this.rotateStart) {
          this.EndRotate();
        }
        break;
      case "insert":
        if (this.moveStart && !this.copyStart) {
          this.EndMove();
        }
        if (this.copyStart) {
          this.clearSelection();
        }
        if (this.isReplaceFile) {
          this.emptyInsertGroup();
        }
        if (this.rotateStart) {
          this.EndRotate();
        }
        break;
      case "replace":
        if (this.isInsertFile) {
          this.CloseContinuousInsert();
        }
        if (this.moveStart && !this.copyStart) {
          this.EndMove();
        }
        if (this.copyStart) {
          this.clearSelection();
        }
        if (this.rotateStart) {
          this.EndRotate();
        }
        break;
      case "rotate":
        if (this.isInsertFile) {
          this.CloseContinuousInsert();
        }
        if (this.moveStart && !this.copyStart) {
          this.EndMove();
        }
        if (this.copyStart) {
          this.clearSelection();
        }
        if (this.isReplaceFile) {
          this.emptyInsertGroup();
        }
        break;
    }
  }

  // 选中全部区域
  public SelectAll() {
    let selectAll: any[] = [];
    this.m_context.SceneManager.ThreeLayer.children.forEach((item) => {
      if (item instanceof THREE.Mesh && item.userData.id) {
        selectAll.push(item);
      }
    });
    this.ClearSelected();
    this.m_selection.addObjects(...selectAll);
    return selectAll.map((item) => item.userData.id);
  }

  /**
   * 结束选取操作
   */
  public CancelPick() {
    this.m_eventManager.ClearListener(Selection_Event.Pick);
    this.selectionDataArr.length = 0;
    this.m_selectionState = SelectionState.None;
    this.m_context.Dom.style.cursor = "";
    this.m_enableShift = false;
    this.multiSelect = false;
    this.m_context.ViewManager.Render();
  }

  /**
   * 框选元素
   * @param callback 框选回调，返回ture 继续拾取， false 停止拾取
   */
  public PickRectangle(
    callback: (box: THREE.Box3, entity: DBEntity[]) => boolean
  ) {
    let selectBox = (box: THREE.Box3, entity: DBEntity[]) => {
      if (!callback(box, entity)) {
        this.m_eventManager.removeEventListener(
          Selection_Event.SelectionBox,
          selectBox
        );
        this.m_selectionState = SelectionState.None;
        this.cancelSelectBox();
      }
    };
    this.m_eventManager.addEventListener(
      Selection_Event.SelectionBox,
      selectBox
    );
    this.m_selectionState = SelectionState.SelectionBox;
    this.OpenSelectBox();
  }

  /**
   *
   * @param entity //放置的对象
   * @param hoverCallback 放置移动过程中的hover回调
   * @param PlaceCallback 放置回调，返回ture 继续拾取， false 停止拾取
   */
  public PlaceEntity(
    entity: DBEntity,
    hoverCallback: (slection: Selection) => boolean,
    PlaceCallback: (slection: Selection) => boolean
  ) {}

  /**
   * 放置标记
   * @param iconPath 图片地址
   * @param sizeX 图片宽
   * @param sizeY 图片高
   * @param PlaceCallback 放置触发回调 ,返回ture继续放置
   * @param BeforeCallBack 放置前触发，返回false，跳过该次放置
   */
  public PlaceSign(
    iconPath: string,
    sizeX: number,
    sizeY: number,
    PlaceCallback: (slection: {
      place: Selection;
      next: THREE.Object3D;
    }) => boolean,
    BeforeCallBack: (location: Selection) => boolean = () => true
  ) {
    let s = ScreenCoordinateHelper.GetScale(
      this.m_context.ViewManager.Camera as THREE.PerspectiveCamera,
      this.m_context.Dom.offsetHeight
    );
    let textureLoader = new THREE.TextureLoader();
    let spriteMaterial = new THREE.SpriteMaterial({
      map: textureLoader.load(iconPath),
    });
    this.m_paceObject = new THREE.Sprite(spriteMaterial);
    this.m_paceObject.scale.set(sizeX * s, sizeY * s, 1);
    this.m_paceObject.userData.scale = { x: sizeX, y: sizeY, z: 1 };
    this.m_context.SceneManager.AuxModel.add(this.m_paceObject);
    let placeS = (selection: { place: Selection; next: THREE.Object3D }) => {
      if (!PlaceCallback(selection)) {
        this.m_eventManager.removeEventListener(Selection_Event.Place, placeS);
        this.m_selectionState = SelectionState.None;
        if (this.m_paceObject) {
          this.m_context.SceneManager.AuxModel.remove(this.m_paceObject);
          FreeThreeObject(this.m_paceObject);
        }
        this.CancelPlaceSign();
      }
      if (this.m_paceObject) {
        let s = ScreenCoordinateHelper.GetScale(
          this.m_context.ViewManager.Camera as THREE.PerspectiveCamera,
          this.m_context.Dom.offsetHeight
        );
        let sacle1 = this.m_paceObject.userData.scale;
        this.m_paceObject?.scale.set(sacle1.x * s, sacle1.y * s, 1);

        let sacle2 = (selection.place.entity as THREE.Object3D).userData.scale;
        (selection.place.entity as THREE.Object3D).scale.set(
          sacle2.x * s,
          sacle2.y * s,
          1
        );
      }
    };
    this.m_eventManager.addEventListener(Selection_Event.Place, placeS);
    this.m_selectionState = SelectionState.Place;

    this.PlaceBeforeCallBack = BeforeCallBack;
  }

  /**
   * 画线并返回线的顶点
   * */
  DrawLine(callback: (points: THREE.Vector3[]) => boolean) {
    this.stopDrawLine();
    this.clearPointLine();
    this.drawlineEve = (points: THREE.Vector3[]) => {
      if (!callback(points)) {
        this.m_eventManager.removeEventListener(
          Selection_Event.drawline,
          this.drawlineEve
        );
        this.m_selectionState = SelectionState.None;
      }
    };
    this.m_eventManager.addEventListener(
      Selection_Event.drawline,
      this.drawlineEve
    );
    this.m_selectionState = SelectionState.drawline;
  }

  public get PlaceObject() {
    return this.m_paceObject;
  }
  /**
   * 结束放置
   */
  public CancelPlaceSign() {
    this.m_eventManager.ClearListener(Selection_Event.Place);
    if (this.m_paceObject)
      this.m_context.SceneManager.AuxModel.remove(this.m_paceObject);
    this.m_selectionState = SelectionState.None;
  }

  public ClearSelected(): void {
    this.m_selection.clear();
  }

  public GetSelected(): (DBEntity | THREE.Object3D)[] {
    return this.m_selection.getObjects();
  }

  public RemoveSelect(entity: DBEntity | THREE.Object3D) {
    this.m_selection.clearObj(entity);
  }

  public GetPointByScreenXY(x: number, y: number) {
    const x1 = (x / this.m_context.Dom.clientWidth) * 2 - 1;
    const y1 = -(y / this.m_context.Dom.clientHeight) * 2 + 1;
    this.m_raycaster.setFromCamera(
      new THREE.Vector2(x1, y1),
      this.m_context.ViewManager.Camera
    );
    let target = new THREE.Vector3();
    this.m_raycaster.ray.intersectPlane(
      this.m_context.ViewManager.m_controls.m_plane,
      target
    );
    return target;
  }

  /**
   *
   * @param entity 设置选择项，并且高亮
   */
  public SetSelected(...entity: (DBEntity | THREE.Object3D)[]) {
    this.m_selection.addObjects(...entity);
    this.m_context.ViewManager.Render();
  }

  public EnableSnap(enable: boolean) {
    this.m_enableSnap = enable;
  }

  // 测量功能
  /**
   * @param isStarted 是否开始测量
   * @param unit 单位
   * @param precision 精度
   * @param isAuxLabel 是否显示辅助标签
   *
   *
   */
  public MeasureDistance(
    isStarted: boolean,
    unit: string = "m",
    precision: number = 0,
    isAuxLabelVisible: boolean = true,
    callback?: (distanceMsg: object) => void
  ) {
    this.m_measurement.setUnit(unit);
    this.m_measurement.setPrecision(precision);
    callback && this.m_measurement.setCallback(callback);
    this.m_measurement.setAuxLabelVisible(isAuxLabelVisible);
    switch (isStarted) {
      case true:
        this.m_measurement.start();
        this.EnableSnap(true);
        break;
      case false:
        this.m_measurement.stop();
        this.EnableSnap(false);
        break;
    }
  }
  ClearMeasure() {
    this.m_measurement.clearAllMeasure();
  }
}

export { SelectionManager, Selection_Event };
