import * as THREE from "three";
import { BufferAttribute, BufferGeometry, ViewManager } from "../CadEngine";
import { BaseDimemsion } from "./BaseDimension";

export class OrthogonalAux extends BaseDimemsion{

    private m_line :THREE.Line|undefined;

    constructor(view:ViewManager, root:THREE.Object3D)
    {
        super(view, root);
    }

    public set visible(visible:boolean)
    {
        if(this.m_line)
            this.m_line.visible = visible;
    }

    public Set(origin:THREE.Vector3, dir:THREE.Vector3, scale:number, end:THREE.Vector3|undefined = undefined)
    {
        this.visible = true;
        let path:THREE.Vector3[] = []
        if(end)
        {
            path.push(origin, end)
        }
        else
        {
          //  alert('ffffffffffffffffffff');
            let length = Math.sqrt(this.m_view.Canvas.offsetWidth * this.m_view.Canvas.offsetWidth + this.m_view.Canvas.offsetHeight * this.m_view.Canvas.offsetHeight);
            path.push(origin.clone().add(dir.clone().multiplyScalar(length * scale)), origin.clone().sub(dir.clone().multiplyScalar(length *scale)))
        }
        if(this.m_line)
        {
            this.m_line.geometry.setFromPoints(path);
            this.m_line.computeLineDistances();
            (this.m_line?.material as THREE.LineDashedMaterial).scale = 1 / scale;
        }
        else{
            let geo = new BufferGeometry();
            geo.setFromPoints(path);
            this.m_line = new THREE.Line(geo, new THREE.LineDashedMaterial({color:'green', dashSize:1, gapSize:2, scale:1/scale}))
            this.m_line.computeLineDistances();
            this.m_line.frustumCulled =false;
            this.m_root.add(this.m_line);
        }
    }

    public dispose(): void {
        if(this.m_line)
        {
            this.m_line.geometry.dispose();
            this.m_root.remove(this.m_line);
        }
    }



} 