import * as THREE from "three";
import { ViewManager } from "../CadEngine";

class BaseDimemsion{
    protected m_root: THREE.Object3D;
    protected m_view: ViewManager;
    constructor(view:ViewManager, root:THREE.Object3D)
    {
        this.m_root = root;
        this.m_view = view;
    }

    public dispose()
    {
        
    }
}

export {BaseDimemsion}