import * as THREE from "three";
import { BufferGeometry } from "three";
import { ViewManager } from "../CadEngine";
import { UInput } from "../ui/UInput";
import { ULabel } from "../ui/ULabel";
import { BPMathUtils } from "../utils/BPMathUtils";
import { BaseDimemsion } from "./BaseDimension";

class ArcDimension extends BaseDimemsion{
    private m_dimension :THREE.LineSegments|undefined;
    private m_input: ULabel;

    constructor(view:ViewManager, root:THREE.Object3D)
    {
        super(view, root)
        this.m_input = new ULabel(view, view.m_context.Dom);
        this.m_input.Visibility = false;
        this.m_root = root;
    }
    
    public SetVaule(inputVuale: string) {
        this.m_input.SetVaule(inputVuale);
        this.m_input.Visibility = true;
    }

    public SetDimension(position:THREE.Vector3, v1:THREE.Vector3, v2:THREE.Vector3, scale:number){
        let angle = v1.angleTo(v2);
        let crossAngle = BPMathUtils.angleTo(v1, v2, new THREE.Vector3(0,0,1));
        let positions:number[] = [0,0,0]
        let arc= new THREE.ArcCurve(0,0,50 * scale,0,crossAngle!=angle ?  Math.PI * 2 - angle:angle, crossAngle!=angle);
        arc.getPoints(crossAngle * 10).forEach(x=>{
            positions.push(x.x, x.y, 0);
        })
        // positions.push(Math.cos(crossAngle) * 50 * scale, Math.sin(crossAngle) * 50  * scale, 0);
        if(!this.m_dimension)
        {
            this.m_dimension = new THREE.LineSegments(new BufferGeometry(), new THREE.LineDashedMaterial({color:'green', dashSize:2, gapSize:2, scale:1}));
            this.m_dimension.frustumCulled = false;
            this.m_root.add(this.m_dimension);
        }

        let rad = angle * 180 / Math.PI;
        if(rad == 90)
        {

        }
        if(rad == 180 || rad == 0)
        {

        }
        let indexes:number[] = [];
        for (let index = 0; index < positions.length; index++) {
            indexes.push(index);
            if(index!=0 && index!=positions.length - 1)
            {
                indexes.push(index);
            }
        }
        this.m_dimension.geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(positions),3));
        this.m_dimension.geometry.setIndex(indexes)
        this.m_dimension.position.copy(position);
        let labelAngle = crossAngle > Math.PI ? - angle : angle;
        labelAngle = labelAngle * 0.5;
        let labelLocation = new THREE.Vector3(Math.cos(labelAngle) * 50 * scale + position.x, Math.sin(labelAngle)* 50 * scale + position.y, 0)
        this.m_input.SetVaule(`${Math.floor(rad)}°`,labelLocation, new THREE.Vector3(1,0,0) );
    }

    public dispose(): void {
        if(this.m_dimension)
        {
            this.m_root.remove(this.m_dimension);
            this.m_dimension.geometry.dispose();
        }
        if(this.m_input)
        {
            this.m_input.dispose();
        }
    }

}
export{ArcDimension}