import { BPLinear } from "./BPLinear";
import { DBEntity } from "./DBEntity";
/**
 * 相交元素的逻辑抽象
 */
  class IndserObject{
    location:{x:number,y:number,z:number};
    adjust:{x:number,y:number,z:number}|undefined;
    distance:number;
    code:string;
    position:IndsertPosition; 
    obejct:DBEntity[] | THREE.Object3D[];
    geo:THREE.Line3|BPLinear | undefined;

    constructor(location:{x:number,y:number,z:number}, distance :number,code:string , obejct:DBEntity[] | THREE.Object3D[])
    {
        this.location = location;
        this.distance = distance;
        this.code = code;
        this.obejct = obejct;
        this.position = IndsertPosition.None;
    }

}
/**
 * 相交点位置
 */
enum IndsertPosition{
    /**
     * 起点
     */
    Start='start',
    /**
     * 终点
     */
    End='end',
    /**
     * 中心点
     */
    Center='center',
    /**
     * 绘制线于现有线相交
     */
    Insert='insert',
    /**
     * 相交
     */
    Cross='cross',
    /**
     * 垂直
     */
    Vertical='vertical',
    /**
     * 辅助点正交
     */
    AuxOrthogonal='auxOrthogonal',
    /**
     * 辅助点垂直或平行
     */
    AuxVertical='auxVertical',
    /**
     * 啥也不是
     */
    None='none'
}

export {IndserObject, IndsertPosition}