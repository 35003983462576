/**
 * 模型数据的抽象实体，所有对外的模型数据交互，均通过该对象，或者该对象的子类
 */
 import {stringUtils} from "../utils/StringUtils";
 import {Md5} from "ts-md5"
export class DBEntity {
  type: string; //对象类型
  id: string; //对象id
  layerId: string; //所属层id
  blockId: string; //所属块id
  parameters: { [key: string]: any } | undefined; //参数
  tag: any | undefined;
  unions: DBEntity[] | undefined;
  model: THREE.Object3D | undefined;
  points: Float32Array | undefined;
  root_reference_id?: string = "";
  block_path?: string = "";
  root_block_id?: string=''
  // geometry: any|undefined;//几何体F
  //material: THREE.MeshBasicMaterial;//材质

  constructor(params: {
    id: string;
    layerId: string;
    blockId: string;
    root_reference_id?: string;
    block_path?: Uint8Array[];
    root_block_id?:string
  }) {
    this.type = "";
    this.id = params.id;
    this.blockId = params.blockId;
    this.layerId = params.layerId;
    this.root_reference_id = params.root_reference_id;
    params.block_path?.length&&params.block_path.forEach((item,index)=>{
      this.block_path += stringUtils.BytesToString(item);
    })
    this.root_block_id=params.root_block_id
    const md5:any = new Md5
    this.block_path = this.block_path?.replace(/,/g, "");
    md5.appendAsciiStr( this.block_path)
    this.block_path=md5.end()

  }
}
