import * as THREE from "three";
import { EventManager } from "../manager/EventManager";

export class SelectionHelper{
    element: HTMLDivElement;
    startPoint: any;
    pointTopLeft: any;
    pointBottomRight: any;
    isDown: boolean;
    eventManager: EventManager;
    parentElement: HTMLElement;
	onPointerDownBind: (event: any) => void;
	onPointerMoveBind: (event: any) => void;
	onPointerUpBind: (event: any) => void;
    buttonNum:number=0

    constructor( eventManager:EventManager, parentElement:HTMLElement,cssClassName:string ) {
        this.parentElement = parentElement;
		this.element = document.createElement( 'div' );
		this.element.classList.add( cssClassName );
		this.element.style.pointerEvents = 'none';
        this.eventManager = eventManager;
		this.startPoint = new THREE.Vector2();
		this.pointTopLeft = new THREE.Vector2();
		this.pointBottomRight = new THREE.Vector2();
		this.isDown = false;
		this.onPointerDownBind = this.onPointerDown.bind(this);
		this.onPointerMoveBind = this.onPointerMove.bind(this);
		this.onPointerUpBind = this.onPointerUp.bind(this);

		this.eventManager.addEventListener( 'pointerdown', this.onPointerDownBind );
		this.eventManager.addEventListener( 'pointermove', this.onPointerMoveBind);
		this.eventManager.addEventListener( 'pointerup', this.onPointerUpBind);

	}
    onPointerMove(event: any) {
		if(this.buttonNum!=0){
			return
		}
        if ( this.isDown ) {//&& event.button==0

            this.onSelectMove( event );

        }
    }

    onPointerDown(event:any)
    {
		// 
		this.buttonNum=event.button
        this.isDown = true;
        this.onSelectStart( event );
    }
    onPointerUp(event:any)
    {

        this.isDown = false;
        this.onSelectOver();
    }
    dispose() {

		this.eventManager.removeEventListener( 'pointerdown', this.onPointerDownBind );
		this.eventManager.removeEventListener( 'pointermove', this.onPointerMoveBind );
		this.eventManager.removeEventListener( 'pointerup', this.onPointerUpBind );
		this.onSelectOver();
	}

	onSelectStart( event:any ) {

		this.element.style.display = 'none';
		this.parentElement.appendChild( this.element );
		this.element.style.left = event.clientX + 'px';
		this.element.style.top = event.clientY + 'px';
		this.element.style.width = '0px';
		this.element.style.height = '0px';
		this.startPoint.x = event.clientX;
		this.startPoint.y = event.clientY;
	}

	onSelectMove( event:any ) {

		this.element.style.display = 'block';

		this.pointBottomRight.x = Math.max( this.startPoint.x, event.clientX );
		this.pointBottomRight.y = Math.max( this.startPoint.y, event.clientY );
		this.pointTopLeft.x = Math.min( this.startPoint.x, event.clientX );
		this.pointTopLeft.y = Math.min( this.startPoint.y, event.clientY );

		this.element.style.left = this.pointTopLeft.x + 'px';
		this.element.style.top = this.pointTopLeft.y + 'px';
		this.element.style.width = ( this.pointBottomRight.x - this.pointTopLeft.x ) + 'px';
		this.element.style.height = ( this.pointBottomRight.y - this.pointTopLeft.y ) + 'px';

	}

	onSelectOver() {
		this.parentElement.removeChild( this.element );
	}
}