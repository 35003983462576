import * as THREE from "three";
import {
  Box3Helper,
  BoxHelper,
  BufferGeometry,
  Line,
  LineSegments,
  Mesh,
  Sprite,
} from "three";
import { Context } from "./context";
import { DbBlockInstance } from "./DbBlockInstance";
import { DBEntity } from "./DBEntity";
import { DbLine } from "./DbLine";
import { DbMesh } from "./DbMesh";
import { IndserObject } from "./IndserObject";

/**
 * 拾取，选择管理数据集合
 */
export class SelectionData extends THREE.Object3D {
  m_hightColor: THREE.Color = new THREE.Color(0x4addec); //0x4ADDEC
  // m_hightColor2: THREE.Color = new THREE.Color(0x4ADDEC);//0x4ADDEC
  public m_hightMaterial: THREE.LineBasicMaterial;
  public m_hightMeshMaterial: THREE.MeshBasicMaterial;
  private m_meshs: DbMesh[] = [];
  private m_lines: DbLine[] = [];
  private m_instances: DbBlockInstance[] = [];
  public m_threeObjects: THREE.Object3D[] = [];
  m_context: Context;

  constructor(
    context: Context,
    color: THREE.Color = new THREE.Color(0x43c7d4)
  ) {
    super();
    this.m_hightColor = color;
    this.m_hightMaterial = new THREE.LineBasicMaterial({
      color: this.m_hightColor,
      transparent: true,
    });
    this.m_hightMeshMaterial = new THREE.MeshBasicMaterial({
      color: this.m_hightColor,
      side: THREE.DoubleSide,
      transparent: true,
    });
    this.m_context = context;
    this.m_context.SceneManager.AuxModel.add(this);
  }

  public setColor(color: number, opacity: number) {
    this.m_hightMaterial.color = new THREE.Color(color);
    this.m_hightMeshMaterial.color = new THREE.Color(color);
    this.m_hightMaterial.opacity = opacity;
    this.m_hightMeshMaterial.opacity = opacity;
  }

  public addObjects(...entities: (DBEntity | THREE.Object3D)[]) {
    let temp: { [key: string]: number } = {};
    entities.forEach((entity) => {
      let result = this.addObject(entity);
      if (result && result.root_reference_id) {
        if (result instanceof DbLine)
          temp[result.root_reference_id] =
            temp[result.root_reference_id] | 0x01;
        else
          temp[result.root_reference_id] =
            temp[result.root_reference_id] | 0x10;
      }
    });
    for (let item of Object.entries(temp)) {
      let value = this.m_context.ModelManager.Group[item[0]];
      // if(item[1] & 0x01)
      {
        let lineGeo = new THREE.BufferGeometry();
        let position: number[] = [];
        let index = 0;
        let indexes: number[] = [];
        value &&
          value.lines?.forEach((item) => {
            item.points.forEach((p) => {
              position.push(p);
            });
            item.indexes?.forEach((v) => {
              indexes.push(v + index);
            });
            index += item.points.length / 3;
          });

        let positionArray = new Float32Array(position);
        lineGeo.setAttribute(
          "position",
          new THREE.BufferAttribute(positionArray, 3)
        );
        lineGeo.setIndex(indexes);
        let line = new THREE.LineSegments(lineGeo, this.m_hightMaterial);
        this.add(line);
      }
      // if(item[1] & 0x10)
      {
        let position: number[] = [];
        value &&
          value.mesh?.forEach((geo) => {
            geo.vertexs?.forEach((v) => {
              position.push(v);
            });
          });
        let geometry = new THREE.BufferGeometry();
        let vertexes = new Float32Array(position);
        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(vertexes, 3)
        );
        let mesh = new THREE.Mesh(geometry, this.m_hightMeshMaterial);
        this.add(mesh);
      }
    }
  }

  /**
   *
   * @param obj 添加拾取元素
   */
  private addObject(
    obj: DBEntity | THREE.Object3D
  ): DbLine | DbMesh | undefined {
    if (obj instanceof DbLine) {
      this.m_lines.push(obj);
      if (obj.root_reference_id) return obj;
      let geo = new THREE.BufferGeometry();
      geo.setAttribute("position", new THREE.BufferAttribute(obj.points, 3));
      geo.setIndex(new THREE.Uint32BufferAttribute(obj.indexes, 1));
      let line = new LineSegments(geo, this.m_hightMaterial);
      line.renderOrder = 10;
      line.userData.obj = obj;
      this.add(line);
    }
    if (obj instanceof DbMesh) {
      this.m_meshs.push(obj);
      if (obj.root_reference_id) return obj;
      //to-do mesh高亮无效
      let geo = new THREE.BufferGeometry();
      let arr: number[] = [];
      for (let index = 0; index < obj.vertexs.length; index++) {
        //    ;
        const element = obj.vertexs.at(index);
        if (element != undefined) arr.push(element);
      }
      geo.setAttribute("position", new THREE.Float32BufferAttribute(arr, 3));
      let mesh = new Mesh(geo, this.m_hightMeshMaterial);
      mesh.renderOrder = 10;
      mesh.position.x = 0;
      mesh.userData.obj = obj;
      this.add(mesh);
    }
    if (obj instanceof DbBlockInstance) {
      this.m_instances.push(obj);

      let block = obj.blockRecord;
      let lineGeo = new THREE.BufferGeometry();
      let posistion: number[] = [];
      let index = 0;
      let indexs: number[] = [];
      block.lines.array.forEach((item) => {
        posistion.push(...(item.points || []));
        item.indexes?.forEach((v) => {
          indexs.push(v + index);
        });
        index += item.points.length / 3;
      });
      lineGeo.setAttribute(
        "position",
        new THREE.BufferAttribute(new Float32Array(posistion), 3)
      );
      lineGeo.setIndex(indexs);
      let lineMesh = new THREE.LineSegments(lineGeo, this.m_hightMaterial);
      lineMesh.matrix.copy(obj.matrix);
      lineMesh.userData.obj = obj;
      this.add(lineMesh);

      posistion.length = 0;
      block.meshs.array.forEach((geo) => {
        geo.vertexs?.forEach((v) => {
          posistion.push(v);
        });
      });
      let geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(new Float32Array(posistion), 3)
      );
      let mesh = new Mesh(geometry, this.m_hightMeshMaterial);
      this.add(mesh);
    }
    if (obj instanceof THREE.Object3D) {
      this.m_threeObjects.push(obj);
      let geometry = new BufferGeometry();
      let mesh;
      if (obj instanceof THREE.Mesh) {
        geometry.copy((obj as THREE.Mesh).geometry);
        mesh = new Mesh(geometry, this.m_hightMeshMaterial);
        mesh.userData.obj = obj;
        this.add(mesh);
      }
      if (obj instanceof THREE.Sprite) {
        let box = new BoxHelper(obj, this.m_hightColor);
        box.userData.obj = obj;
        this.add(box);
      }
    }
  }

  /**
   *
   * @returns 获取拾取元素
   */
  public getObjects(): (DBEntity | THREE.Object3D)[] {
    let entities: (DBEntity | THREE.Object3D)[] = [];
    this.m_lines.forEach((line) => {
      entities.push(line);
    });
    this.m_meshs.forEach((mesh) => {
      entities.push(mesh);
    });
    this.m_instances.forEach((instacnce) => {
      entities.push(instacnce);
    });
    this.m_threeObjects.forEach((instacnce) => {
      entities.push(instacnce);
    });
    return entities;
  }

  /**
   *
   * @returns 清空拾取
   */
  public clear(): this {
    this.m_lines.length = 0;
    this.m_meshs.length = 0;
    this.m_threeObjects.length = 0;
    this.m_instances.length = 0;
    super.clear();
    this.children.forEach((item) => {
      let mesh = item as Mesh;
      mesh.geometry.dispose();
      let line = item as Line;
      line.geometry.dispose();
    });
    return this;
  }

  public Remove(obj: THREE.Object3D | DBEntity): boolean {
    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i].userData.obj == obj) {
        this.children.splice(i, 1);
        return true;
      }
    }
    return false;
  }
  /**
   * 按shift多选时如果把高亮的元素再点一次就取消点击的高亮元素
   * */
  public clearObj(obj: THREE.Object3D | DBEntity): boolean {
    let points!: Float32Array[] | Float32Array;
    if (obj instanceof DbLine) {
      points = obj.points;
      if (obj.root_reference_id) {
        let arrLines: any = [];
        let arrMeshs: any = [];
        this.m_context.ModelManager.Group[obj.root_reference_id].lines.forEach(
          (item) => {
            arrLines.push(...item.points);
          }
        );
        this.m_context.ModelManager.Group[obj.root_reference_id].mesh.forEach(
          (item) => {
            arrMeshs.push(...item.vertexs);
          }
        );

        points = [arrLines, arrMeshs];
      }
      let index = this.m_lines.indexOf(obj);
      if (index > -1) {
        this.m_lines.splice(index, 1);
      }
    }
    if (obj instanceof DbMesh) {
      points = obj.vertexs;
      if (obj.root_reference_id) {
        let arrLines: any = [];
        let arrMeshs: any = [];
        this.m_context.ModelManager.Group[obj.root_reference_id].lines.forEach(
          (item) => {
            arrLines.push(...item.points);
          }
        );
        this.m_context.ModelManager.Group[obj.root_reference_id].mesh.forEach(
          (item) => {
            arrMeshs.push(...item.vertexs);
          }
        );
        points = [arrLines, arrMeshs];
      }
      let index = this.m_meshs.indexOf(obj);
      if (index > -1) {
        this.m_meshs.splice(index, 1);
      }
    }
    if (obj instanceof THREE.Object3D) {
      if (this.m_threeObjects.length > 0) {
        let index: number;
        index = this.m_threeObjects.indexOf(obj);
        //@ts-ignore
        points = obj.geometry.attributes.position.array;
        if (index > -1) {
          this.m_threeObjects.splice(index, 1);
        }
      }
    }

    for (let i = 0; i < this.children.length; i++) {
      if (
        this.children[i] instanceof THREE.Mesh ||
        this.children[i] instanceof THREE.Line ||
        this.children[i] instanceof THREE.LineSegments ||
        this.children[i] instanceof THREE.Sprite
      ) {
        //@ts-ignore
        let vts =this.children[i].geometry.attributes.position.array.toString();
        //@ts-ignore
        let pts = "";
        if((obj instanceof DbLine || obj instanceof DbMesh)){
          if(obj.root_reference_id){
            if (Array.isArray(points)) {
              for (let j = 0; j < points.length; j++) {
                pts = points[j].toString();
                if (vts == pts) {
                  this.children.splice(i, 1);
                  points.splice(j, 1);
                  j--
                  i--
                  if(!points.length){
                    return true;
                  }
                }
              }
            } 
          }
          else {
            pts = points?.toString();
            if (vts == pts) {
              this.children.splice(i, 1);
                return true;
            }
          }
        }
        else {
          pts = points?.toString();
          if (vts == pts) {
            this.children.splice(i, 1);
              return true;
          }
        }
      }
    }

    return false;
  }
}
