import * as THREE from "three";
import { Box3 } from "three";
import { DBEntity } from "../core/DBEntity";
import { DbLine } from "../core/DbLine";
import { DbMesh } from "../core/DbMesh";
import { IndserObject, IndsertPosition } from "../core/IndserObject";

function AddLineSegmentsIndexs(indexs:number[],start: number, count: number, ) {
    for(let i = 0; i < count ;i++) {
        indexs.push(start);
        if((i != count - 1 && i != 0)) {
            indexs.push(start);
        }
        if(count == 1) {
            indexs.push(start);
        }
        start++;
    }
}

/**
 * 获取相交mesh
 * @param lines 
 * @param ray 
 * @param sx 
 * @param sy 
 * @param tol 
 * @param callback 
 * @returns 
 */
function  GetInsertMeshs(items:DbMesh[], ray: THREE.Ray, tol:number, callback:(param:IndserObject)=>boolean)
{
    let target = new THREE.Vector3();
    for(let item of items)
        {
            if(item.sphere && ray.intersectSphere(item.sphere,target)){
                for (let index = 0; index < item.vertexs.length; index=index+9) {
                    // 
                    let p1=new THREE.Vector3(item.vertexs[index+0],item.vertexs[index+1],item.vertexs[index+2])
                    let p2=new THREE.Vector3(item.vertexs[index+3],item.vertexs[index+4],item.vertexs[index+5])
                    let p3=new THREE.Vector3(item.vertexs[index+6],item.vertexs[index+7],item.vertexs[index+8])
                    let point3=new THREE.Vector3()
                    let intersect=ray.intersectTriangle(p1,p2,p3,true,point3)
                    if(!intersect)
                    intersect = ray.intersectTriangle(p1,p2,p3,false,point3)
                    if(intersect!=null){
                        let objs:DBEntity[] = [item];
                        let location = {x:Number(point3.x), y:Number(point3.y), z:Number(point3.z)};
                        let indsertObject = new IndserObject(location, 0, '', objs);
                        indsertObject.adjust = {x:Number(point3.x), y:Number(point3.y), z:Number(point3.z)};;
                        // if(item.unions){
                        //     objs.push(...item.unions)
                        // }
                        callback(indsertObject)
                        return;
                    }
                }
            }
        }
}
/**
 * 遍历相交所有的线
 * @param items 
 * @param ray 
 * @param tol 
 * @param callback 
 * @returns 
 */
function  GetInsertLines(items:DbLine[], ray: THREE.Ray, tol:number, callback:(param:IndserObject)=>boolean)
{
    let tolSq = tol * tol;
    let target = new THREE.Vector3();
    let targetOnSegment = new THREE.Vector3();
    let start = new THREE.Vector3();
    let end = new THREE.Vector3();
    for(let item of items)
    {
        if(item.sphere && ray.intersectSphere(item.sphere,target)){
            for (let index = 0; index < item.points.length; index=index+3) {
                start.set(item.points[index+0],item.points[index+1],item.points[index+2]);
                end.set(item.points[index+3],item.points[index+4],item.points[index+5]);
                let distance = ray.distanceSqToSegment(start, end, target,targetOnSegment);
                if(distance <= tolSq){
                    let objs:DBEntity[] = [item];
                    // if(item.unions){
                    //     objs.push(...item.unions)
                    // }
                    let location = {x:Number(target.x), y:Number(target.y), z:Number(target.z)};
                    let center = new THREE.Vector3((start.x  +end.x) * 0.5,(start.y  +end.y) * 0.5,(start.z +end.z) * 0.5);
                    let d1 = start.distanceTo(targetOnSegment);
                    let d2 = center.distanceTo(targetOnSegment);
                    let d3 = end.distanceTo(targetOnSegment);
                    let position = IndsertPosition.None;
                    let min = Math.min(d1, d2, d3);
                    let adjust = targetOnSegment.clone();
                    if(min <= tol)
                    {
                        if(min == d1)
                        {
                            position = IndsertPosition.Start;
                            adjust = start.clone();
                        }
                        if(min == d2)
                        {
                            position = IndsertPosition.Center;
                            adjust = center;
                        }
                        if(min == d3)
                        {
                            position = IndsertPosition.End;
                            adjust = end.clone();
                        }
                    }
                    let parms = new IndserObject(location, Math.sqrt(distance), '', objs)
                    parms.position = position;
                    parms.adjust = adjust;
                    parms.geo = new THREE.Line3(start.clone(), end.clone());
                    if(!callback(parms))
                        return;
                }
            }
        }
    }
}
function FreeThreeObject(object3D:THREE.Object3D){
    if(object3D instanceof THREE.Line || object3D instanceof THREE.LineSegments || object3D instanceof THREE.Mesh)
    {
        object3D.geometry.dispose();
        object3D.clear();
    }
}

function BoxToPath(min:THREE.Vector3 | THREE.Vector2, max:THREE.Vector3 | THREE.Vector2)
{
    //;
    let p1 = new THREE.Vector2(min.x-0, min.y);
    let p2 = new THREE.Vector2(max.x, min.y);
    let p3 = new THREE.Vector2(max.x, max.y);
    let p4 = new THREE.Vector2(min.x-0, max.y);
    return [p1, p2, p3, p4];
}

export {AddLineSegmentsIndexs, GetInsertLines, GetInsertMeshs, FreeThreeObject,BoxToPath}