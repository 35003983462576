import * as THREE from "three";
import { BufferGeometry } from "three";
import { ViewManager } from "../CadEngine";
import { UInput } from "../ui/UInput";
import { BaseDimemsion } from "./BaseDimension";

class LineDimension extends BaseDimemsion{
    private m_dimension :THREE.LineSegments|undefined;
    private m_input: UInput;
    constructor(view:ViewManager, root:THREE.Object3D)
    {
        super(view, root)
        this.m_input = new UInput(view, view.m_context.Dom);
        this.m_input.Visibility = false;
        this.m_root = root;
    }
    
    public SetVaule(inputVuale: string) {
        this.m_input.SetVaule(inputVuale);
        this.m_input.Visibility = true;
    }

    public SetDimension(start:THREE.Vector3, end:THREE.Vector3, scale:number){
        let dir = start.clone().sub(end).normalize();
        let angle = dir.angleTo(new THREE.Vector3(1,0,0));
        if(new THREE.Vector3(1,0,0).applyAxisAngle(new THREE.Vector3(0,0,1), angle).angleTo(dir)> 0.00001)
        {
            angle = Math.PI - angle % Math.PI;
        }
        if(angle>= Math.PI)
            angle = 0;
        let outDir = new THREE.Vector3(1,0,0).applyAxisAngle(new THREE.Vector3(0,0,1), angle + Math.PI * 0.5);
        let offset1 = outDir.clone().multiplyScalar(30 * scale);        // 短线偏移长度
        let offset2 = outDir.clone().multiplyScalar(15 * scale);        // 长线偏移长度
        let ps = [ start.clone().add(offset2), end.clone().add(offset2)];   // 数组中为长线的起始点
        ps.push(start, start.clone().add(offset1));
        ps.push(end, end.clone().add(offset1));
        if(!this.m_dimension)
        {
            this.m_dimension = new THREE.LineSegments(new BufferGeometry(), new THREE.LineBasicMaterial({color:'green'}));
            this.m_dimension.frustumCulled = false;
            this.m_root.add(this.m_dimension);
        }
        this.m_dimension.geometry.setFromPoints(ps);
        let labelLocation = start.clone().add(offset1).add(end).add(offset1).multiplyScalar(0.5);
        this.m_input.SetVaule(`${(start.distanceTo(end) * 1000).toFixed(3)}`,labelLocation, dir );
    }

    public dispose(): void {
        if(this.m_dimension)
        {
            this.m_root.remove(this.m_dimension);
            this.m_dimension.geometry.dispose();
        }
        if(this.m_input)
        {
            this.m_input.dispose();
        }
    }

}
export{LineDimension}