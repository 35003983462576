import * as THREE from "three";
import { DbLayer } from "../core/DbLayer";
import { ModelManager } from "../manager/ModelManager";

/**
 * p2d文件格式加载，主要代码位于woerker内
 */
export class P2dLoader {
  //@ts-ignore
  m_model: ModelManager;

  constructor() {}

  load(
    url: string,
    canEdit: boolean,
    model: ModelManager,
    onComplete: (isComplete:boolean) => void
  ) {
    this.m_model = model;
    this.loadProc(url, canEdit, onComplete);
  }
  loadProc(url: string, canEdit: boolean, onComplete: (isComplete:boolean) => void) {
    const worker = new Worker(
      new URL("@/worker/P2dLoader.worker.ts", import.meta.url)
    );
    worker.postMessage({ command: "load", url: url });
    worker.onmessage = ({
      data: { lines, meshs, layers, blocks, instances, complete },
    }) => {
      if (lines) this.m_model.addlines(lines, canEdit);
      if (meshs) this.m_model.addMeshs(meshs, canEdit);
      if (layers) this.m_model.addLayers(layers);
      if (blocks) this.m_model.addBlocks(blocks);
      if (instances) this.m_model.addInstances(instances);
      if (complete) {
        this.m_model.OnComplete();
        onComplete(complete);
        worker.terminate();
      }else if(!complete){
        this.m_model.OnComplete();
        onComplete(complete);
        // worker.terminate();
      }
    };
  }
}
