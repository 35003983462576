/**
 * 图层
 */
export class DbLayer {
    public Name: string;
    public Id: string;
    Color: number | undefined;
    ColorIndex: number | undefined;
    Enabled: boolean = true;
    Visible: boolean = true;
    /**
     * 存储得关联对象
     */
    Objects: THREE.Object3D[] = [];
    LineCount: number | undefined;
    MeshCount: number | undefined;
    constructor(name: string, id: string) {
        this.Name = name;
        this.Id = id;
    }
}