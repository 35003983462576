namespace stringUtils {
  export function StringToByte(str: string) {
    var bytes = new Array();
    var len, c;
    len = str.length;
    for (var i = 0; i < len; i++) {
      c = str.charCodeAt(i);
      if (c >= 0x010000 && c <= 0x10ffff) {
        bytes.push(((c >> 18) & 0x07) | 0xf0);
        bytes.push(((c >> 12) & 0x3f) | 0x80);
        bytes.push(((c >> 6) & 0x3f) | 0x80);
        bytes.push((c & 0x3f) | 0x80);
      } else if (c >= 0x000800 && c <= 0x00ffff) {
        bytes.push(((c >> 12) & 0x0f) | 0xe0);
        bytes.push(((c >> 6) & 0x3f) | 0x80);
        bytes.push((c & 0x3f) | 0x80);
      } else if (c >= 0x000080 && c <= 0x0007ff) {
        bytes.push(((c >> 6) & 0x1f) | 0xc0);
        bytes.push((c & 0x3f) | 0x80);
      } else {
        bytes.push(c & 0xff);
      }
    }
    return bytes;
  }
  /**
   *
   * @param bytes 字节码转字符串
   * @returns
   */
  export function BytesToString(bytes: Uint8Array): string {
    if (typeof bytes === "string") {
      return bytes;
    }
    let isUtf8 = canUseUTF8(bytes);
    if (isUtf8) {
      var str = "",
        _arr = bytes;
      for (var i = 0; i < _arr.length; i++) {
        var one = _arr[i].toString(2),
          v = one.match(/^1+?(?=0)/);
        if (v && one.length == 8) {
          var bytesLength = v[0].length;
          var store = _arr[i].toString(2).slice(7 - bytesLength);
          for (var st = 1; st < bytesLength; st++) {
            store += _arr[st + i].toString(2).slice(2);
          }
          str += String.fromCharCode(parseInt(store, 2));
          i += bytesLength - 1;
        } else {
          str += String.fromCharCode(_arr[i]);
        }
      }
      return str;
    }else{
      const decoder  = new TextDecoder("gbk");
      return decoder.decode(bytes);
    }
  }

  //判断传入的字节流能否使用utf8转成可读字符串
  function canUseUTF8(buffer: Uint8Array) {
    if (buffer.length > 0 && buffer[0] == 0) {
      return false; //首字节为0的就不是可读字符串
    }
    var i = 0;
    while (i < buffer.length) {
      var b = buffer[i++];
      var count; //判断是否符合utf8格式，并且确定后续的字节数
      if (b <= 127) {
        //0xxxxxxx
        count = 0;
      } else if (b >= 194 && b <= 223) {
        //110xxxxx 10xxxxxx
        count = 1;
      } else if (b >= 224 && b <= 239) {
        //1110xxxx 10xxxxxx 10xxxxxx
        count = 2;
      } else if (b >= 240 && b <= 244) {
        //11110xxx 10xxxxxx 10xxxxxx 10xxxxxx
        count = 3;
      } else {
        return false; //不符合utf8格式的首字节
      }
      while (count > 0 && i < buffer.length) {
        b = buffer[i++];
        if (b < 128 || b > 191) {
          //utf的非首字节处于128-191之间，因为10xxxxxx
          return false;
        }
        count--;
      }
    }
    return true;
  }
}

export { stringUtils };
