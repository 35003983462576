class ScreenCoordinateHelper{
    static GetScale(camera:THREE.PerspectiveCamera, height:number){
        let halfAngle = (camera.fov * 0.5) * (Math.PI / 180)
        let L = Math.tan(halfAngle) * camera.position.z * 2
        let P = height;
        let P2 = L / P
        let mindis = P2
        return mindis;
    }
}

export {ScreenCoordinateHelper}