import * as THREE from "three";
import { generateUUID } from "three/src/math/MathUtils";
import { BaseManager } from "../base/BaseManager";
import { Context } from "../core/context";
import { IndserObject, IndsertPosition } from "../core/IndserObject";
import { MLayer } from "../core/MLayer";
import {
  DrawOperateState,
  IDrawOperation,
  DrawOperationMovePointBeforeParams,
  DrawOperationMovePointParams,
} from "../draws/IDrawOperation";
import { DrawOperationLine } from "../draws/DrawOperationLine";
import { DBEntity, DbLayer, ModelManager, ViewManager } from "../CadEngine";
import { EventManager } from "./EventManager";
import { SelectionManager, Selection_Event } from "./SelectionManager";

class DrawManager extends BaseManager {
  private m_modelLayer: MLayer;
  private m_auxModel: MLayer;
  private m_signModel: MLayer;
  private m_eventManager: EventManager;
  private m_selectionManager: SelectionManager;
  private drawObject: IDrawOperation | undefined;
  private m_positions: THREE.Vector3[] = [];
  private curpositon:
    | { point: THREE.Vector3; geo: any; position: IndsertPosition }
    | undefined;
  private m_model: ModelManager;
  private m_view: ViewManager;
  private m_completeCallback:
    | ((entity: DBEntity | DBEntity[]) => void)
    | undefined;
  private onZoomBind: ((camera: THREE.Camera) => void) | undefined;
  private onMouseMoveBind: ((e: PointerEvent) => void) | undefined;
  private onMouseUpBind: ((e: PointerEvent) => void) | undefined;
  private onMouseDownBind: ((e: PointerEvent) => void) | undefined;
  private onKeyDownBind: ((e: KeyboardEvent) => void) | undefined;
  private onSnapFilterBind:
    | ((e: DrawOperationMovePointBeforeParams) => void)
    | undefined;
  private onInsertCallbackBind: ((e: IndserObject) => void) | undefined;
  constructor(context: Context) {
    super(context);
    this.m_modelLayer = this.m_context.SceneManager.ModelLayer;
    this.m_model = this.m_context.ModelManager;
    this.m_auxModel = this.m_context.SceneManager.AuxModel;
    this.m_signModel = this.m_context.SceneManager.SignModel;
    this.m_eventManager = new EventManager();
    this.m_selectionManager = this.m_context.SelectionManager;

    this.m_view = context.ViewManager;
  }

  private initEvent() {
    this.onMouseMoveBind = this.onMouseMove.bind(this);
    this.m_context.ViewManager.EventManager.addEventListener(
      "pointermove",
      this.onMouseMoveBind
    );

    this.onMouseUpBind = this.onMouseUp.bind(this);
    this.m_context.ViewManager.EventManager.addEventListener(
      "pointerup",
      this.onMouseUpBind
    );

    this.onMouseDownBind = this.onMouseDown.bind(this);
    this.m_context.ViewManager.EventManager.addEventListener(
      "pointerdown",
      this.onMouseDownBind
    );

    this.onKeyDownBind = this.onKeyDown.bind(this);
    window.addEventListener("keydown", this.onKeyDownBind);

    this.onSnapFilterBind = this.onSnapFilter.bind(this);
    this.m_selectionManager.EventManager.addEventListener(
      Selection_Event.snapFilter,
      this.onSnapFilterBind
    );

    this.onInsertCallbackBind = this.onInsertCallback.bind(this);
    this.m_selectionManager.EventManager.addEventListener(
      Selection_Event.inserts,
      this.onInsertCallbackBind
    );

    this.onZoomBind = this.OnZoom.bind(this);
    this.m_context.ViewManager.Controls.addEventListener(
      "zoom",
      this.onZoomBind
    );
  }
  private unInitEvent() {
    if (this.onMouseMoveBind)
      this.m_context.ViewManager.EventManager.removeEventListener(
        "pointermove",
        this.onMouseMoveBind
      );
    if (this.onMouseUpBind)
      this.m_context.ViewManager.EventManager.removeEventListener(
        "pointerup",
        this.onMouseUpBind
      );
    if (this.onMouseDownBind)
      this.m_context.ViewManager.EventManager.removeEventListener(
        "pointerdown",
        this.onMouseDownBind
      );
    if (this.onKeyDownBind)
      window.removeEventListener("keydown", this.onKeyDownBind);
    if (this.onSnapFilterBind)
      this.m_selectionManager.EventManager.removeEventListener(
        Selection_Event.snapFilter,
        this.onSnapFilterBind
      );
    if (this.onInsertCallbackBind)
      this.m_selectionManager.EventManager.removeEventListener(
        Selection_Event.inserts,
        this.onInsertCallbackBind
      );
    if (this.onZoomBind)
      this.m_context.ViewManager.Controls.removeEventListener(
        "zoom",
        this.onZoomBind
      );
  }
  private DrawInit() {
    this.m_selectionManager.EnableSnap(true);
    this.m_selectionManager.HighLighting = false;
    this.m_context.ViewManager.Controls.MouseLeftPan = false;
    this.initEvent();
  }
  private DrawEnd() {
    this.m_selectionManager.EnableSnap(false);
    this.m_selectionManager.HighLighting = true;
    this.m_context.ViewManager.Controls.MouseLeftPan = true;
    this.unInitEvent();
    this.m_view.Render();
  }
  private OnZoom(camera: THREE.Camera) {
    this.drawObject?.OnZoom(camera);
  }
  private onKeyDown(e: KeyboardEvent) {
    this.drawObject?.onKeyDown(e);
    if (e.key == "Escape") {
      if (this.drawObject instanceof DrawOperationLine) {
        this.DrawLineFinish(DrawOperateState.complete);
      }
    }
    if (e.ctrlKey && e.key == "z") {
      this.drawObject!.GetPoints().pop();
      this.drawObject?.OnMovePoint(this.curpositon!);
      this.m_view.Render();
    }
  }
  private onMouseMove(e: PointerEvent) {
    this.drawObject?.onMouseMove(e);
  }
  private onMouseUp(e: PointerEvent) {}
  private onMouseDown(e: PointerEvent) {
    if (this.curpositon && e.button == 0)
      this.drawObject?.OnDownPoint({
        point: this.curpositon.point,
        geo: this.curpositon.geo,
        position: this.curpositon.position,
      });
  }
  /**
   * move时候触发，未交到直接使用loc，为未矫正的坐标
   * @param insert
   */
  private onInsertCallback(insert: IndserObject) {
    let p = insert.adjust ?? insert.location;
    this.curpositon = {
      point: new THREE.Vector3(p.x, p.y, p.z),
      geo: insert.geo,
      position: insert.position,
    };
    this.drawObject?.OnMovePoint(this.curpositon);
  }

  private onSnapFilter(param: DrawOperationMovePointBeforeParams) {
    this.drawObject?.OnMovePointBefore(param);
  }

  /**
   * 绘制线
   * @param layerName 图层
   * @param completeCallback 绘制结束回调
   * @param completeBeforeCallback 绘制结束前回调
   * @param isClose 是否闭合
   * @param isSubsection 是否分段
   * @returns
   */
  private isClose: boolean = false;
  public DrawLine(
    layerName: string,
    completeCallback: ((entity: DBEntity | DBEntity[]) => void) | undefined,
    canDrawCallback: (params: DrawOperationMovePointParams) => boolean,
    isClose: boolean = false,
    isSubsection: boolean = false
  ) {
    this.m_completeCallback = completeCallback;
    this.isClose = isClose;
    let layer = this.m_model.GetLayerByName(layerName);
    if (!layer) {
      layer = new DbLayer(layerName, generateUUID());
      layer.Color = 0xffffff;
      layer.ColorIndex = 1;
      layer.Objects = [];
      this.m_model.addLayer(layer);
    }
    this.DrawInit();
    this.drawObject = new DrawOperationLine(
      {
        layer: layer,
        model: this.m_model,
        auxLayer: this.m_auxModel,
        view: this.m_context.ViewManager,
      },
      canDrawCallback,
      isSubsection
    );
    return this.drawObject;
  }

  public DrawLineFinish(operate: DrawOperateState): IDrawOperation | undefined {
    let result = undefined;
    switch (operate) {
      case DrawOperateState.cancel:
        this.drawObject = undefined;
        break;
      case DrawOperateState.complete:
        result = this.drawObject;
        if (result) {
          //@ts-ignore
          let points = result!.points;
          if (this.isClose && points) {
            points[points.length] = points[0];
          }
        }

        let dbentity = this.drawObject?.Finish(operate);
        if (dbentity && this.m_completeCallback)
          this.m_completeCallback(dbentity);
        this.drawObject = undefined;
    }
    this.DrawEnd();
    this.m_completeCallback = undefined;
    return result;
  }

  public get Positions() {
    return this.drawObject?.GetPoints();
  }
}

export { DrawManager };
