import * as THREE from "three";
import { BufferGeometry, Line, Line3, MathUtils, Ray, Vector3 } from "three";
import { generateUUID } from "three/src/math/MathUtils";
import { ArcDimension } from "@/Dimensions/ArcDimension";
import { LineDimension } from "@/Dimensions/LineDimension";
import { IndserObject, IndsertPosition } from "@/core/IndserObject";
import { MLayer } from "@/core/MLayer";
import {
  DBEntity,
  DbLayer,
  DbLine,
  ModelManager,
  ViewManager,
} from "@/CadEngine";
import { BPMathUtils } from "@/utils/BPMathUtils";
import {
  DrawOperateState,
  IDrawOperation,
  DrawOperationMovePointBeforeParams,
  DrawOperationMovePointParams,
} from "./IDrawOperation";
import { AnchorPointAux } from "@/Dimensions/AnchorPointAuxs";
import { BPLinear } from "@/core/BPLinear";
import { OrthogonalAux } from "@/Dimensions/OrthogonalAux";

interface DrawOperateLineParams {
  layer: DbLayer;
  model: ModelManager;
  auxLayer: MLayer;
  view: ViewManager;
}

class DrawOperationLine implements IDrawOperation {
  private m_model: ModelManager;
  private m_layer: DbLayer;
  private m_auxLayer: MLayer;
  private m_material: THREE.LineBasicMaterial;
  private m_tempObject: THREE.Line | undefined;
  private m_geometry: THREE.BufferGeometry | undefined;
  private points: THREE.Vector3[] = [];
  private m_locationObject: THREE.Line;
  private m_size = { x: 1, y: 1, z: 1 };
  private view: ViewManager;
  private m_dimension: DimensionGroup;
  private inputVuale: string = "";
  private m_auxPoints: {
    orgin: THREE.Vector3;
    dir: THREE.Vector3 | undefined;
  }[] = [];
  private m_preMovePoint: { point: THREE.Vector3; time: number } = {
    point: new THREE.Vector3(),
    time: 0,
  };
  private m_auxOrthogonalOrVertical:
    | { position: IndsertPosition; origin: THREE.Vector3; dir: THREE.Vector3 }
    | undefined;

  private canDrawCallBack: (params: DrawOperationMovePointParams) => boolean;
  private isSubsection: boolean = false;
  constructor(
    options: DrawOperateLineParams,
    canDrawCallBack: (params: DrawOperationMovePointParams) => boolean,
    isSubsection: boolean = false
  ) {
    this.m_model = options.model;
    this.m_layer = options.layer;
    this.m_auxLayer = options.auxLayer;
    this.m_material = new THREE.LineBasicMaterial({
      color: options.layer.Color,
    });
    this.m_locationObject = this.createLine(
      [-20, 0, 0, 20, 0, 0, 0, -20, 0, 0, 20, 0],
      undefined,
      new THREE.LineBasicMaterial({ color: "green" })
    );
    this.view = options.view;
    let scale = options.view.ScreenModelScale;
    this.m_locationObject.scale.set(
      scale * this.m_locationObject.userData.scale.x,
      scale * this.m_locationObject.userData.scale.y,
      scale * this.m_locationObject.userData.scale.z
    );
    this.m_auxLayer.add(this.m_locationObject);
    this.m_dimension = new DimensionGroup(options.view, this.m_auxLayer);
    this.canDrawCallBack = canDrawCallBack;
    this.isSubsection = isSubsection;
  }
  onMouseMove(e: PointerEvent): void {
    if (this.points.length > 0) {
      this.m_dimension.Line.SetDimension(
        this.originPoint,
        this.curPoint,
        this.scale
      );
      this.m_dimension.Arc.SetDimension(
        this.originPoint,
        new THREE.Vector3(1, 0, 0),
        this.curDir,
        this.scale
      );
    }
    this.m_dimension.Anchor.SetPoint(this.auxPoint, this.scale);
  }
  onKeyDown(e: KeyboardEvent): void {
    this.m_auxPoints = [];
    if (e.key == "Backspace") {
      if (this.inputVuale.length > 0) {
        this.inputVuale = this.inputVuale.substring(
          0,
          this.inputVuale.length - 1
        );
        this.m_dimension.Line.SetVaule(this.inputVuale);
      }
    } else if ((e.key >= "0" && e.key <= "9") || e.key == ".") {
      if (this.inputVuale?.includes(".")) return;
      this.inputVuale += e.key;
      this.m_dimension.Line.SetVaule(this.inputVuale);
    } else if (e.key == "Enter") {
      if (this.inputVuale != "") {
        if (this.points.length > 0) {
          let length = parseFloat(this.inputVuale) * 0.001;
          let position = this.m_locationObject.position.clone();
          let dir = this.points[this.points.length - 1]
            .clone()
            .sub(position)
            .normalize();
          position = this.points[this.points.length - 1]
            .clone()
            .add(dir.multiplyScalar(-length));
          this.points.push(position);
          this.OnMovePoint({
            point: this.m_locationObject.position,
            geo: undefined,
            position: IndsertPosition.None,
          });
          this.view.Render();
        }
      }
    }
  }

  private get originPoint() {
    return this.points[this.points.length - 1];
  }
  private get curPoint() {
    return this.m_locationObject.position;
  }

  private get curDir() {
    return this.curPoint.clone().sub(this.originPoint).normalize();
  }
  OnZoom(camera: THREE.Camera) {
    this.m_auxPoints = [];
    let scale = this.scale;
    if (this.points.length > 0) {
      this.m_dimension.Line.SetDimension(
        this.originPoint,
        this.curPoint,
        scale
      );
      this.m_dimension.Arc.SetDimension(
        this.originPoint,
        new THREE.Vector3(1, 0, 0),
        this.curDir,
        scale
      );
    }
    this.m_dimension.Anchor.SetPoint(this.auxPoint, scale);
    this.m_dimension.OrthogonalAux.visible = false;
    if (this.m_auxOrthogonalOrVertical) {
      this.m_dimension.OrthogonalAux.Set(
        this.m_auxOrthogonalOrVertical.origin,
        this.m_auxOrthogonalOrVertical.dir,
        scale,
        this.m_auxOrthogonalOrVertical.position == IndsertPosition.AuxOrthogonal
          ? undefined
          : this.curPoint
      );
    }
  }
  OnMovePointBefore(params: DrawOperationMovePointBeforeParams): void {
    let tolSq = params.tol * params.tol;

    if (this.points.length > 0) {
      let start = new THREE.Vector3();
      let end = new THREE.Vector3();
      let target = new THREE.Vector3();
      let targetOnSegment = new THREE.Vector3();
      let point = this.points[this.points.length - 1];
      if (this.points.length > 1) {
        for (let index = 0; index < this.points.length - 1; index++) {
          start.copy(this.points[index]);
          end.copy(this.points[index + 1]);
          let distance = params.ray.distanceSqToSegment(
            start,
            end,
            target,
            targetOnSegment
          ); // target在射线上，targetOnSegment在线段上
          //  console.log(target.x,target.y,target.z+'          '+targetOnSegment.x,targetOnSegment.y,targetOnSegment.z)
          if (distance <= tolSq) {
            // let location = {x:Number(target.x.toFixed(3)), y:Number(target.y.toFixed(3)), z:Number(target.z.toFixed(3))};
            let location = {
              x: Number(targetOnSegment.x.toFixed(3)),
              y: Number(targetOnSegment.y.toFixed(3)),
              z: Number(targetOnSegment.z.toFixed(3)),
            };
            let center = new THREE.Vector3(
              (start.x + end.x) * 0.5,
              (start.y + end.y) * 0.5,
              (start.z + end.z) * 0.5
            );
            let d1 = start.distanceTo(targetOnSegment);
            let d2 = center.distanceTo(targetOnSegment);
            let d3 = end.distanceTo(targetOnSegment);
            let position = IndsertPosition.None;
            let min = Math.min(d1, d2, d3);
            let adjust = targetOnSegment.clone();
            if (min <= params.tol) {
              if (min == d1) {
                position = IndsertPosition.Start;
                adjust = start.clone();
              }
              if (min == d2) {
                position = IndsertPosition.Center;
                adjust = center;
              }
              if (min == d3) {
                position = IndsertPosition.End;
                adjust = end.clone();
              }
            }
            let insert = new IndserObject(location, distance, "", []);
            insert.adjust = adjust;
            insert.position = position;
            insert.geo = new THREE.Line3(start.clone(), end.clone());
            params.array.push(insert);
          }
        } // 以上先是用当前移动线检测是否和自己画的线段相交，不包括最后一个线段

        //夹角矫正
        {
          // 这段代码作用为用当前移动线段来和自己画的线的最后一段线检测是否相交
          // alert('yyyyyyyy')
          let direction = new THREE.Vector3();
          this.view.Camera.getWorldDirection(direction);
          let line = new THREE.Line3(
            this.points[this.points.length - 2].clone(),
            this.points[this.points.length - 1].clone()
          );
          let p0 = new THREE.Vector3();
          //  console.log(direction)
          let p = params.ray.intersectPlane(new THREE.Plane(direction, 0), p0);

          //   console.log(direction)

          let dir0 = line.end.clone().sub(line.start).normalize();
          let dir1 = p0.clone().sub(line.end).normalize();
          let rad = BPMathUtils.angleTo(dir0, dir1, direction);
          let angle = (rad * 180) / Math.PI;
          //  debugger
          let orginLength = p0.distanceTo(line.end);
          let tolAngle = (((params.tol / orginLength) * 180) / Math.PI) * 2; // orginLength越小自动偏转幅度越大

          //   console.log(tolAngle)
          let offsetAngle = (angle + tolAngle) % 90;
          if (offsetAngle <= tolAngle * 2) {
            //五度之内视为垂直
            rad = rad - ((offsetAngle - tolAngle) * Math.PI) / 180;
            let p = dir0
              .applyAxisAngle(direction, rad)
              .multiplyScalar(p0.distanceTo(line.end));

            p = line.end.clone().add(dir0);
            let insert = new IndserObject(
              p,
              Math.sqrt(params.ray.distanceSqToPoint(p)),
              "",
              []
            );
            insert.adjust = p;
            insert.position = IndsertPosition.AuxOrthogonal;
            params.array.push(insert);
          }
        }
      }
      //正交矫正
      {
        // 这段代码作用为 当只有一个点的时候移动线段和 X轴的相交情况
        let direction = new THREE.Vector3();
        this.view.Camera.getWorldDirection(direction);
        let start = this.points[this.points.length - 1].clone();
        let p0 = new THREE.Vector3();
        params.ray.intersectPlane(new THREE.Plane(direction, 0), p0);
        let dir0 = new THREE.Vector3(1, 0, 0);
        let dir1 = p0.clone().sub(start).normalize();
        let rad = BPMathUtils.angleTo(dir0, dir1, direction);
        let angle = (rad * 180) / Math.PI;
        let orginLength = p0.distanceTo(start);
        let tolAngle = (((params.tol / orginLength) * 180) / Math.PI) * 2;
        let offsetAngle = (angle + tolAngle) % 90;

        if (offsetAngle <= tolAngle * 2) {
          //五度之内视为垂直
          rad = rad - ((offsetAngle - tolAngle) * Math.PI) / 180;
          let p = dir0
            .applyAxisAngle(direction, rad)
            .multiplyScalar(p0.distanceTo(start));
          p = start.clone().add(dir0);
          let insert = new IndserObject(
            p,
            Math.sqrt(params.ray.distanceSqToPoint(p)),
            "",
            []
          );
          insert.adjust = p;
          insert.position = IndsertPosition.AuxOrthogonal;
          // insert.geo = new THREE.Line3(start.clone(), end.clone());
          params.array.push(insert);
        }
      }
    }
    let ray = new THREE.Ray();

    /**
     * 辅助点的吸附点
     */
    if (this.m_auxPoints.length > 0) {
      let normal = new THREE.Vector3(0, 0, 1);
      let panel = new THREE.Plane(normal, 0);
      let insertPoint = new THREE.Vector3();
      params.ray.intersectPlane(panel, insertPoint);

      let func = (line: BPLinear, position: IndsertPosition) => {
        let target = new THREE.Vector3();
        let dis = line.distanceSqPoint(insertPoint, target); //line.distanceSqToRay(params.ray, target);
        if (dis <= tolSq) {
          let insert = new IndserObject(target, Math.sqrt(dis), "", []);
          insert.adjust = target;
          insert.position = position;
          insert.geo = line;
          params.array.push(insert);
        }
      };

      this.m_auxPoints.forEach((item) => {
        if (item.orgin && item.dir) {
          if (
            item.dir.distanceTo(new THREE.Vector3(1, 0, 0)) <= 0.0000001 ||
            item.dir.distanceTo(new THREE.Vector3(-1, 0, 0)) <= 0.0000001 ||
            item.dir.distanceTo(new THREE.Vector3(0, 1, 0)) <= 0.0000001 ||
            item.dir.distanceTo(new THREE.Vector3(0, -1, 0)) <= 0.0000001
          ) {
            func(
              new BPLinear(item.orgin, item.dir),
              IndsertPosition.AuxVertical
            );
            func(
              new BPLinear(
                item.orgin,
                item.dir.clone().applyAxisAngle(normal, Math.PI * 0.5)
              ),
              IndsertPosition.AuxVertical
            );
          } else {
            func(
              new BPLinear(item.orgin, item.dir),
              IndsertPosition.AuxVertical
            );
            func(
              new BPLinear(
                item.orgin,
                item.dir.clone().applyAxisAngle(normal, Math.PI * 0.5)
              ),
              IndsertPosition.AuxVertical
            );
            func(
              new BPLinear(item.orgin, new THREE.Vector3(1, 0, 0)),
              IndsertPosition.AuxOrthogonal
            );
            func(
              new BPLinear(item.orgin, new THREE.Vector3(0, 1, 0)),
              IndsertPosition.AuxOrthogonal
            );
          }
        }
      });
    }

    params.array.forEach((item) => {
      if (item.position == "none") item.position = IndsertPosition.Insert;
      if (item.geo) {
        if (item.geo instanceof THREE.Line3) {
          let line = item.geo as THREE.Line3;
          let p0 = item.adjust ?? item.location;
          let v0 = line.start.clone().sub(line.end.clone());
          let dir0 = v0.clone().normalize();
          if (this.points.length > 0) {
            let point = this.points[this.points.length - 1];
            let v1 = new THREE.Vector3(
              p0.x - point.x,
              p0.y - point.y,
              p0.z - point.z
            );
            let offset = dir0.multiplyScalar(v1.dot(dir0));
            if (offset.lengthSq() <= params.tol * params.tol) {
              //正交矫正
              item.position = IndsertPosition.Vertical;
              item.adjust = new THREE.Vector3(
                p0.x - offset.x,
                p0.y - offset.y,
                p0.z - offset.z
              );
            }
          }
          ray.set(line.start, line.end.clone().sub(line.start).normalize()); // 这里开始检测移动线段是否和场景中两个或两个以上的交点处接触
          params.array.forEach((item1) => {
            if (item1.geo instanceof THREE.Line3) {
              let line1 = item1.geo as THREE.Line3;
              if (line != line1) {
                let target = new THREE.Vector3();
                let target1 = new THREE.Vector3();
                let distance = ray.distanceSqToSegment(
                  line1.start.clone(),
                  line1.end.clone(),
                  target
                ); //
                if (distance < 0.0000001) {
                  line.closestPointToPoint(target, true, target1); //
                  if (target.distanceTo(target1) < 0.00000001) {
                    // 说明这两条线交叉了，因为这个点同时在两根线上
                    item.position = IndsertPosition.Cross;
                    item.adjust = target;
                    item.distance = target.distanceTo(
                      new THREE.Vector3(
                        item.location.x,
                        item.location.y,
                        item.location.z
                      )
                    );
                  }
                }
              }
            }
          });
        }
      }
    });
  }
  OnMovePoint(params: DrawOperationMovePointParams): void {
    this.inputVuale = "";
    this.m_locationObject.position.copy(params.point);
    this.m_locationObject.visible = true;

    if (params.point.distanceTo(this.m_preMovePoint.point) < this.scale) {
      if (
        params.position == IndsertPosition.End ||
        params.position == IndsertPosition.Start ||
        params.position == IndsertPosition.Center ||
        params.position == IndsertPosition.Cross
      ) {
        if (new Date().getTime() - this.m_preMovePoint.time > 300) {
          //超过1秒 记住锚点
          let dir =
            params.geo && params.geo instanceof THREE.Line3
              ? params.geo.end.clone().sub(params.geo.start).normalize()
              : undefined;
          this.m_auxPoints.push({ orgin: params.point, dir: dir });
        }
      }
    } else {
      this.m_preMovePoint.time = new Date().getTime();
      this.m_preMovePoint.point.copy(params.point);
    }

    this.m_dimension.OrthogonalAux.visible = false;
    if (params.position == IndsertPosition.AuxOrthogonal) {
      let line =
        params.geo instanceof BPLinear
          ? { origin: params.geo.Origin, dir: params.geo.Direction }
          : params.geo instanceof THREE.Line3
          ? {
              origin: params.point,
              dir: params.geo.end.clone().sub(params.geo.start).normalize(),
            }
          : undefined;
      if (line) {
        this.m_dimension.OrthogonalAux.Set(line.origin, line.dir, this.scale);
        this.m_auxOrthogonalOrVertical = {
          position: params.position,
          origin: line.origin,
          dir: line.dir,
        };
      }
    }
    if (params.position == IndsertPosition.AuxVertical) {
      let line =
        params.geo instanceof BPLinear
          ? { origin: params.geo.Origin, dir: params.geo.Direction }
          : params.geo instanceof THREE.Line3
          ? {
              origin: params.point,
              dir: params.geo.end.clone().sub(params.geo.start).normalize(),
            }
          : undefined;
      if (line) {
        this.m_dimension.OrthogonalAux.Set(
          line.origin,
          line.dir,
          this.scale,
          params.point
        );
        this.m_auxOrthogonalOrVertical = {
          position: params.position,
          origin: line.origin,
          dir: line.dir,
        };
      }
    }

    if (this.points.length == 0) return;
    let ps = [];
    ps.push(...this.points);
    ps.push(params.point);
    if (this.m_geometry) {
      this.m_geometry.setFromPoints(ps);
    } else {
      this.m_geometry = new THREE.BufferGeometry();
      this.m_geometry.setFromPoints(ps);
      this.m_tempObject = new THREE.Line(this.m_geometry, this.m_material);
      this.m_tempObject.frustumCulled = false;
      this.m_auxLayer.add(this.m_tempObject);
    }
    this.m_dimension.Arc.SetDimension(
      this.points[this.points.length - 1],
      new THREE.Vector3(1, 0, 0),
      params.point
        .clone()
        .sub(this.points[this.points.length - 1])
        .normalize(),
      this.scale
    );
    this.m_dimension.Line.SetDimension(
      this.points[this.points.length - 1],
      params.point,
      this.scale
    );
  }
  OnDownPoint(params: DrawOperationMovePointParams): void {
    if (!this.canDrawCallBack(params)) {
      return;
    }
    if (this.points.length <= 1) {
      this.m_auxPoints = [];
      this.points.push(params.point);
    }
    if (
      this.points.length > 1 &&
      this.points[this.points.length - 1].x == params.point.x &&
      this.points[this.points.length - 1].y == params.point.y &&
      this.points[this.points.length - 1].z == params.point.z
    ) {
      this.points.length = this.points.length - 1;
      this.m_auxPoints = [];
      this.points.push(params.point);
    }

    if (this.points.length > 1) {
      if (
        this.points[this.points.length - 1].x != params.point.x ||
        this.points[this.points.length - 1].y != params.point.y ||
        this.points[this.points.length - 1].z != params.point.z
      ) {
        this.m_auxPoints = [];
        this.points.push(params.point);
      }
    }
  }
  GetPoints(): Vector3[] {
    return this.points;
  }

  get auxPoint() {
    let ps: THREE.Vector3[] = [];
    this.m_auxPoints.forEach((x) => {
      ps.push(x.orgin);
    });
    return ps;
  }
  Finish(operate: DrawOperateState): DBEntity| DBEntity[]|undefined {
    if (this.m_tempObject) this.m_auxLayer.remove(this.m_tempObject);
    if (this.m_geometry) this.m_geometry.dispose();

    if (this.m_locationObject) {
      this.m_auxLayer.remove(this.m_locationObject);
      this.m_locationObject.geometry.dispose();
    }
    if (this.m_dimension) {
      this.m_dimension.dispose();
    }
    if (operate == DrawOperateState.complete) {
      if (this.points.length > 1) {
        let key = `${this.m_layer.Id}_${this.m_layer.Color}`;

        let lines: DbLine[] = this.m_model.Lines[key]?.lines ?? [];
        let ps: number[] = [];
        let index = 0;
        let indexs: number[] = [];
        let returnLine: DbLine|DBEntity[]=[];
        this.points.forEach((p) => {
          ps.push(p.x, p.y, p.z);
          indexs.push(index);
          if (index != 0 && index != this.points.length - 1) indexs.push(index);
          index++;
        });
        if (this.isSubsection) {
          for (let i = 0; i < ps.length; i++) {
            if (i % 3 === 0&&i+3<ps.length) {
              let line = new DbLine({
                id: generateUUID(),
                layerId: this.m_layer.Id,
                blockId: "",
                points: new Float32Array([
                  ps[i],
                  ps[i + 1],
                  ps[i + 2],
                  ps[i + 3],
                  ps[i + 4],
                  ps[i + 5],
                ]),
                indexes: new Int32Array([0, 1]),
              });
              let sphere = new THREE.Sphere();
              let box = new THREE.Box3();
              box.setFromArray(line.points);
              box.getBoundingSphere(sphere);
              line.sphere = sphere;
              line.box = box;
              lines.push(line);
              returnLine!.push(line);
            }
          }
        } else {
          let line = new DbLine({
            id: generateUUID(),
            layerId: this.m_layer.Id,
            blockId: "",
            points: new Float32Array(ps),
            indexes: new Int32Array(indexs),
          });
          let sphere = new THREE.Sphere();
          let box = new THREE.Box3();
          box.setFromArray(line.points);
          box.getBoundingSphere(sphere);
          line.sphere = sphere;
          line.box = box;
          lines.push(line);
          returnLine!.push(line);
        }
        if (this.m_layer.Color) {
          if (this.m_model.Lines[key]) {
            this.m_model.Lines[key].canEdit = true;
            this.m_model.Lines[key].lines = lines;
            this.m_model.Lines[key].model = this.m_model.addLinesToModel(
              this.m_layer.Id,
              this.m_layer.Color,
              lines
            );
          } else {
            this.m_model.Lines[key] = {
              canEdit: true,
              lines: lines,
              model: this.m_model.addLinesToModel(
                this.m_layer.Id,
                this.m_layer.Color,
                lines
              ),
            };
          }
        }

        if(this.isSubsection){
          return returnLine;
        }else{  
         return returnLine[0]
        }

        // return line;
      }
    } else if (operate == DrawOperateState.cancel) {
      return undefined;
    }
    return undefined;
  }

  private createLine(
    position: number[],
    indexs: number[] | undefined,
    material: THREE.LineBasicMaterial
  ): THREE.LineSegments {
    let geo = new THREE.BufferGeometry();
    geo.setAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(position), 3)
    );
    if (indexs) geo.setIndex(indexs);
    let mesh = new THREE.LineSegments(geo, material);
    mesh.renderOrder = 20;
    mesh.userData.scale = this.m_size;
    return mesh;
  }

  private get scale() {
    return this.view.ScreenModelScale;
  }
}

class DimensionGroup {
  private m_dimension: LineDimension;
  private m_arcDimension: ArcDimension;
  private m_anchor: AnchorPointAux; // 十字mesh
  private m_orthogonalAux: OrthogonalAux;
  constructor(view: ViewManager, root: THREE.Object3D) {
    this.m_arcDimension = new ArcDimension(view, root);
    this.m_dimension = new LineDimension(view, root);
    this.m_anchor = new AnchorPointAux(view, root);
    this.m_orthogonalAux = new OrthogonalAux(view, root);
  }

  public get Line() {
    return this.m_dimension;
  }

  public get Arc() {
    return this.m_arcDimension;
  }
  public get Anchor() {
    return this.m_anchor;
  }

  public get OrthogonalAux() {
    return this.m_orthogonalAux;
  }

  public dispose() {
    if (this.Line) this.Arc.dispose();
    if (this.Line) this.Line.dispose();
    if (this.m_anchor) this.m_anchor.dispose();
    if (this.m_orthogonalAux) {
      //     alert(';;;;;;;;;;;;;;;;')
      this.m_orthogonalAux.dispose();
    }
  }
}

export { DrawOperationLine, DrawOperateLineParams };
