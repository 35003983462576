import * as THREE from "three";

export class SnapModel{
    /**
     * 三角形，用于表示中点
     */
    private triangular: THREE.LineSegments;
    /**
     * 正方形，用于表示端点
     */
    private square: THREE.LineSegments;
    /**
     * 叉，表示相交的交点处
     */
    private cross: THREE.LineSegments;
    /**
     * 拐线，表示垂足
     */
    private turn:THREE.LineSegments;
    /**
     * 相交，绘制的线和原有的线相交
     */
    private insert: THREE.LineSegments;
    private material: THREE.LineBasicMaterial;
    private map:Map<snap, THREE.LineSegments> = new Map<snap, THREE.LineSegments>();
    private m_size: { x: number; y: number; z: number; }|undefined;
    private m_renderOrder = 15;
    constructor(parent:THREE.Object3D) {
        this.m_size = {x:1, y:1,z:1};
        this.material = new THREE.LineBasicMaterial({ color: 0xff0000});
        this.triangular = this.createTriangular(this.material);
        this.square = this.createSquare(this.material);
        this.cross = this.createCross(this.material);
        this.insert = this.createInsert(this.material);
        this.turn = this.createTurn(this.material);
        parent.add(this.triangular);
        parent.add(this.square);
        parent.add(this.cross);
        parent.add(this.insert);
        parent.add(this.turn);
        this.map.set(snap.center, this.triangular);
        this.map.set(snap.cross, this.cross);
        this.map.set(snap.end, this.square);
        this.map.set(snap.insert, this.insert);
        this.map.set(snap.vertical, this.turn);
    }

    createTriangular(material: THREE.LineBasicMaterial): THREE.LineSegments<THREE.BufferGeometry, THREE.Material | THREE.Material[]> {
        let path = [-8.6481, -5.0209, 0, 
                          0,      10, 0,  
                     8.6603, -5.0000, 0];
        return this.createLine(path, [0,1,1,2,2,0], material);
    }
    createSquare(material: THREE.LineBasicMaterial): THREE.LineSegments<THREE.BufferGeometry, THREE.Material | THREE.Material[]> {
        let path = [-7.0710, -7.0710, 0,
                    -7.0710,  7.0710, 0,
                     7.0710,  7.0710, 0,
                     7.0710, -7.0710, 0];
        return this.createLine(path, [0,1,1,2,2,3,3,0], material);
    }
    createCross(material: THREE.LineBasicMaterial): THREE.LineSegments<THREE.BufferGeometry, THREE.Material | THREE.Material[]> {
        let path = [];
        path.push(-7.0710, -7.0710, 0,  7.0710,  7.0710, 0);
        path.push(-7.0710,  7.0710, 0,  7.0710, -7.0710, 0);
        return this.createLine(path, undefined, material);
    }
    createInsert(material: THREE.LineBasicMaterial): THREE.LineSegments<THREE.BufferGeometry, THREE.Material | THREE.Material[]> {
        let path = [];
        path.push(-7.0710, -7.0710, 0,  7.0710,  7.0710, 0);
        path.push(-7.0710,  7.0710, 0,  7.0710, -7.0710, 0);
        path.push(-7.0710, 7.0710, 0,  7.0710,  7.0710, 0);
        path.push(-7.0710, -7.0710, 0,  7.0710,  -7.0710, 0);
        return this.createLine(path, undefined, material);
    }
    createTurn(material: THREE.LineBasicMaterial): THREE.LineSegments<THREE.BufferGeometry, THREE.Material | THREE.Material[]> {
        let path = [];
        path.push(-7.0710,  7.0710, 0, -7.0710, -7.0710, 0);
        path.push(-7.0710, -7.0710, 0,  7.0710, -7.0710, 0);
        path.push(5, 0, 0, -7.0710, 0, 0);
        path.push(0, 5, 0,  0, -7.0710, 0);
        return this.createLine(path, undefined, material);
    }

    private createLine(position:number[], indexs:number[]|undefined, material: THREE.LineBasicMaterial):THREE.LineSegments
    {
        let geo = new THREE.BufferGeometry();
        geo.setAttribute('position', new THREE.BufferAttribute(new Float32Array(position), 3));
        if(indexs)
            geo.setIndex(indexs);
        let mesh = new THREE.LineSegments(geo, material); 
        mesh.renderOrder = 15;
        mesh.userData.scale = this.m_size;
        mesh.visible = false;
        return mesh;
    }

    public set(type:snap, posistion:THREE.Vector3, scale:number)
    {
        this.map.forEach((value, key)=>{
            if(type == key)
            {
                value.position.copy(posistion);
                value.visible = true;
                value.scale.set(scale * value.userData.scale.x, scale * value.userData.scale.y,scale * value.userData.scale.z)
            }
            else{
                value.visible = false;
            }
        })
    }
}
export enum snap{
    none,
    center,
    end,
    insert,
    cross,
    vertical,
    other,
}