/**
 * 事件管理器
 */
export class EventManager{
    events: {[key: string]: Function[]} = {};

    public addEventListener(type: string, listener: Function) {
        if (!this.events[type]) {
            this.events[type] = [];
        }
        if ( this.events[type].indexOf(listener) === - 1 )
            this.events[type].push(listener);
    }

    public removeEventListener(type: string, listener: Function) {
        if(this.events[type]){
            let index = this.events[type].indexOf(listener);
            if(index !== - 1 ){
                this.events[type].splice(index, 1);
            }
        }
    }

    public dispatchEvent(type: string, data?: any) {
        if(this.events[type]){
            this.events[type].forEach(listener => {
                listener(data);
            });
        }
    }

    public ClearListener(type:string)
    {
        if(this.events[type]){
            this.events[type] = []
        }
    }
}