import { DbBlockRecord } from "./DbBlockRecord";
import { DBEntity } from "./DBEntity";
/**
 * 块的实体，DbBlockRecord通过DbBlockInstance实例化到场景内
 */
export class DbBlockInstance extends DBEntity {
    blockRecord:DbBlockRecord;
    matrix:THREE.Matrix4;
    color:THREE.Color|undefined;
    sphere: THREE.Sphere|undefined;
    line: THREE.InstancedMesh|undefined;
    Mesh:  THREE.Mesh|undefined;
    originData?:{matrix:THREE.Matrix4};

    constructor(params:{id:string, layerId: string, blockId: string, matrix: THREE.Matrix4, blockRecord?: DbBlockRecord})
    {
        super(params);
        //@ts-ignore
        this.blockRecord = params.blockRecord;
        this.matrix = params.matrix;

    }

}