import { ViewManager } from "@/manager/ViewManager";
import * as THREE from "three";
import { BaseDimemsion } from "./BaseDimension";

/**
 * 辅助锚点
 */
class AnchorPointAux extends BaseDimemsion {
  private m_line: THREE.LineSegments | undefined;
  constructor(view: ViewManager, root: THREE.Object3D) {
    super(view, root);
    this.m_root = root;
  }

  public SetPoint(points: THREE.Vector3[], scale: number) {
    if (this.m_line) this.m_line.visible = points.length > 0;

    let path: number[] = [];
    points.forEach((point) => {
      path.push(
        -5 * scale + point.x,
        point.y,
        0,
        5 * scale + point.x,
        point.y,
        0
      );
      path.push(
        point.x,
        5 * scale + point.y,
        0,
        point.x,
        -5 * scale + point.y,
        0
      );
    });
    if (!this.m_line) {
      let geo = new THREE.BufferGeometry();
      geo.setAttribute(
        "position",
        new THREE.BufferAttribute(new Float32Array(path), 3)
      );
      this.m_line = new THREE.LineSegments(
        geo,
        new THREE.LineBasicMaterial({ color: "green" })
      );
      this.m_line.frustumCulled = false;
      this.m_root.add(this.m_line);
    } else {
      this.m_line.geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(new Float32Array(path), 3)
      );
    }
  }

  public dispose() {
    if (this.m_line) {
      this.m_root.remove(this.m_line);
      this.m_line.geometry.dispose();
    }
  }
}

export { AnchorPointAux };
