import {Context} from './core/context';
import {Box3, Color,Matrix4, Material, MeshPhongMaterial,Mesh,Vector3,Vector2, TextureLoader, SpriteMaterial,Sphere,BufferGeometry,BufferAttribute,Uint32BufferAttribute, Box3Helper} from "three";
import { LoadManager } from './manager/LoaderManager';
import { SceneManager } from './manager/SceneManager';
import { ViewManager } from './manager/ViewManager';
import { SelectionManager } from './manager/SelectionManager';
import { ModelManager } from './manager/ModelManager';
import {MaterialManager} from './manager/MaterialManager';
import { DbLine } from './core/DbLine';
import { DbMesh } from './core/DbMesh';
import { DBEntity } from './core/DBEntity';
import { DbLayer } from './core/DbLayer';
import {CameraControls} from './Controls/CameraControls'
import { DrawManager } from './manager/DrawManager';
import { DrawOperateState, IDrawOperation } from './draws/IDrawOperation';
import { DrawOperationLine } from './draws/DrawOperationLine';
export { generateUUID } from 'three/src/math/MathUtils';
export { Matrix4 } from 'three';
let context:Context;
class CadEngine{

    /**
     * 加载器，提供加载不同文件接口
     */
    public get LoadManager():LoadManager{
        return context.LoadManager;
    }

    constructor(container:HTMLDivElement){
        context = new Context({div:container});
    }
    LoadModel(model:IModelInfo) {
        this.LoadManager.LoadModel(model);
    }

    public get SceneManager(){
        return context.SceneManager;
    }

    public get ViewManager(){
        return context.ViewManager;
    }
    public get SelectionManager(){
        return context.SelectionManager;
    }
    public get ModelManager(){
        return context.ModelManager;
    }
    public get DrawManager(){
        return context.DrawManager;
    }
}

class IBPCad3DEngined{
    public get LoadManager():LoadManager{
        return context.LoadManager;
    }
    constructor(container:HTMLDivElement){
        context = new Context({div:container});
    }
    public get SceneManager(){
        return context.SceneManager;
    }
    public get ViewManager(){
        return context.ViewManager;
    }
    public get SelectionManager(){
        return context.SelectionManager;
    }
    public get ModelManager(){
        return context.ModelManager;
    }
}

export {LoadManager, SceneManager,ViewManager,SelectionManager,ModelManager,MaterialManager,CameraControls, DrawManager}
export {DbLine, DbMesh,DBEntity,DbLayer}
export {IDrawOperation,DrawOperationLine,DrawOperateState}
export {Box3,MeshPhongMaterial,Box3Helper, Material, Color,Mesh,Vector3,Vector2,TextureLoader,SpriteMaterial,Sphere,BufferGeometry,BufferAttribute,Uint32BufferAttribute}

export { CadEngine };

export{IBPCad3DEngined}


// alert("sds");