import { BaseManager } from "../base/BaseManager";
import { Context } from "../core/context";
import * as THREE from "three";
import { SceneManager } from "./SceneManager";
import { CameraControls } from "../Controls/CameraControls";
import { EventManager } from "./EventManager";
import { ScreenCoordinateHelper } from "../utils/ScreenCoordinateHelper";
import { DBEntity } from "../core/DBEntity";
import { SelectionBox } from "../core/SelectionBox";
import { Box3, Mesh, ShapeBufferGeometry } from "three";
import { BoxToPath } from "../utils/GeometryUtils";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer.js";

/**
 * 视图管理器
 */
class ViewManager extends BaseManager {
  private m_canvas: HTMLCanvasElement;
  private m_renderer: THREE.WebGLRenderer;
  private m_scene: THREE.Scene;
  private m_effectScene: THREE.Scene;
  private m_camera: THREE.Camera;
  public m_controls: CameraControls; //OrbitControls;
  private m_sceneManger: SceneManager;
  private m_hasPicked: boolean;
  private m_hasMoved: boolean;
  private m_eventManager: EventManager = new EventManager();
  private m_meshDisableMaterial: THREE.MeshBasicMaterial;
  private m_lineDisableMaterial: THREE.LineBasicMaterial;
  private m_renderTarget;
  ScreenModelScale: number;
  private CSS2DRenderer!: CSS2DRenderer;
  // private dbxArr:any

  constructor(context: Context) {
    super(context);
    this.m_canvas = document.createElement("canvas");
    this.m_canvas.style.width = "100%";
    this.m_canvas.style.height = "100%";
    this.m_canvas.style.overflow = "hidden";
    this.m_context.Dom.appendChild(this.m_canvas);
    this.m_renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: this.m_canvas,
      precision: "highp",
      alpha: true,
    });
    this.m_renderer.setClearColor(0xffffff, 0.0);
    this.m_renderer.setPixelRatio(window.devicePixelRatio);
    // this.m_renderer.setSize(
    //   this.m_context.Dom.clientWidth,
    //   this.m_context.Dom.clientHeight
    // );
    this.m_renderer.shadowMap.enabled = true;
    this.m_camera = new THREE.PerspectiveCamera(
      60,
      this.m_canvas.width / this.m_canvas.height,
      0.00001,
      100000000000
    );
    this.m_camera.position.set(0, 0, 100);
    this.m_scene = this.m_context.SceneManager.Scene;
    this.m_effectScene = this.m_context.SceneManager.EffectScene;
    this.m_sceneManger = this.m_context.SceneManager;
    this.m_controls = new CameraControls(this.m_camera); //new OrbitControls( this.m_camera, this.m_renderer.domElement ,this.m_scene,this.m_renderer,this.oldVerts);
    this.m_controls.addEventListener("zoom", (event: any) => {
      //控制auxlayer层尺寸，和界面保持一致
      this.ScreenModelScale = ScreenCoordinateHelper.GetScale(
        event,
        this.m_context.Dom.offsetHeight
      );
      this.m_sceneManger.SignModel.children.forEach((item) => {
        if (item.userData.scale)
          item.scale.set(
            this.ScreenModelScale * item.userData.scale.x,
            this.ScreenModelScale * item.userData.scale.y,
            this.ScreenModelScale * item.userData.scale.z
          );
        else
          item.scale.set(
            this.ScreenModelScale,
            this.ScreenModelScale,
            this.ScreenModelScale
          );
      });
      this.m_sceneManger.AuxModel.children.forEach((item) => {
        if (item.userData.scale)
          item.scale.set(
            this.ScreenModelScale * item.userData.scale.x,
            this.ScreenModelScale * item.userData.scale.y,
            this.ScreenModelScale * item.userData.scale.z
          );
      });
    });
    this.ScreenModelScale = ScreenCoordinateHelper.GetScale(
      this.m_camera as THREE.PerspectiveCamera,
      this.m_context.Dom.offsetHeight
    );
    this.m_renderTarget = new THREE.WebGLRenderTarget(
      this.m_context.Dom.clientWidth * this.m_renderer.getPixelRatio(),
      this.m_context.Dom.clientHeight * this.m_renderer.getPixelRatio()
    );
    this.m_meshDisableMaterial = new THREE.MeshBasicMaterial({
      color: new THREE.Color("grey"),
      side: THREE.DoubleSide,
    });
    this.m_lineDisableMaterial = new THREE.LineBasicMaterial({
      color: new THREE.Color("grey"),
    });
    this.m_hasPicked = false;
    this.m_hasMoved = false;
    this.m_controls.listenToKeyEvents(this.m_context.Dom);
    // this.dbxArr=[]
    this.bindEvent();
    this.initCss2dRenderer();
    this.resize(null);
  }

  public get Canvas() {
    return this.m_canvas;
  }
  public get Controls() {
    return this.m_controls;
  }
  public get Camera() {
    return this.m_camera;
  }
  public get CameraRay() {
    return this.m_context.SelectionManager.CameraRay;
  }
  public get Renderer() {
    return this.m_renderer;
  }
  /**
   * 绑定鼠标移动刷新时触发渲染
   */
  bindEvent() {
    this.m_renderer.domElement.addEventListener(
      "mousewheel",
      this.mousewheel.bind(this)
    );
    this.m_renderer.domElement.addEventListener(
      "pointerdown",
      this.mousedown.bind(this)
    );
    this.m_renderer.domElement.addEventListener(
      "pointermove",
      this.mousemove.bind(this)
    );
    this.m_renderer.domElement.addEventListener(
      "pointerup",
      this.mouseup.bind(this)
    );
    this.m_renderer.domElement.addEventListener(
      "resize",
      this.resize.bind(this)
    );
    // this.m_renderer.domElement.addEventListener( 'pointerdown', this.onPointerDown.bind(this) );
    // this.m_renderer.domElement.addEventListener( 'pointermove', this.onPointerMove.bind(this) );
    // this.m_renderer.domElement.addEventListener( 'pointerup', this.onPointerUp.bind(this) );
    window.addEventListener("resize", this.resize.bind(this), false);
  }

  public get EventManager() {
    return this.m_eventManager;
  }
  private mousedown(event: MouseEvent) {
    this.m_eventManager.dispatchEvent("pointerdown", event);
    this.Render();
  }

  private mouseup(event: MouseEvent) {
    this.m_eventManager.dispatchEvent("pointerup", event);
    this.Render();
  }

  public mousemove(event: MouseEvent) {
    // ;
    this.m_eventManager.dispatchEvent("pointermove", event);
    this.Render();
  }

  /**
   * 重置视图宽高比和尺寸，当父容器变化时候刷新宽高比和尺寸
   */
  public Resize() {
    // @ts-ignore
    this.m_camera.aspect =
      this.m_controls.domElement.offsetWidth /
      this.m_controls.domElement.offsetHeight;
    // @ts-ignore
    this.m_camera.updateProjectionMatrix();
    this.m_renderer.setSize(
      this.m_context.Dom.clientWidth,
      this.m_context.Dom.clientHeight
    );
    this.CSS2DRenderer.setSize(
      this.m_context.Dom.clientWidth,
      this.m_context.Dom.clientHeight
    );
    this.Render();
  }

  private resize(event: any) {
    this.Resize();
  }
  private mousewheel(event: any) {
    this.m_eventManager.dispatchEvent("mousewheel", event);
    this.Render();
  }

  public Render() {
    Mesh.prototype.onBeforeRender = MeshBefer;
    requestAnimationFrame(() => {
      // this.m_renderer.setRenderTarget(this.m_renderTarget);
      // this.m_renderer.render(this.m_scene, this.m_camera);
      // this.m_renderer.setRenderTarget(null);
      // this.m_renderer.clear();
      // this.m_renderer.autoClear =false;
      this.m_renderer.render(this.m_scene, this.m_camera);
      this.CSS2DRenderer.render(this.m_scene, this.m_camera);
      // this.m_renderer.render(this.m_effectScene, this.m_camera);
    });
  }

  public animate() {
    this.Render();

    //更新控制器
    requestAnimationFrame(this.animate.bind(this));
  }

  public AreaOfTriangle(
    p1: THREE.Vector3,
    p2: THREE.Vector3,
    p3: THREE.Vector3
  ) {
    let v1: THREE.Vector3 = new THREE.Vector3();
    let v2: THREE.Vector3 = new THREE.Vector3();
    v1 = p1.clone().sub(p2);
    v2 = p1.clone().sub(p3);
    let v3 = new THREE.Vector3();
    v3.crossVectors(v1, v2);
    let s = v3.length() / 2;
    return s;
  }

  public computeGeoArea(geo: THREE.ShapeGeometry): number {
    let area: number = 0;
    if (geo.index) {
      let indexAttribute = geo.index;
      let posAtt = geo.attributes.position;
      //@ts-ignore
      for (let i = 0; i < geo.index.count; i += 3) {
        let index1 = indexAttribute.getX(i);
        let index2 = indexAttribute.getX(i + 1);
        let index3 = indexAttribute.getX(i + 2);
        let p1 = new THREE.Vector3(
          posAtt.getX(index1),
          posAtt.getY(index1),
          posAtt.getZ(index1)
        );
        let p2 = new THREE.Vector3(
          posAtt.getX(index2),
          posAtt.getY(index2),
          posAtt.getZ(index2)
        );
        let p3 = new THREE.Vector3(
          posAtt.getX(index3),
          posAtt.getY(index3),
          posAtt.getZ(index3)
        );
        area += this.AreaOfTriangle(p1, p2, p3);
      }
    }
    return area;
  }

  public isDeleteHole(
    i: number,
    holes: any,
    box3_1: THREE.Box3,
    geo1: THREE.ShapeGeometry
  ): boolean {
    for (let j = 0; j < holes.length; j++) {
      if (i == j) {
        continue;
      } else {
        let arr2: THREE.Vector2[] = [];
        //@ts-ignore
        for (let n = 0; n < holes[j].length; n++) {
          //@ts-ignore
          arr2.push(new THREE.Vector2(holes[j][n].X, holes[j][n].Y));
        }
        if (arr2.length > 2) {
          let shape2 = new THREE.Shape(arr2);
          let geo2 = new THREE.ShapeGeometry(shape2);
          geo2.computeBoundingBox();
          let box3_2 = geo2.boundingBox;
          //@ts-ignore
          if (box3_1.intersectsBox(box3_2)) {
            //@ts-ignore
            let area1 = this.computeGeoArea(geo1);
            let area2 = this.computeGeoArea(geo2);
            if (area1 < area2) {
              //
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  /**
   * 添加多边形
   * @param polygons 多边形
   * @returns
   */
  public AddPolygon(
    polygons: {
      Id: string;
      Edges: { X: number; Y: number; Z: number }[];
      Holes: { X: number; Y: number; Z: number }[][];
    }[],
    material: THREE.Material | undefined,
    callback: (
      model: THREE.Mesh,
      polygon: {
        Id: string;
        Edges: { X: number; Y: number; Z: number }[];
        Holes: { X: number; Y: number; Z: number }[][];
      }
    ) => void
  ): string[] {
    //@ts-ignore
    let ids = [];
    // ;
    //   this.dbxArr=[]
    for (let i = 0; i < polygons.length; i++) {
      const polygon = polygons[i];
      let points: THREE.Vector2[] = [];
      if (!polygon.Edges || polygon.Edges.length == 0) continue;
      for (let j = 0; j < polygon.Edges.length; j++) {
        let p = polygon.Edges[j];
        points.push(new THREE.Vector2(p.X, p.Y));
      }

      let pGeo = new THREE.Shape(points);

      if (polygon.Holes && polygon.Holes.length >= 1) {
        //527280  527284   527268    && polygon.Id=='527284'

        // polygon.Holes=this.filterHoles(polygon.Holes)

        //console.log(polygon.Holes)

        for (let q = 0; q < polygon.Holes.length; q++) {
          let hole = polygon.Holes[q];
          if (hole.length == 0) {
            continue;
          }
          let holePath = new THREE.Path();
          let index = 0;
          //@ts-ignore
          hole.forEach((edge) => {
            if (index == 0) {
              holePath.moveTo(edge.X, edge.Y);
            } else {
              holePath.lineTo(edge.X, edge.Y);
            }
            index++;
          });
          holePath.lineTo(hole[0].X, hole[0].Y);
          pGeo.holes.push(holePath);
        }
      }
      if (material instanceof THREE.Material) material.side = THREE.DoubleSide;
      else
        material = new THREE.MeshPhongMaterial({
          color: 0x4addec,
          transparent: true,
          opacity: 0.41,
          flatShading: true,
        });
      let geometry = new THREE.ShapeGeometry(pGeo);
      // geometry.scale(0.9,0.9,0.9)
      let pMesh = new THREE.Mesh(geometry, material);
      let lineGeo = new THREE.BufferGeometry();
      lineGeo.setFromPoints(points);
      let LineSegments = new THREE.LineSegments(
        lineGeo,
        new THREE.LineBasicMaterial({ color: 0x4addec, linewidth: 2 })
      );
      pMesh.add(LineSegments);
      //@ts-ignore
      this.m_context.SceneManager.ThreeLayer.children.push(pMesh);
      if (callback) callback(pMesh, polygon);
      pMesh.userData.id = polygon.Id;
      //  pMesh.visible=false
      ids.push(polygon.Id);

      // this.dbxArr.push(pMesh)
    }
    this.Render();
    return ids;
  }

  /**
   * 添加标记点
   * @param points 标记点
   * @returns
   */
  public AddSignPoints(
    points: { x: number; y: number; z: number }[],
    iconPath: string | undefined = undefined
  ): string[] {
    //控制auxlayer层尺寸，和界面保持一致
    let size = ScreenCoordinateHelper.GetScale(
      this.Camera as THREE.PerspectiveCamera,
      this.m_context.Dom.offsetHeight
    );
    let posistion: number[] = [];
    points.forEach((item) => {
      posistion.push(item.x, item.y, item.z);
    });
    let geo = new THREE.BufferGeometry();
    geo.setAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(posistion), 3)
    );
    let material = undefined;
    if (iconPath) {
      let sprite = new THREE.TextureLoader().load(iconPath);
      material = new THREE.PointsMaterial({
        size: size,
        transparent: true,
        color: new THREE.Color(0xffffff),
        map: sprite,
      });
    } else {
      material = new THREE.PointsMaterial({ size: size });
    }
    let mesh = new THREE.Points(geo, material);
    this.m_context.SceneManager.ThreeLayer.children.push(mesh);
    this.Render();
    return [mesh.id.toString()];
  }

  /**
   *根据指定Id删除多边形
   */
  public DelPolygonById(...ids: string[]): void {
    ids.forEach((id) => {
      for (
        let i = 0;
        i < this.m_context.SceneManager.ThreeLayer.children.length;
        i++
      ) {
        if (
          this.m_context.SceneManager.ThreeLayer.children[i].userData.id == id
        ) {
          //@ts-ignore
          this.m_context.SceneManager.ThreeLayer.children.splice(i, 1);
        }
      }
    });
    this.m_context.SelectionManager.m_selection.clear();

    this.Render();
  }

  public HidePolygons(isVisible: boolean = false, ...ids: string[]): void {
    // debugger;
    let that = this;
    ids.forEach((id) => {
      for (
        let i = 0;
        i < this.m_context.SceneManager.ThreeLayer.children.length;
        i++
      ) {
        if (
          this.m_context.SceneManager.ThreeLayer.children[i].userData.id == id
        ) {
          //@ts-ignore
          this.m_context.SceneManager.ThreeLayer.children[i].traverse(function (
            obj
          ) {
            // if(obj instanceof THREE.Object3D){
            obj.visible = isVisible;
            // }
          });

          // console.log('nnnnnnnnnnnnnnnn')
          // console.log(this.m_context.SceneManager.ThreeLayer.children[i])
          // console.log('nnnnnnnnnnnnnnnn')
        }
      }
    });

    // for(let i=0;i<this.dbxArr.length;i++){
    //   this.dbxArr[i].visible=false
    // }

    this.Render();
  }

  public SetPolygonsColor(color: string = "0xffffff",...ids: string[]): THREE.Material[] {
    let that = this;
    let originMaterials:THREE.Material[]=[]
    ids.forEach((id) => {
      for (
        let i = 0;
        i < this.m_context.SceneManager.ThreeLayer.children.length;
        i++
      ) {
        if (
          this.m_context.SceneManager.ThreeLayer.children[i].userData.id == id
        ) {
          //@ts-ignore
          this.m_context.SceneManager.ThreeLayer.children[i].traverse(function (
            obj
          ) {
            if (obj instanceof THREE.Mesh && obj.material) {
              if(!originMaterials.length){
                originMaterials.push(obj.material.clone())
              }
              let material = obj.material.clone();
              material.color = new THREE.Color(color);
              obj.material = material;
            }
          });

          // console.log('nnnnnnnnnnnnnnnn')
          // console.log(this.m_context.SceneManager.ThreeLayer.children[i])
          // console.log('nnnnnnnnnnnnnnnn')
        }
      }
    });


    // for(let i=0;i<this.dbxArr.length;i++){
    //   this.dbxArr[i].visible=false
    // }

    this.Render();
    return originMaterials
  }

  /**
   *根据指定Id删除sign
   */
  public DelSignById(ids: string[]): void {
    ids.forEach((id) => {
      for (
        let i = 0;
        i < this.m_context.SceneManager.SignModel.children.length;
        i++
      ) {
        if (
          this.m_context.SceneManager.SignModel.children[i].userData.id == id
        ) {
          //@ts-ignore
          this.m_context.SceneManager.SignModel.children.splice(i, 1);
        }
      }
    });
    this.Render();
  }

  /**
   * 自动缩放到box，或者自动缩放到模型
   */
  public ZoomTo(box3: THREE.Box3 | THREE.Object3D) {
    //|THREE.Object3D
    return this.ZoomTobox(box3);
  }
  public ZoomTobox(box3: THREE.Box3 | THREE.Object3D) {
    //@ts-ignore
    //  this.m_scene.add(new THREE.Box3Helper(box3))

    let box = new THREE.Box3();
    if (box3 instanceof THREE.Object3D) {
      box.setFromObject(box3);
    } else {
      box = box3;
    }
    let boxObj = this.m_controls.ZoomToBox(box);
    return boxObj;
  }

  /**
   * 自动缩放
   */
  public ZoomToFit() {
    let box = this.m_sceneManger.GetBox();
    this.m_controls.ZoomToBox(box);
    this.Render();
  }

  public get Model() {
    return this.m_context.ModelManager;
  }
  /**
   *
   * @param visible 通过图层名称隐藏图层
   * @param layerNames
   */
  public SetVisibleLayerByNames(visible: boolean, ...layerNames: string[]) {
    this.SetVisibleLayerByIds(
      visible,
      ...this.Model.getLayerIdsByNames(...layerNames)
    );
    this.Render();
  }
  /**
   * 通过图层id隐藏图层
   */
  public SetVisibleLayerByIds(visible: boolean, ...ids: string[]) {
    ids.forEach((id) => {
      if (this.m_context.ModelManager.LayerTable[id]) {
        this.m_context.ModelManager.LayerTable[id].Visible = visible;
        this.m_context.ModelManager.LayerTable[id].Objects.forEach(
          (obj) => (obj.visible = visible)
        );
      }
    });
    this.Render();
  }
  /**
   *
   * @param enable 根据图层名称禁用模型
   * @param layerNames
   */
  public EnableLayerByNames(enable: boolean, ...layerNames: string[]) {
    this.EnableLayerByIds(
      enable,
      ...this.Model.getLayerIdsByNames(...layerNames)
    );
    this.Render();
  }

  /**
   * 所有图层禁用或启用
   * @param enable ture：启用图层， false：禁用图层
   */
  public EnableAllLayers(enable: boolean) {
    let ids = Object.keys(this.m_context.ModelManager.LayerTable);
    this.EnableLayerByIds(enable, ...ids);
  }
  public EnableThreeModel(enable: boolean) {
    this.m_sceneManger.ThreeLayer.userData.disEnable = !enable;
  }

  /**
   *
   * @param enable 根据图层id禁用图层
   * @param ids
   */
  public EnableLayerByIds(enable: boolean, ...ids: string[]) {
    ids.forEach((id) => {
      if (this.m_context.ModelManager.LayerTable[id]) {
        this.m_context.ModelManager.LayerTable[id].Enabled = enable;
        this.m_context.ModelManager.LayerTable[id].Objects.forEach((obj) => {
          if (enable) {
            if (
              obj instanceof THREE.Line ||
              obj instanceof THREE.LineSegments
            ) {
              if (obj.userData.enableColor) {
                obj.material = obj.userData.enableColor;
                delete obj.userData.enableColor;
              }
            }
            if (obj instanceof THREE.Mesh) {
              if (obj.userData.enableColor) {
                obj.material = obj.userData.enableColor;
                delete obj.userData.enableColor;
              }
            }
          } else {
            if (
              obj instanceof THREE.Line ||
              obj instanceof THREE.LineSegments
            ) {
              if (!obj.userData.enableColor) {
                obj.userData.enableColor = obj.material;
                obj.material = this.m_lineDisableMaterial;
              }
            }
            if (obj instanceof THREE.Mesh) {
              if (!obj.userData.enableColor) {
                obj.userData.enableColor = obj.material;
                obj.material = this.m_meshDisableMaterial;
              }
            }
          }
        });
      }
    });
    this.Render();
  }
  /**
   * 根据图层名称删除模型
   * @param layerNames
   */
  public DeleteLayerByNames(...layerNames: string[]) {
    this.DeleteLayerByIds(...this.Model.getLayerIdsByNames(...layerNames));
    this.Render();
  }

  /**
   * 根据图层id删除模型
   * @param layerNames
   */
  public DeleteLayerByIds(...layerIds: string[]) {
    this.Model.DeleteLayerByIds(...layerIds);
    this.Render();
  }
  /**
   * 根据图层id更新修改图层颜色
   * @param layerNames
   */
  public UpdateColorByLayerNames(color: THREE.Color, ...layerNames: string[]) {
    this.UpdateColorByLayerIds(
      color,
      ...this.Model.getLayerIdsByNames(...layerNames)
    );
    this.Render();
  }
  /**
   * 根据图层名称更新修改图层颜色
   * @param color 设置的颜色
   * @param layerIds 图层的id
   */
  public UpdateColorByLayerIds(color: THREE.Color, ...layerIds: string[]) {
    layerIds.forEach((id) => {
      this.Model.LayerTable[id].Objects.forEach((obj) => {
        if (obj instanceof THREE.Line || obj instanceof THREE.LineSegments) {
          obj.material =
            this.m_context.MaterialManager.createLineMaterial(color);
        }
        if (obj instanceof THREE.Mesh) {
          obj.material =
            this.m_context.MaterialManager.createMeshBasicMaterial(color);
        }
      });
    });
    this.Render();
  }
  /**
   * 根据图层名称恢复原始图层颜色
   * @param layerNames
   */
  public RecoverColorByLayerNames(...layerNames: string[]) {
    this.RecoverColorByLayerIds(
      ...this.Model.getLayerIdsByNames(...layerNames)
    );
    this.Render();
  }
  /**
   * 根据图层id恢复原始图层颜色
   * @param layerIds
   */
  public RecoverColorByLayerIds(...layerIds: string[]) {
    layerIds.forEach((id) => {
      this.Model.LayerTable[id].Objects.forEach((obj) => {
        if (
          obj instanceof THREE.Line ||
          obj instanceof THREE.Points ||
          obj instanceof THREE.Mesh
        ) {
          if (obj.userData.originalMaterial) {
            obj.material = obj.userData.originalMaterial;
          }
        }
      });
    });
    this.Render();
  }

  /**
   * 恢复所有图层的原始颜色
   */
  public RecoverAllLayerColor() {
    this.RecoverColorByLayerIds(
      ...Object.keys(this.m_context.ModelManager.LayerTable)
    );
  }

  /**
   *
   * @param obj 删除场景内模型, 目前仅three内置对象支持删除
   */
  public Delete(...obj: THREE.Object3D[]) {
    this.m_context.SceneManager.ThreeLayer.remove(...obj);
    this.m_context.SceneManager.SignModel.remove(...obj);
    this.m_context.SceneManager.AuxModel.remove(...obj);
    this.Render();
  }

  /**
   * 清除标记
   */
  public ClearSign() {
    this.m_context.SceneManager.SignModel.clear();
  }

  /**
   * @startPoint 鼠标点击起始屏幕坐标   @endPoint 鼠标点击结束屏幕坐标    @layerIds 过滤数组
   */
  public searchRectangleByIds(
    startPoint: THREE.Vector2,
    endPoint: THREE.Vector2,
    ...layerIds: string[]
  ): (DBEntity | THREE.Object3D)[] {
    let selectionBox = new SelectionBox(
      this.m_context.ViewManager.Camera,
      this.m_context.ModelManager,
      this.m_context.SceneManager.ThreeLayer,
      this.m_context.SceneManager.SignModel
    );
    let startStd = this.screenCoordToStd(startPoint);
    let endStd = this.screenCoordToStd(endPoint);
    selectionBox?.select(startStd, endStd);
    let arr: (DBEntity | THREE.Object3D)[] = [];
    if (selectionBox?.Collection) {
      let collection = selectionBox?.Collection;
      layerIds.forEach((id) => {
        for (let i = 0; i < collection.length; i++) {
          //@ts-ignore
          if (collection[i].layerId == id) {
            arr.push(collection[i]);
          }
        }
      });
    }
    return arr;
  }

  public searchRectangleByNames(
    startPoint: THREE.Vector2,
    endPoint: THREE.Vector2,
    ...names: string[]
  ): (DBEntity | THREE.Object3D)[] {
    return this.searchRectangleByIds(
      startPoint,
      endPoint,
      ...this.Model.getLayerIdsByNames(...names)
    );
  }

  /**
   * 添加标记
   * @param location 标记的位置
   * @param iconPath 标记的图片
   * @param sizeX 标记图片宽
   * @param sizeY 标记图片高
   */
  public addSign(
    location: THREE.Vector3,
    iconPath: string,
    sizeX: number,
    sizeY: number
  ) {
    let s = ScreenCoordinateHelper.GetScale(
      this.Camera as THREE.PerspectiveCamera,
      this.m_context.Dom.offsetHeight
    );
    let textureLoader = new THREE.TextureLoader();
    let spriteMaterial = new THREE.SpriteMaterial({
      map: textureLoader.load(iconPath),
    });
    let spriteObject = new THREE.Sprite(spriteMaterial);
    spriteObject.scale.set(sizeX * s, sizeY * s, 1);
    spriteObject.userData.scale = { x: sizeX, y: sizeY, z: 1 };
    spriteObject.position.copy(location);
    this.m_context.SceneManager.SignModel.add(spriteObject);
    return spriteObject;
  }

  public screenCoordToStd(screenCoord: THREE.Vector2): THREE.Vector3 {
    const x1 = (screenCoord.x / this.m_context.Dom.clientWidth) * 2 - 1;
    const y1 = -(screenCoord.y / this.m_context.Dom.clientHeight) * 2 + 1;
    let target = new THREE.Vector3();
    let ray = new THREE.Raycaster();
    ray.setFromCamera(new THREE.Vector2(x1, y1), this.Camera);
    ray.ray.intersectPlane(
      new THREE.Plane(new THREE.Vector3(0, 0, 1), 0),
      target
    );
    return target;
  }

  public EnableMask(box: THREE.Box3) {
    // this.m_renderer.getRenderTarget();
    // this.m_renderTarget.texture.addEventListener('')
    // this.m_effectScene.background = this.m_renderTarget.texture;
    box = box.expandByScalar(box.min.distanceTo(box.max));
    let boxMax = new THREE.Vector2(box.min.x, box.min.y); //box.min.x-300
    let boxMin = new THREE.Vector2(box.max.x, box.max.y); //box.max.x-300
    let pGeo = new THREE.Shape(BoxToPath(boxMin, boxMax));
    let geometry = new THREE.ShapeGeometry(pGeo);
    geometry.userData.boxMax = boxMax;
    geometry.userData.boxMin = boxMin;
    let material = new THREE.MeshBasicMaterial({
      color: 0x212121,
      colorWrite: true,
      transparent: true,
      opacity: 0.7,
    }); //0x212121      'red'
    let mesh = new Mesh(geometry, material);
    mesh.userData.isDisEnableRegion = true;
    mesh.userData.disenable = true;
    mesh.renderOrder = 100;
    this.m_sceneManger.MaskLayer.add(mesh);

    this.Render();
    return mesh;
  }

  public get Mask() {
    return this.m_sceneManger.MaskLayer;
  }

  public SetMaskOpacity(mask: THREE.Mesh, opacity: number) {
    (mask.material as THREE.MeshBasicMaterial).opacity = opacity;
  }

  public SetMaskHole(mask: THREE.Mesh, ...disboxs: THREE.Box3[]) {
    if (mask.userData && mask.userData.isDisEnableRegion) {
      if (
        mask.geometry.userData &&
        mask.geometry.userData.boxMax &&
        mask.geometry.userData.boxMin
      ) {
        let boxMax = mask.geometry.userData.boxMax;
        let boxMin = mask.geometry.userData.boxMin;
        let pGeo = new THREE.Shape(BoxToPath(boxMin, boxMax));
        disboxs.forEach((hole) => {
          let holePath = new THREE.Path();
          let ps = BoxToPath(hole.min, hole.max);
          holePath.moveTo(ps[3].x, ps[3].y);
          holePath.lineTo(ps[2].x, ps[2].y);
          holePath.lineTo(ps[1].x, ps[1].y);
          holePath.lineTo(ps[0].x, ps[0].y);
          holePath.lineTo(ps[3].x, ps[3].y);
          pGeo.holes.push(holePath);
        });
        mask.geometry.dispose();
        let geo = new ShapeBufferGeometry(pGeo);
        geo.userData.boxMax = mask.geometry.userData.boxMax;
        geo.userData.boxMin = mask.geometry.userData.boxMin;
        mask.geometry = geo;
        this.Render();
      } else {
        throw new Error(`${mask}disRegion不存在boxMax and boxMin`);
      }
    } else {
      throw new Error(`${mask}disRegion不是有效区域`);
    }
  }

  public initCss2dRenderer() {
    this.CSS2DRenderer = new CSS2DRenderer();
    this.CSS2DRenderer.setSize(
      this.m_context.Dom.clientWidth,
      this.m_context.Dom.clientHeight
    );
    let cssRender = document.createElement("div");
    cssRender.appendChild(this.CSS2DRenderer.domElement);
    cssRender.className = "cssRender";
    this.m_context.Dom.appendChild(cssRender);
  }
}

function MeshBefer(
  renderer: THREE.WebGLRenderer,
  scene: THREE.Scene,
  camera: THREE.Camera,
  geometry: THREE.BufferGeometry,
  material: THREE.Material,
  group: THREE.Group
) {
  //@ts-ignore
  if (this.name == "insert") {
    let s = "";
  }
}

export { ViewManager };
