import { IndserObject, IndsertPosition } from "../core/IndserObject";
import { DBEntity } from "../CadEngine";

interface DrawOperationMovePointBeforeParams{
    ray:THREE.Ray, array:IndserObject[], tol:number
}

interface DrawOperationMovePointParams{
    point:THREE.Vector3,geo:any, position:IndsertPosition
}

interface IDrawOperation{
   /**
    * 过滤相交的元素
    * @param array 
    */
    OnMovePointBefore(params:DrawOperationMovePointBeforeParams):void;
    OnMovePoint(point:DrawOperationMovePointParams):void;
    OnDownPoint(point:DrawOperationMovePointParams):void;
    onMouseMove(e:PointerEvent):void;
    onKeyDown(e:KeyboardEvent):void;
    OnZoom(camera:THREE.Camera):void;
    GetPoints():THREE.Vector3[];
    Finish(operate:DrawOperateState):DBEntity|DBEntity[]|undefined;
}

/**
 * 结束状态
 */
 enum DrawOperateState
 {
     complete,
     cancel,
 }

 export{ IDrawOperation, DrawOperateState, DrawOperationMovePointBeforeParams,DrawOperationMovePointParams}