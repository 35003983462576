import * as THREE from "three";
import { ViewManager } from "../CadEngine";

class UBase {
    protected m_root: HTMLDivElement
    protected m_panelDom:HTMLDivElement;
    private m_visibility:boolean= true;
    protected m_view: ViewManager;
    constructor(view:ViewManager, root: HTMLDivElement) {
        this.m_root = root
        this.m_panelDom = document.createElement('div');
        this.m_panelDom.classList.add('u3d-div')
        this.m_panelDom.style.position='absolute';
        root.appendChild(this.m_panelDom);
        this.m_view = view;
    }

    /**
     *
     * @param location
     */
    public SetLocation(location:THREE.Vector3, orientation:THREE.Vector3)
    {
        let vector = location.clone().project(this.m_view.Camera)
        var w = this.m_root.offsetWidth / 2;
        var h = this.m_root.offsetHeight / 2;
        var x = Math.round(vector.x *w + w);//标准设备坐标转屏幕坐标
        var y = Math.round(-vector.y * h + h);
        this.m_panelDom.style.left = `${x - this.m_panelDom.offsetWidth * 0.5 + this.m_root.offsetLeft}px`;
        this.m_panelDom.style.top = `${y - this.m_panelDom.offsetHeight * 0.5 + this.m_root.offsetTop}px`;
        let xAxis = new THREE.Vector3(), yAxis = new THREE.Vector3(),zAxis = new THREE.Vector3();
        this.m_view.Camera.matrixWorldInverse.extractBasis(xAxis, yAxis, zAxis);
        orientation.projectOnPlane(zAxis.clone());
        let angle = orientation.angleTo(xAxis);
      //  console.log(orientation,angle)
        if(xAxis.applyAxisAngle(zAxis, angle).angleTo(orientation) < 0.00001)
        {
            angle = Math.PI - angle;
        }
        angle = angle * 180 / Math.PI;
        if(angle > 90)
            angle = angle - 180;
        this.m_panelDom.style.transform=`rotate(${angle}deg)`
    }

    public set Visibility(visibility:boolean)
    {
        if(this.m_visibility!=visibility)
        {
            this.m_panelDom.style.display = visibility ? 'block':'none';
            this.m_visibility = visibility;
        }
    }
    public get Visibility()
    {
        return this.m_visibility;
    }

    dispose()
    {
        if(this.m_panelDom)
            this.m_root.removeChild(this.m_panelDom)
    }
}

export { UBase }