import { BaseManager } from "../base/BaseManager";
import { Context } from "../core/context";
import * as THREE from "three";
/**
 * 材质管理，主要用于加载过程中创建材质，材质合并，避免材质过多造成内存膨胀
 */
export class MaterialManager extends BaseManager{
    items:{[key:string]:THREE.Material}={};
    constructor(context:Context){
        super(context);
        this.items={};
    }
    has(key:string):boolean{
        return this.items.hasOwnProperty(key);
    }
    set(key:string,val:THREE.Material){
        // @ts-ignore
        this.items[key]=val;
    }
    delete(key:string):boolean{
        if(this.has(key)){
            // @ts-ignore
            delete this.items[key];
        }
        return false;
    }
    get(key:string):THREE.Material{
        // @ts-ignore
        return this.items[key];
    }
    values():THREE.Material[]{
        let values:THREE.Material[]=[];
        for(let k in this.items){
            if(this.has(k)){
                // @ts-ignore
                values.push(this.items[k]);
            }
        }
        return values;
    }

    createLineMaterial(color: THREE.Color, lineStyle?:string){
        let key = color.getHexString();
        if(!this.items[key]){
            this.items[key] = new THREE.LineBasicMaterial({color:color,side:THREE.DoubleSide});
        }
        return this.items[key];
    }
    createMeshBasicMaterial(color:THREE.Color){
        let key = color.getHexString();
        if(!this.items[key]){
            this.items[key] = new THREE.MeshBasicMaterial({color:color,side:THREE.DoubleSide});
        }
        return this.items[key];
    }

}