import * as THREE from "three";
import { DBEntity } from "./DBEntity";
/**
 * 存储的线
 */
export class DbLine extends DBEntity {
  constructor(params: {
    id: string;
    layerId: string;
    blockId: string;
    points: Float32Array;
    indexes: Int32Array;
    root_reference_id?: string;
    block_path?: Uint8Array[];
    root_block_id?: string;
  }) {
    super(params);
    this.points = params.points;
    this.indexes = params.indexes;

  }
  points: Float32Array;
  indexes: Int32Array;
  sphere: THREE.Sphere | undefined;
  box: THREE.Box3 | undefined;
  index?: number = 0;
  radiusCp?: number;



  /**
   * 获取连续线内的所有点
   */
  public get V3Points() {
    let points = [];
    for (let index = 0; index < this.points.length; index += 3) {
      points.push(
        new THREE.Vector3(
          this.points[index],
          this.points[index + 1],
          this.points[index + 2]
        )
      );
    }
    return points;
  }

  /**
   * 获取连续线内的子线段
   */
  public get Lines() {
    let points = this.V3Points;
    let lines: THREE.Line3[] = [];
    for (let index = 0; index < this.indexes.length; index += 2) {
      const startIndex = this.indexes[index];
      const endIndex = this.indexes[index + 1];
      lines.push(new THREE.Line3(points[startIndex], points[endIndex]));
    }
    return lines;
  }
  /**
   *
   * @param line 克隆方法，用于解决woker传递过来得对象没有属性了
   */
  static clone(line: DbLine): DbLine {
    let newLine: DbLine = new DbLine({
      id: line.id,
      blockId: line.blockId,
      indexes: line.indexes,
      layerId: line.layerId,
      points: line.points,
    });
    newLine.sphere = line.sphere;
    newLine.box = line.box;
    newLine.root_reference_id = line.root_reference_id
    newLine.block_path = line.block_path;
    newLine.root_block_id = line.root_block_id;
    return newLine;
  }
}
