import * as THREE from "three";
import { Camera } from "three";
import { EventManager } from "../manager/EventManager";
/**
 * 相机控制器，用于控制相机试图
 */
export class CameraControls extends EventManager {

    //@ts-ignore
    domElement: HTMLDivElement;
    /**
     * @param camera
     */
    private m_camera: THREE.Camera;
    private m_pressPoint: THREE.Vector3 = new THREE.Vector3();
    private m_tempPoint: THREE.Vector3 = new THREE.Vector3();
    /**
     * 相交射线
     */
    private raycaster: THREE.Raycaster = new THREE.Raycaster();
    /**
     * 相交面，由于计算相交点
     */
    public m_plane: THREE.Plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
    /**
     * 是否平移相机的标志位
     */
    private m_isPan: boolean = false;

    /**
     * 启用左键滑动
     */
    public MouseLeftPan:boolean = true;
    /**
     * 是否可以平移的标志位，该值由外部赋值，m_isPan m_enablePan同时为true的时候可以平移
     */
    private m_enablePan: boolean = true;
    /**
     * 开始平移时相机的位置
     */
    private m_pressCameraPosition: THREE.Vector3 = new THREE.Vector3();
    /**
     * 开始平移屏幕的坐标点
     */
    private m_pressScreenPoint: THREE.Vector2 = new THREE.Vector2();


    constructor(camera: Camera) {
        super();
        this.m_camera = camera;
    }

    public set EnablePan(pan: boolean) {
        this.m_enablePan = pan;
    }

    public get IsPan() {
        return this.m_isPan;
    }

    /**
     * 相交定位到box
     * @param box
     */
    ZoomToBox(box: THREE.Box3): THREE.Box3 {
        let center = new THREE.Vector3();
        box.getCenter(center);
        let size = new THREE.Vector3();
        box.getSize(size);
        this.m_camera.position.copy(new THREE.Vector3(center.x, center.y, size.length()));
        this.m_camera.lookAt(new THREE.Vector3(center.x, center.y, 0));
        this.dispatchEvent('zoom', this.m_camera)
        return box
    }

    listenToKeyEvents(domElement: HTMLDivElement) {
        this.domElement = domElement;
        domElement.addEventListener('pointerdown', this.onPointerDown.bind(this));
        domElement.addEventListener('pointermove', this.onPointerMove.bind(this));
        domElement.addEventListener('pointerup', this.onPointerUp.bind(this));
        domElement.addEventListener('mousewheel', this.onMouseWheel.bind(this));
        //禁用右键
        domElement.oncontextmenu = ()=>{ return false; }
    }

    getScreenCoordinates(offsetX: number, offsetY: number): { x: number, y: number } {
        let Sx = offsetX;
        let Sy = offsetY;
        let x = (Sx / this.domElement.clientWidth) * 2 - 1;
        let y = -(Sy / this.domElement.clientHeight) * 2 + 1;
        return { x, y };
    }

    onPointerUp(event: PointerEvent) {
        this.m_isPan = false;
    }

    onPointerMove(event: PointerEvent) {
        if (this.m_isPan && this.m_enablePan) {
            {
                let { x, y } = this.getScreenCoordinates(event.offsetX, event.offsetY);
                this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.m_camera);
                this.raycaster.ray.intersectPlane(this.m_plane, this.m_tempPoint);
            }
            {
                let { x, y } = this.getScreenCoordinates(this.m_pressScreenPoint.x, this.m_pressScreenPoint.y);
                this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.m_camera);
                this.raycaster.ray.intersectPlane(this.m_plane, this.m_pressPoint);
            }
            let offset = this.m_pressPoint.sub(this.m_tempPoint);
            this.m_camera.position.copy(this.m_pressCameraPosition.clone().add(offset));
            this.m_camera.lookAt(new THREE.Vector3(this.m_camera.position.x, this.m_camera.position.y, 0));
        }
    }
    onPointerDown(event: PointerEvent) {
        if ((event.button == 0 && this.MouseLeftPan) || event.button == 1|| event.button == 2) {
            this.m_pressScreenPoint = new THREE.Vector2(event.offsetX, event.offsetY);
            this.m_pressCameraPosition.copy(this.m_camera.position);
            this.m_isPan = true;
        }
    }

    public get DownPointXY()
    {
        return this.m_pressScreenPoint;
    }
    onMouseWheel(event: any) {
        if (this.m_isPan)
            return;
        let { x, y } = this.getScreenCoordinates(event.offsetX, event.offsetY);
        this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.m_camera);
        this.m_plane.set(new THREE.Vector3(0, 0, 1), 0);

        let target = new THREE.Vector3();
        this.raycaster.ray.intersectPlane(this.m_plane, target);
        let origin = this.m_camera.position.clone();
        let direction = target.clone().sub(origin).normalize();
        let distance = target.distanceTo(origin);
        let scale = 0.1;
        //前
        if (event.deltaY < 0) {
            let offset = direction.clone().multiplyScalar(distance * scale);
            this.m_camera.position.add(offset);
            this.m_camera.lookAt(new THREE.Vector3(this.m_camera.position.x, this.m_camera.position.y, 0));
        }
        //后
        if (event.deltaY > 0) {
            let offset = direction.clone().multiplyScalar(-distance * scale);
            this.m_camera.position.add(offset);
            this.m_camera.lookAt(new THREE.Vector3(this.m_camera.position.x, this.m_camera.position.y, 0));
        }
        this.dispatchEvent('zoom', this.m_camera)
    }
}