import * as THREE from "three";
import { DBEntity } from "./DBEntity";

/**
 * mesh的抽象实体
 */
export class DbMesh extends DBEntity {
    constructor(params: { id: string, layerId: string, blockId: string, vertexs: Float32Array, indexes: Int32Array ,    
        root_reference_id?: string;
        block_path?: Uint8Array[],
        root_block_id?: string}) {
        super(params);
        this.vertexs = params.vertexs;
        this.indexes = params.indexes;
    }
    vertexs: Float32Array;
    indexes: Int32Array;
    count: number = 0;
    sphere: THREE.Sphere | undefined;
    box: THREE.Box3 | undefined;
    index?: number;
    radiusCp?: number;

    /**
     * 
     * @param mesh 克隆方法，用于解决woker传递过来得对象没有属性了
     */
    static clone(mesh: DbMesh): DbMesh {
        let newMesh: DbMesh = new DbMesh({ id: mesh.id, blockId: mesh.blockId, indexes: mesh.indexes, layerId: mesh.layerId, vertexs: mesh.vertexs });
        newMesh.sphere = mesh.sphere;
        newMesh.box = mesh.box;
        newMesh.root_reference_id = mesh.root_reference_id
        newMesh.block_path = mesh.block_path;
        newMesh.root_block_id = mesh.root_block_id
        return newMesh;
    }
}