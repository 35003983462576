import { DbMesh } from "@/CadEngine";
import { IndserObject } from "../core/IndserObject";

export class BPMathUtils {
  public static max<T>(array: T[], getVaule: (item: T) => number) {
    let max: T;
    let value: number;
    if (array.length > 0) {
      array.forEach((item) => {
        let tempValue = getVaule(item);
        if (max) {
          if (tempValue > value) {
            max = item;
            value = tempValue;
          }
        } else {
          max = item;
          value = tempValue;
        }
      });
      //@ts-ignore
      return max;
    } else {
      return undefined;
    }
  }

  public static min<T>(array: T[], getVaule: (item: T) => number) {
    let min: T;
    let value: number;
    if (array.length > 0) {
      // array.forEach(item=>{
      //     let tempValue= getVaule(item)
      //     if(min)
      //     {
      //         if(tempValue < value )
      //         {
      //             min = item;
      //             value = tempValue;
      //         }
      //     }
      //     else{
      //         min = item;
      //         value = tempValue;
      //     }
      // })
      for (let i = 0; i < array.length; i++) {
        if (array[i] instanceof IndserObject) {
          //@ts-ignore
          let obj = array[i].obejct[0];
          if (obj instanceof DbMesh&&array.length>1) {
            continue;
          }
        }
        let tempValue = getVaule(array[i]);
        //@ts-ignore
        if (min) {
          //@ts-ignore
          if (tempValue < value) {
            min = array[i];
            value = tempValue;
          }
        } else {
          min = array[i];
          value = tempValue;
        }
      }

      //@ts-ignore
      return min;
    } else {
      return undefined;
    }
  }

  public static angleTo(
    v1: THREE.Vector3,
    v2: THREE.Vector3,
    axis: THREE.Vector3
  ): number {
    let angle = v1.angleTo(v2);
    return v1.clone().applyAxisAngle(axis, angle).angleTo(v2) < 0.000001
      ? angle
      : Math.PI * 2 - angle;
  }
}
