import { BaseEvent, EventDispatcher } from "three";
import { Context } from "../core/context";

class BaseManager{
    m_context: Context;
    constructor(context:Context){
        this.m_context = context;
    }
}

export {BaseManager}