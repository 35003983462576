import { LoadManager } from "../manager/LoaderManager";
import { IContextOptions } from "../config/IContextOptions";
import { ViewManager } from "../manager/ViewManager";
import { SceneManager } from "../manager/SceneManager";
import { ModelManager } from "../manager/ModelManager";
import {MaterialManager} from "../manager/MaterialManager";
import {SelectionManager} from "../manager/SelectionManager"
import { DrawManager } from "../manager/DrawManager";
/**
 * 上下文实例，当前工程内有且仅有一个Context，用于挂载各种管理器
 */
class Context{
    private m_loadManager: LoadManager;
    private m_viewManager: ViewManager;
    private m_dom: HTMLDivElement;
    private m_sceneManager: SceneManager;
    private m_modelManager: ModelManager;
    private m_materialManager: MaterialManager;
    private m_selectionManager:SelectionManager
    private  m_drawManager: DrawManager;

    constructor(options:IContextOptions){
        this.m_dom = options.div;
        this.m_sceneManager = new SceneManager(this);
        this.m_viewManager = new ViewManager(this);
        this.m_loadManager = new LoadManager(this);
        this.m_modelManager = new ModelManager(this);
        this.m_materialManager = new MaterialManager(this);
        this.m_selectionManager = new SelectionManager(this);
        this.m_drawManager = new DrawManager(this);
    }

    /**
     * 3d画布容器
     */
    public get Dom()
    {
        return this.m_dom;
    }
    /**
     * 加载器
     */
    public get LoadManager(){
        return this.m_loadManager;
    }
    /**
     * 场景管理
     */
    public get SceneManager(){
        return this.m_sceneManager;
    }

    /**
     * 视图管理
     */
    public get ViewManager(){
        return this.m_viewManager;
    }
    /**
     * 模型管理
     */
    public get ModelManager(){
        return this.m_modelManager;
    }
    /**
     * 材质管理
     */
    public get MaterialManager(){
        return this.m_materialManager;
    }
    /**
     * 拾取工具集
     */
    public get SelectionManager(){
        return this.m_selectionManager;
    }
    public get DrawManager(){
        return this.m_drawManager;
    }
}
export {Context}