import * as THREE from "three";
import { Vector3 } from "three";
import { ViewManager } from "../CadEngine";
import { UBase } from "./UBase";

export class UInput extends UBase{

    private m_dom: HTMLInputElement;

    constructor(view:ViewManager, root: HTMLDivElement)
    {
        super(view, root);
        this.m_dom = document.createElement('input');
        this.m_dom.setAttribute("type", "text");
        this.m_dom.style.width="100%";
        this.m_dom.style.height="100%";
        this.m_dom.style.fontSize="10px";
        this.m_dom.style.textAlign = "center";
        this.m_dom.style.border='1px solid #101010'
        this.m_dom.style.background='#303030'
        this.m_dom.style.color='#fff'
        this.m_panelDom.appendChild(this.m_dom);
        this.m_panelDom.style.pointerEvents='none';
    }
    public SetVaule(label:string,location: Vector3|undefined = undefined, orientation: Vector3|undefined = undefined, ): void
    {
        let width = label.length * 6 + 4;
        if(width <=40)
            width = 40;
        this.m_panelDom.style.width = `${width}px`;
        this.m_dom.value = label;
        if(location && orientation)
        this.SetLocation(location, orientation);
    }
}