import { BaseManager } from "../base/BaseManager";
import { Context } from "../core/context";
import { MLayer } from "../core/MLayer";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { EventManager } from "./EventManager";
import { EventListener } from "three";
import * as THREE from "three";
import { P2dLoader } from "../loader/P2dLoader";
/**
 * 加载器管理器，提供加载不同文件接口
 */
class LoadManager extends BaseManager {
  static loadFinish: string = "finish";
  static loadStart: string = "start";

  private m_modelLayer: MLayer;
  private m_threeLayer: MLayer;
  private m_eventManager: EventManager;
  LoadModel(model: IModelInfo) {
    switch (model.type) {
      case "gltf":
        this.LoadGLTF(model.url);
      case "p2d": {
        this.LoadP2d(model.url, model.canEdit);
      }
    }
  }
  LoadP2d(url: string, canEdit: boolean) {
    let loader = new P2dLoader();
    loader.load(url, canEdit, this.m_context.ModelManager, (isComplete) => {
      this.m_eventManager.dispatchEvent(
        LoadManager.loadFinish,
        {isComplete,modelManger:this.m_context.ModelManager}
      );
      this.m_context.ViewManager.Render();
    });
  }
  LoadBlockP2d(url: string, canEdit: boolean, callback?: () => void) {
    let loader = new P2dLoader();
    loader.load(url, canEdit, this.m_context.ModelManager, (isComplete) => {
      // this.m_eventManager.dispatchEvent(
      //   LoadManager.loadFinish,
      //   {isComplete,modelManger:this.m_context.ModelManager}
      // );
      this.m_context.ViewManager.Render();
      callback && callback();
    });
  }
  LoadGLTF(url: string) {
    let loader = new GLTFLoader();
    loader.load(url, (gltf) => {
      let matrix = new THREE.Matrix4();
      matrix.makeBasis(
        new THREE.Vector3(1, 0, 0),
        new THREE.Vector3(0, 0, 1),
        new THREE.Vector3(0, 1, 0)
      );
      matrix.scale(new THREE.Vector3(0.001, 0.001, 0.001));
      gltf.scene.applyMatrix4(matrix);
      this.m_threeLayer.add(gltf.scene);
      this.m_eventManager.dispatchEvent(LoadManager.loadFinish, gltf.scene);
    });
  }
  constructor(context: Context) {
    super(context);
    this.m_modelLayer = this.m_context.SceneManager.ModelLayer;
    this.m_threeLayer = this.m_context.SceneManager.ThreeLayer;
    this.m_eventManager = new EventManager();
  }

  public addEventListener(type: string, listener: Function) {
    this.m_eventManager.addEventListener(type, listener);
  }

  public removeEventListener(type: string, listener: Function) {
    this.m_eventManager.removeEventListener(type, listener);
  }
}

export { LoadManager };
