import { Vector3 } from "three";

/**
 * 直线
 */
export class BPLinear {

  public Origin: THREE.Vector3;
  public Direction: THREE.Vector3;
  constructor(origin: THREE.Vector3, direction: THREE.Vector3) {
    this.Origin = origin;
    this.Direction = direction;
  }

  /**
   * 射线到直线的距离
   * @param rayOrgin 
   * @param rayDirection 
   * @param orgin 
   * @param direction 
   */
  public distanceSqToRay(ray:THREE.Ray,target:THREE.Vector3|undefined=undefined)
  {
    let dis = ray.origin.clone().sub(this.Origin);
    let col = this.Direction.clone().multiplyScalar(dis.dot(this.Direction));
    let project = this.Origin.clone().add(col);
    if(target)
      target.copy(project);
    return ray.distanceSqToPoint(project);
  }

  public distanceSqPoint(point: Vector3,target:THREE.Vector3|undefined = undefined) {
    let dis = point.clone().sub(this.Origin);  // this.Origin 射线的起始点，point射线的结束点，即与线段的交点
    let col = this.Direction.clone().multiplyScalar(dis.dot(this.Direction)); // 线段的方向
    let project = this.Origin.clone().add(col);  // 射线的起点到线段垂直方向的交点
    if(target)
      target.copy(project);
    return project.distanceToSquared(point);
  }
}
