import * as THREE from "three";
import { BaseManager } from "../base/BaseManager";
import { Context } from "../core/context";
import { DbLayer } from "../core/DbLayer";
import { Block, BlockReference, Line, Mesh } from "../vendor/protobuf/packet2d";
import { MLayer } from "../core/MLayer";
import { DBEntity } from "../core/DBEntity";
import {
  AddLineSegmentsIndexs,
  FreeThreeObject,
  GetInsertLines,
  GetInsertMeshs,
} from "../utils/GeometryUtils";
import { DbLine } from "../core/DbLine";
import { DbMesh } from "../core/DbMesh";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import { IndserObject } from "../core/IndserObject";
import { DbBlockRecord } from "../core/DbBlockRecord";
import { DbBlockInstance } from "../core/DbBlockInstance";
import { InstancedMesh } from "three";
import { generateUUID } from "three/src/math/MathUtils";

interface EntityClass {
  line: DbLine;
  mesh: DbMesh;
}

/**
 * 模型管理
 */
export class ModelManager extends BaseManager {
  /**
   * 线集
   */
  private m_lineGeos: {
    [key: string]: {
      lines: DbLine[];
      model: THREE.LineSegments;
      canEdit: boolean;
    };
  } = {};
  /***
   * mesh集
   */
  private m_meshGeos: {
    [key: string]: { meshs: DbMesh[]; canEdit: boolean; model: THREE.Mesh };
  } = {};

  private m_references: {
    [key: string]: { key: string; lines: DbLine[]; mesh: DbMesh[] };
  } = {};
  /***
   * 块表，所谓类型存储， key为id，对应到m_instances的blockRecord
   */
  private m_blocks: { [key: string]: DbBlockRecord } = {};
  /**
   * 块表在场景中对应的实例
   */
  private m_instances: { [key: string]: DbBlockInstance[] } = {};
  private m_lineGeoCount: number = 0;
  private m_meshGeoCount: number = 0;

  public getInstancesMatrix(id: string) {
    for (let items of Object.values(this.m_instances)) {
      for (let item of items) {
        if (item.id == id) return item;
      }
    }
    let s = "时代的";
    s.charCodeAt(0) > 122;
  }
  /**
   * 模型挂载根节点
   */
  private m_model: MLayer;
  private m_layers: { [key: string]: DbLayer };

  public isInsert: boolean = false;
  private insertGroup: THREE.Group = new THREE.Group();
  public insertGeos: {
    lines: {
      [key: string]: {
        lines: DbLine[];
        model: THREE.LineSegments;
        canEdit: boolean;
      };
    };
    meshs: {
      [key: string]: { meshs: DbMesh[]; canEdit: boolean; model: THREE.Mesh };
    };
  } = { lines: {}, meshs: {} };

  public get Group() {
    return this.m_references;
  }
  constructor(context: Context) {
    super(context);
    this.m_layers = {};
    this.m_model = this.m_context.SceneManager.ModelLayer;
    this.addInsertGroup();
  }
  public addInsertGroup() {
    this.insertGroup = new THREE.Group();
    this.insertGroup.name = "insertGroup";
    this.insertGroup.visible = false;
    this.m_model.add(this.insertGroup);
  }

  public traverse(callback: (object: THREE.Object3D) => any) {
    this.m_model.traverse(callback);
    document.addEventListener;
  }

  public getLayerIdsByNames(...layerNames: string[]): string[] {
    let ids = [];
    for (let key in this.m_context.ModelManager.LayerTable) {
      let item = this.m_context.ModelManager.LayerTable[key];
      if (layerNames.includes(item.Name)) ids.push(key);
    }
    return ids;
  }

  public GetLayerByName(layerName: string) {
    for (let key in this.m_context.ModelManager.LayerTable) {
      let item = this.m_context.ModelManager.LayerTable[key];
      if (item.Name == layerName) return item;
    }
    return undefined;
  }

  public get LayerTable() {
    return this.m_layers;
  }
  public get Layers() {
    let layers = [];
    for (const key in this.m_layers) {
      layers.push(this.m_layers[key]);
    }
    return layers;
  }
  public get Lines() {
    return this.m_lineGeos;
  }

  public get Meshs() {
    return this.m_meshGeos;
  }

  public get Model() {
    return this.m_model;
  }
  // 判断字符串是否含有乱码
  // private isGarbledCode(str: string) {
  //   if (!str) return;
  //   let isGarbled = false;
  //   for (let index = 0; index < str.length; index++) {
  //     const charCode = str.charCodeAt(index);
  //     if (charCode > 256) {
  //       isGarbled = true;
  //       break;
  //     }
  //   }
  //   if (!isGarbled) return str;
  //   let result = "";
  //   for (let index = 0; index < str.length; index++) {
  //     const charCode = str.charCodeAt(index);
  //     result += charCode.toString(16);
  //   }
  //   return result;
  // }

  addlines(lines: { [key: string]: DbLine[] }, canEdit: boolean) {
    //
    for (const key in lines) {
      let items = lines[key];
      let array: DbLine[] = [];
      if (items) {
        items.forEach((item) => {
          // let str = item.root_block_id!;
          // item.root_block_id=  this.isGarbledCode(str)
          let line = DbLine.clone(item);
          if (line.root_reference_id) {
            if (!this.m_references[line.root_reference_id])
              this.m_references[line.root_reference_id] = {
                key: key,
                lines: [],
                mesh: [],
              };
            this.m_references[line.root_reference_id].lines.push(line);
          }
          array.push(line);
        });
        let ps = key.split("_");
        let model = this.addLinesToModel(ps[0], parseInt(ps[1]), array);
        if (this.isInsert) {
          if (this.insertGeos.lines[key]) {
            this.insertGeos.lines[key].lines.push(...array);
          } else {
            this.insertGeos.lines[key] = {
              lines: array,
              model: model,
              canEdit: canEdit,
            };
          }
          this.insertGeos.lines[key].model = model;
        } else {
          if (this.m_lineGeos[key]) {
            this.m_lineGeos[key].lines.push(...array);
          } else {
            this.m_lineGeos[key] = {
              lines: array,
              model: model,
              canEdit: canEdit,
            };
          }
          this.m_lineGeos[key].model = model;
        }
      }
    }
    this.m_context.ViewManager.Render();
  }

  addMeshs(meshs: { [key: string]: DbMesh[] }, canEdit: boolean) {
    for (const key in meshs) {
      let items = meshs[key];
      let array: DbMesh[] = [];
      items.forEach((item) => {
        // let str = item.root_block_id!;
        // item.root_block_id=  this.isGarbledCode(str)
        let mesh = DbMesh.clone(item);
        if (mesh.root_reference_id) {
          if (!this.m_references[mesh.root_reference_id])
            this.m_references[mesh.root_reference_id] = {
              key: key,
              lines: [],
              mesh: [],
            };
          this.m_references[mesh.root_reference_id].mesh.push(mesh);
        }
        array.push(mesh);
      });
      let ps = key.split("_");
      let model = this.addMeshToModel(ps[0], parseInt(ps[1]), array);

      if (this.isInsert) {
        if (this.insertGeos.meshs[key]) {
          this.insertGeos.meshs[key].meshs.push(...array);
        } else {
          this.insertGeos.meshs[key] = {
            meshs: array,
            model: model,
            canEdit: canEdit,
          };
        }
        this.insertGeos.meshs[key].model = model;
      } else {
        if (this.m_meshGeos[key]) {
          this.m_meshGeos[key].meshs.push(...array);
        } else {
          this.m_meshGeos[key] = {
            meshs: array,
            canEdit: canEdit,
            model: model,
          };
        }
        this.m_meshGeos[key].model = model;

        delete meshs[key];
      }
    }
    this.m_context.ViewManager.Render();
  }

  addLayers(layers: DbLayer[]) {
    layers.forEach((item) => {
      let temp = this.m_layers[item.Id];
      if (!temp) this.m_layers[item.Id] = item;
    });
  }

  addInstances(instances: { [key: string]: DbBlockInstance[] }) {
    let keys = Object.keys(this.m_instances);
    for (const key in instances) {
      // 如果已经存在，代表是同一个块，是新插入的实例，需要加标识，并合并
      if (keys.includes(key)) {
        if (this.isInsert) {
          instances[key].forEach((instance) => {
            instance.type = "insert";
          });
        }
        this.m_instances[key].push(...instances[key]);
        continue;
      } else {
        let items = instances[key];
        this.m_instances[key] = items;
      }
      // let items = instances[key];
      // this.m_instances[key]=items
    }
  }

  setInstances(rootId: string, position: THREE.Vector3) {
    let keys = Object.keys(this.m_instances);
    let ids: string[] = [];
    if (keys.length) {
      for (const key in this.m_instances) {
        let arr = this.m_instances[key].map((instance) => {
          return instance.id;
        });
        ids.push(...arr);
      }
      for (const key in this.m_instances) {
        this.m_instances[key].forEach((instance) => {
          if (!ids.includes(rootId)) {
            ids.push(rootId);
            let matrix4 = new THREE.Matrix4();
            matrix4.setPosition(position);
            let instance = new DbBlockInstance({
              id: rootId,
              layerId: "",
              blockId: "",
              matrix: matrix4,
            });
            instance.type = "insertEnd";
            this.m_instances[generateUUID()] = [instance];
          } else {
            if (instance.type == "insert") {
              instance.type = "insertEnd";
              instance.id = rootId;
              let matrix4 = new THREE.Matrix4();
              matrix4.setPosition(position);
              instance.matrix.elements = matrix4.elements;
            } else if (instance.type == "insertEnd" || instance.type == "") {
              if (instance.id == rootId) {
                instance.matrix.elements[12] = position.x;
                instance.matrix.elements[13] = position.y;
                instance.matrix.elements[14] = position.z;
              }
            }
          }
        });
      }
    } else {
      let matrix4 = new THREE.Matrix4();
      matrix4.setPosition(position);
      let instance = new DbBlockInstance({
        id: rootId,
        layerId: "",
        blockId: "",
        matrix: matrix4,
      });
      instance.type = "insertEnd";
      this.m_instances[generateUUID()] = [instance];
    }
  }

  addBlocks(blocks: { [key: string]: DbBlockRecord }) {
    for (const key in blocks) {
      let items = blocks[key];
      this.m_blocks[key] = items;
    }
  }

  addLayer(layer: DbLayer) {
    this.m_layers[layer.Id] = layer;
  }

  addMeshToModel(layerId: string, color: number, meshs: DbMesh[]) {
    let material = this.m_context.MaterialManager.createMeshBasicMaterial(
      new THREE.Color(color)
    );
    material.side = THREE.DoubleSide;
    let posistion: number[] = [];
    meshs.forEach((geo) => {
      geo.vertexs?.forEach((v) => {
        posistion.push(v);
      });
      //关联相关同id的模型
      // let lines = this.m_lineGeos[key]
      // if (lines) {
      //     lines.forEach(line => {
      //         if (geo.id == line.id) {
      //             if (geo.unions == undefined) geo.unions = []
      //             geo.unions?.push(line)
      //             if (line.unions == undefined) line.unions = []
      //             line.unions?.push(geo)
      //         }
      //     })
      // }
    });
    let geometry = new THREE.BufferGeometry();
    let vertexs = new Float32Array(posistion);
    geometry.setAttribute("position", new THREE.BufferAttribute(vertexs, 3));
    let mesh = new THREE.Mesh(geometry, material);
    mesh.userData.layerId = layerId;
    mesh.userData.originalMaterial = mesh.material;
    mesh.renderOrder = -1;
    if (this.m_layers[layerId]) this.m_layers[layerId].Objects.push(mesh);
    this.isInsert ? this.insertGroup.add(mesh) : this.m_model.add(mesh);
    return mesh;
  }

  getBlock(root_block_id: string) {
    return this.m_blocks[root_block_id];
  }

  getLinesGeometry(lines: DbLine[]) {
    let position: number[] = [];
    let index = 0;
    let indexes: number[] = [];
    lines.forEach((item) => {
      item.points.forEach((p) => {
        position.push(p);
      });
      item.indexes?.forEach((v) => {
        indexes.push(v + index);
      });
      index += item.points.length / 3;
    });
    return { index: indexes, position: position };
  }

  addLinesToModel(layerId: string, color: number, lines: DbLine[]) {
    //   ;
    let material = this.m_context.MaterialManager.createLineMaterial(
      new THREE.Color(color)
    );
    let lineGeo = new THREE.BufferGeometry();
    let posistion: number[] = [];
    let index = 0;
    let indexs: number[] = [];
    lines.forEach((item) => {
      item.points.forEach((p) => {
        posistion.push(p);
      });
      item.indexes?.forEach((v) => {
        indexs.push(v + index);
      });
      index += item.points.length / 3;
    });

    let posistionArray = new Float32Array(posistion);
    lineGeo.setAttribute(
      "position",
      new THREE.BufferAttribute(posistionArray, 3)
    );
    lineGeo.setIndex(indexs);
    let line = new THREE.LineSegments(lineGeo, material);
    line.renderOrder = -this.m_meshGeoCount;
    line.userData.layerId = layerId;
    line.userData.originalMaterial = line.material;
    if (this.m_layers[layerId]) this.m_layers[layerId].Objects.push(line);
    this.isInsert ? this.insertGroup.add(line) : this.m_model.add(line);
    return line;
  }

  OnComplete() {
    for (let key in this.m_blocks) {
      let block = this.m_blocks[key];
      if (block.instances.length <= 0) continue;
      // let lineGeo = new THREE.BufferGeometry();
      // let posistion: number[] = [];
      // let index = 0;
      // let indexs: number[] = [];
      // block.lines?.array.forEach((item) => {
      //   posistion.push(...(item.points || []));
      //   item.indexes?.forEach((v) => {
      //     indexs.push(v + index);
      //   });
      //   index += item.points.length / 3;
      // });
      // lineGeo.setAttribute(
      //   "position",
      //   new THREE.BufferAttribute(new Float32Array(posistion), 3)
      // );
      // lineGeo.setIndex(indexs);
      // let lineMesh = new InstancedMesh(
      //   lineGeo,
      //   undefined,
      //   block.instances.length
      // );
      // index = 0;
      block?.instances?.forEach((instance) => {
        let color = this.m_layers[instance.layerId]?.Color;
        // lineMesh.setColorAt(index, new THREE.Color(color));

        let matrix4 = new THREE.Matrix4();
        instance.matrix.elements.forEach((v, i) => {
          matrix4.elements[i] = v;
        });
        // lineMesh.setMatrixAt(index, matrix4);
        // index++;
        // instance.line = lineMesh;
        // this.m_layers[instance.layerId];
        // this.m_layers[instance.layerId].Objects.push(lineMesh);
      });
      // this.isInsert
      //   ? this.insertGroup.add(lineMesh)
      //   : this.m_model.add(lineMesh);
      // posistion.length = 0;
      // block.meshs?.array.forEach((geo) => {
      //   geo.vertexs?.forEach((v) => {
      //     posistion.push(v);
      //   });
      // });
      // let geometry = new THREE.BufferGeometry();
      // geometry.setAttribute(
      //   "position",
      //   new THREE.BufferAttribute(new Float32Array(posistion), 3)
      // );
      // let mesh = new InstancedMesh(geometry, undefined, block.instances.length);
      // index = 0;
      block.instances.forEach((instance) => {
        // mesh.setColorAt(index, new THREE.Color(0xff00ff));
        let matrix4 = new THREE.Matrix4();
        instance.matrix.elements.forEach((v, i) => {
          matrix4.elements[i] = v;
        });
        // mesh.setMatrixAt(index, matrix4);
        // instance.Mesh = mesh;
        // if (this.m_layers[instance.layerId])
        //   this.m_layers[instance.layerId].Objects.push(mesh);

        // index++;
      });
    }
  }

  /**
   * 返回当前模型内的Block
   */
  public GetBlocks(): DBEntity[] {
    return [];
  }

  /**
   * 根据id获取元素
   * @param id 模型id
   */
  public GetDBEntityById(id: string): DBEntity | undefined {
    for (let key in this.m_lineGeos) {
      let items = this.m_lineGeos[key];
      for (let line of items.lines) {
        if ((line.id = id)) return line;
      }
    }
    for (let key in this.m_meshGeos) {
      let items = this.m_meshGeos[key];
      for (let mesh of items.meshs) {
        if ((mesh.id = id)) return mesh;
      }
    }

    return undefined;
  }

  /**
   *
   * @param ray 获取相交的元素
   * @param mindis 误差距离
   * @param callback 相交回调
   */
  GetInsertObject(
    ray: THREE.Ray,
    minDis: number,
    callback: (param: IndserObject) => boolean
  ) {
    let param;

    let isContinue = true;
    let isFirst = false;
    for (let key in this.m_lineGeos) {
      let items = this.m_lineGeos[key];
      let layerId = key.split("_")[0];
      if (
        this.LayerTable[layerId].Visible &&
        this.LayerTable[layerId].Enabled
      ) {
        GetInsertLines(items.lines, ray, minDis, (outParam) => {
          param = outParam;
          param.code = key;
          isContinue = callback(param);
          return isContinue;
        });
        if (param && !isContinue) return;
      }
    }

    for (let key in this.m_meshGeos) {
      let items = this.m_meshGeos[key];
      let layerId = key.split("_")[0];
      if (
        this.LayerTable[layerId].Visible &&
        this.LayerTable[layerId].Enabled
      ) {
        GetInsertMeshs(items.meshs, ray, minDis, (outParam) => {
          param = outParam;
          param.code = key;
          isContinue = callback(param);
          return isContinue;
        });
        if (!isContinue && param) return;
      }
    }
  }

  public get Instances() {
    return this.m_instances;
  }

  DeleteLayerByIds(...ids: string[]) {
    ids.forEach((id) => {
      this.LayerTable[id].Objects.forEach((obj) => {
        this.m_model.remove(obj);
        FreeThreeObject(obj);
      });
      delete this.LayerTable[id];

      for (let key in this.m_meshGeos) {
        let layerId = key.split("_")[0];
        if (id == layerId) {
          delete this.m_meshGeos[key];
        }
      }

      for (let key in this.m_lineGeos) {
        let layerId = key.split("_")[0];
        if (id == layerId) {
          delete this.m_lineGeos[key];
        }
      }

      for (let key in this.m_blocks) {
        let block = this.m_blocks[key];
        for (let key2 in block.lines) {
          let layerId = key2.split("_")[0];
          if (id == layerId) {
            delete block.lines[key2];
          }
        }
        for (let key2 in block.meshs) {
          let layerId = key2.split("_")[0];
          if (id == layerId) {
            delete block.meshs[key2];
          }
        }
        for (let j = 0; j < block.instances.length; j++) {
          if (block.instances[j].layerId == id) {
            block.instances.splice(j, 1);
          }
        }
      }
    });
    this.m_context.SelectionManager.m_selection.clear();
  }

  DeleteEntity(...entities: DBEntity[]) {
    let deletes: { lines: DbLine[]; mesh: DbMesh[] } = { lines: [], mesh: [] };
    entities.forEach((item) => {
      if (item.root_reference_id) {
        let refs = this.m_references[item.root_reference_id];
        if (refs) {
          refs.lines.forEach((line) => {
            deletes.lines.push(line);
          });
          refs.mesh.forEach((mesh) => {
            deletes.mesh.push(mesh);
          });
        }
      } else {
        if (item instanceof DbLine) {
          deletes.lines.push(item);
        }
        if (item instanceof DbMesh) {
          deletes.mesh.push(item);
        }
      }
    });

    let keys: Set<string> = new Set<string>();
    deletes.lines.forEach((item) => {
      let lineGeo, color, keyValue;
      for (let key in this.m_lineGeos) {
        let lineGeo = this.m_lineGeos[key];
        let layerId = key.split("_")[0];
        if (
          lineGeo.canEdit &&
          item.layerId == layerId &&
          this.m_lineGeos[key].lines.indexOf(item) > -1
        ) {
          lineGeo.lines.splice(this.m_lineGeos[key].lines.indexOf(item), 1);
          color = key.split("_")[1];
          keyValue = key;
          keys.add(keyValue);
        }
      }
    });
    keys.forEach((key) => {
      let value = this.m_lineGeos[key];
      let lines = this.m_lineGeos[key].lines;
      let model = this.m_lineGeos[key].model;
      if (lines.length == 0) {
        let layer = this.LayerTable[key.split("_")[0]];
        if (layer) {
          let index = layer.Objects.indexOf(model);
          if (index > 0) {
            layer.Objects.splice(index, 1);
          }
        }
        this.m_model.remove(model);
        model.geometry.dispose();
        delete this.m_lineGeos[key];
      } else {
        let geo = this.getLinesGeometry(lines);
        model.geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(new Float32Array(geo.position), 3)
        );
        model.geometry.setIndex(geo.index);
      }
    });
    keys.clear();
    deletes.mesh.forEach((item) => {
      let meshGeo, color, keyValue;
      for (let key in this.m_meshGeos) {
        let layerId = key.split("_")[0];
        meshGeo = this.m_meshGeos[key];
        if (
          meshGeo.canEdit &&
          item.layerId == layerId &&
          meshGeo.meshs.indexOf(item) > -1
        ) {
          meshGeo.meshs.splice(this.m_meshGeos[key].meshs.indexOf(item), 1);
          color = key.split("_")[1];
          keyValue = key;
          keys.add(keyValue);
        }
      }
    });
    keys.forEach((key) => {
      let value = this.m_meshGeos[key];
      if (!value.canEdit) return;
      let mesh = this.m_meshGeos[key].meshs;
      let model = this.m_meshGeos[key].model;
      if (mesh.length == 0) {
        let layer = this.LayerTable[key.split("_")[0]];
        if (layer) {
          let index = layer.Objects.indexOf(model);
          if (index > 0) {
            layer.Objects.splice(index, 1);
          }
        }
        this.m_model.remove(model);
        model.geometry.dispose();
        delete this.m_meshGeos[key];
      } else {
        let position: number[] = [];
        mesh.forEach((item) => {
          item.vertexs?.forEach((p) => {
            position.push(p);
          });
        });
        model.geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(new Float32Array(position), 3)
        );
      }
    });
    this.m_context.ViewManager.Render();
  }

  // 计算数组中元素的个数
  computeArrItemCount(arr: any[]) {
    return arr.reduce((obj, cur) => {
      if (cur in obj) {
        obj[cur]++;
      } else {
        obj[cur] = 1;
      }
      return obj;
    }, {});
  }
  private layerNameCounts: { [key: string]: number } = {};
  /**
   *
   * @param layerName 图层名称
   * @param points 点集
   */
  AddCustomLines(layerName: string, points: THREE.Vector3[]) {
    let layerNames = this.Layers.map((layer: DbLayer) => {
      return layer.Name;
    });
    this.layerNameCounts = this.computeArrItemCount(layerNames);
    let count = 0;
    if (layerNames.includes(layerName)) {
      this.Layers.forEach((layer: DbLayer) => {
        if (layer.Name === layerName && count < 1) {
          if (this.layerNameCounts[layerName] > 1) {
            count++;
          }
          let key = `${layer.Id}_${layer.Color}`;
          let lines: DbLine[] = this.m_lineGeos[key]?.lines ?? [];
          let posistion: number[] = [];
          let index = 0;
          let indexs: number[] = [];
          points.forEach((p) => {
            posistion.push(p.x, p.y, p.z);
            indexs.push(index);
            if (index != 0 && index != points.length - 1) indexs.push(index);
            index++;
          });
          let line = new DbLine({
            id: generateUUID(),
            layerId: layer.Id,
            blockId: "",
            points: new Float32Array(posistion),
            indexes: new Int32Array(indexs),
          });
          let sphere = new THREE.Sphere();
          let box = new THREE.Box3();
          box.setFromArray(line.points);
          box.getBoundingSphere(sphere);
          line.sphere = sphere;
          line.box = box;
          lines.push(line);
          if (layer.Color) {
            if (this.m_lineGeos[key]) {
              this.m_lineGeos[key].canEdit = true;
              this.m_lineGeos[key].lines = lines;
              this.m_lineGeos[key].model = this.addLinesToModel(
                layer.Id,
                layer.Color,
                lines
              );
            } else {
              this.m_lineGeos[key] = {
                canEdit: true,
                lines: lines,
                model: this.addLinesToModel(layer.Id, layer.Color, lines),
              };
            }
          }
        }
      });
    } else {
      throw new Error("没有找到layName");
    }
  }

  /**
   *
   * @param layerName 图层名称
   * @param points 点集
   */
  CreateCircle(
    layerName: string,
    circleData: { radius: number; list: { x: number; y: number; z: number }[] }
  ) {
    let list = circleData.list;
    let radius = circleData.radius;
    for (let i = 0; i < list.length; i++) {
      let circlePoints: THREE.Vector3[] = [];
      for (let j = 0; j <= 36; j++) {
        let points = new THREE.Vector3(
          list[i].x + radius * Math.cos(((Math.PI * 2) / 36) * j),
          list[i].y + radius * Math.sin(((Math.PI * 2) / 36) * j),
          0
        );
        circlePoints.push(points);
      }
      this.AddCustomLines(layerName, circlePoints);

      let count = 0;
      this.Layers.forEach((layer: DbLayer) => {
        if (layer.Name === layerName && count < 1) {
          if (this.layerNameCounts[layerName] > 1) {
            count++;
          }
          let key = `${layer.Id}_${layer.Color}`;
          let lines: DbLine[] = this.m_lineGeos[key]?.lines ?? [];
          //创建圆所添加的DbLine的block_path要保持唯一性
          lines[lines.length - 1].block_path = generateUUID();
          //创建圆的buffGeometry要唯一，否则会出现多个圆，最后一个会合并之前的，所以要删除之前的
          if (i !== list.length - 1) {
            let lastObj = this.m_layers[layer.Id].Objects.pop();
            this.m_model.remove(lastObj!);
          }
        }
      });
    }
  }

  public get Box() {
    let box = new THREE.Box3();
    box.setFromObject(this.m_model);
    return box;
  }
  public Clear() {
    this.m_layers = {};
    this.m_lineGeos = {};
    this.m_meshGeos = {};
    this.m_instances = {};
    this.m_blocks = {};
    this.m_lineGeoCount = 0;
    this.m_meshGeoCount = 0;
    this.m_model.clear();
    this.m_context.SceneManager.MaskLayer.clear();
    this.m_context.ViewManager.Render();
  }
}
